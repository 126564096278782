import axios from 'axios'
import jwt_decode from "jwt-decode"
import {backendUrl} from '../config/config';

axios.defaults.baseURL = backendUrl;

export default {
    login(email, password, captcha, cb){
        axios.post("/api/users/login", {email, password, captcha})
        .then(resp => {
            if(resp.data.token){
                localStorage.setItem("token", resp.data.token);
                cb(true, "success");
            }else{
                cb(false, resp.data.info.message);
            }
        }).catch(err => {
            console.log(err);
            cb(false, "An error occurred, please try again");
        })
    },
    adminLogin(username, password, cb){
        axios.post("/api/admins/login", {username, password})
        .then(resp => {
            if(resp.data.token){
                localStorage.setItem("adminToken", resp.data.token);
                cb(true, "success");
            }else{
                cb(false, resp.data.info.message);
            }
        }).catch(err => {
            console.log(err);
            cb(false, "An error occurred, please try again");
        })
    },

    signup(user, cb){
        axios.post("/api/users/signup", user)
        .then(resp => {
            if(!resp.data.error){
                cb(true, resp.data.message);
            }else{
                cb(false, resp.data.message);
            }
        }).catch(err => {
            console.log(err);
            cb(false, "An error occurred, please try again");
        })
    },

    async confirmed(){
        let isConfirmed
        let res;
        try {
             res = await axios
        .get("/api/users/confirmation-status", {
            headers: {
            Authorization: `bearer ${this.token()}`,
            },
        })
        } catch (error) {
            console.log(error)
            localStorage.removeItem("token")
            return false
        }
        isConfirmed = res.data.status || new Date(res.data.date) < new Date("06-01-2023")
        return isConfirmed
    },

    async authenticated(){
        try {
            await axios
       .get("/api/users/confirmation-status", {
           headers: {
           Authorization: `bearer ${this.token()}`,
           },
       })
       } catch (error) {
           console.log(error)
           localStorage.removeItem("token")
           return false
       }
        return !!localStorage.getItem("token");   
    },

    adminAuthenticated(){
        return !!localStorage.getItem("adminToken");   
    },

    adminToken(){
        return localStorage.getItem("adminToken");   
    },
    admin(){
        if(!localStorage.getItem("adminToken")){
            return;
        }
        return jwt_decode(localStorage.getItem("adminToken"));
    },

    token(){
        return localStorage.getItem("token");
    },
    user(){
        if(!localStorage.getItem("token")){
            return;
        }
        return jwt_decode(localStorage.getItem("token"));
    },
    logout(){
        localStorage.removeItem("token");
    },
    adminLogout(){
        localStorage.removeItem("adminToken");
    }
}

