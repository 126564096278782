<template>
  <div>
    <DashboardNavbar />

    <!-- <v-toolbar class="dashboard__tooltip hidden-md-and-up" app color="indigo lighten-1">
      <v-toolbar-items>
        <v-spacer></v-spacer>
        <v-btn
          v-for="address in companyAddresses" :key="address._id"
          @click="activeAddress = address"
          text
          class="navbar-link white--text dashboard__tooltip-item"
        >
          <v-img style="max-width: 25px; margin-right: 5px" :src="backendUrl + '/' + address.flagImage"></v-img>
          <span class="">
            {{ address.country}}
          </span>
        </v-btn>
      </v-toolbar-items>
    </v-toolbar> -->

    <CountrySelector class="" :activeAddress.sync="activeAddress" />

    <v-container>
      <loading
        :active.sync="loading"
        :can-cancel="false"
        loader="bars"
        color="indigo"
        :is-full-page="true"
      ></loading>

      <v-row class="d-flex justify-center">
        <v-col  cols="12" sm="12" lg="4">
          <v-card height="100%" class="indigo">
            <div style="font-size:20px" class="pa-3 indigo white--text">
              <v-icon
                color="white"
                :right="this.$i18n.locale == 'ar' ? true : false"
                :left="this.$i18n.locale != 'ar' ? true : false"
                >fa-solid fa-shipping-fast</v-icon
              >

              <span v-if="lockerCount == 0">{{ $t('lockTitle') }}</span>
              <span v-else>{{ $t('lockerFullTitle') }}</span>
            </div>

            <v-card-text style="font-size:15px" class="indigo white--text">
              <span v-if="lockerCount == 0">{{ $t('lockerText') }}</span>
              <span v-else>{{ $t('lockerFullText') }}</span>
            </v-card-text>

            <v-card-text class="indigo">
              <v-img
                class="mx-auto"
                style="margin-top:80px;"
                src="../assets/locker.png"
                width="180"
              />
            </v-card-text>
          </v-card>
        </v-col>

        <v-col class="mobile-margin" cols="12" sm="12" lg="4">
          <v-card color="red darken-1 white--text">
            <div style="font-size:20px" class="pa-3 white--text">
              <v-icon
                color="white"
                :right="this.$i18n.locale == 'ar' ? true : false"
                :left="this.$i18n.locale != 'ar' ? true : false"
                >fa-solid fa-shopping-cart</v-icon
              >
              {{ $t('shopTitle') }}
            </div>

            <v-card-text style="font-size:16px;" class="white--text">
              {{ $t('shopText') }}
            </v-card-text>
          

            <v-card-text style="direction:ltr">
              <span style="font-weight:bold;font-size:14px;" class="white--text"
                >{{$i18n.locale === "ar" ? "Name" : $t("name")}}:</span
              >
              <br />
              <span
                style="font-size:17px;display:block;margin-bottom:8px"
                class="white--text"
                >{{ this.user.fullName }}
                <v-icon
                  v-if="this.user.fullName"
                  size="20"
                  style="cursor: pointer; color: gris;"
                  @click="
                    () => {
                      copy(this.user.fullName);
                    }
                  "
                  >far fa-copy</v-icon
                ></span
              >

              <span style="font-weight:bold;font-size:14px;" class="white--text"
                >{{$i18n.locale === "ar" ? "Address Line 1" : $t("addressFirst")}}:</span
              >
              <br />
              <span
                style="font-size:17px;display:block;margin-bottom:8px;"
                class="white--text"
                >{{ this.activeAddress.addressLineOne }}
                <v-icon
                  v-if="this.activeAddress.addressLineOne"
                  size="20"
                  style="cursor: pointer; color: gris;"
                  @click="
                    () => {
                      copy(this.activeAddress.addressLineOne);
                    }
                  "
                  >far fa-copy</v-icon
                >
              </span>

              <span style="font-weight:bold;font-size:14px;" class="white--text"
                >{{$i18n.locale === "ar" ? "Address Line 2" : $t("addressSecond")}}:</span
              >
              <br />
              <span
                style="font-size:17px;display:block;margin-bottom:8px;"
                class="white--text"
                >{{ this.activeAddress.addressLineTwo }} {{ this.userHash }}
                <v-icon
                  v-if="this.activeAddress.addressLineTwo && this.userHash"
                  size="20"
                  style="cursor: pointer; color: gris;"
                  @click="
                    () => {
                      copy(
                        `${this.activeAddress.addressLineTwo} ${this.userHash}`
                      );
                    }
                  "
                  >far fa-copy</v-icon
                >
              </span>

              <span style="font-weight:bold;font-size:14px;" class="white--text"
                >{{$i18n.locale === "ar" ? "City" : $t("city")}}:</span
              >
              <br />
              <span
                style="font-size:17px;display:block;margin-bottom:8px;"
                class="white--text"
                >{{ this.activeAddress.city }}
                <v-icon
                  v-if="this.activeAddress.city"
                  size="20"
                  style="cursor: pointer; color: gris;"
                  @click="
                    () => {
                      copy(this.activeAddress.city);
                    }
                  "
                  >far fa-copy</v-icon
                ></span
              >

              <span style="font-weight:bold;font-size:14px;" class="white--text"
                >{{$i18n.locale === "ar" ? "State" : $t("state")}}:</span
              >
              <br />
              <span
                style="font-size:17px;display:block;margin-bottom:8px;"
                class="white--text"
                >{{ this.activeAddress.state }}
                <v-icon
                  v-if="this.activeAddress.state"
                  size="20"
                  style="cursor: pointer; color: gris;"
                  @click="
                    () => {
                      copy(this.activeAddress.state);
                    }
                  "
                  >far fa-copy</v-icon
                ></span
              >
              <span style="font-weight:bold;font-size:14px;" class="white--text"
                >{{$i18n.locale === "ar" ? "ZIP" : $t("postal")}}:</span
              >
              <br />
              <span
                style="font-size:17px;display:block;margin-bottom:8px;"
                class="white--text"
                >{{ this.activeAddress.zip }}
                <v-icon
                  v-if="this.activeAddress.zip"
                  size="20"
                  style="cursor: pointer; color: gris;"
                  @click="
                    () => {
                      copy(this.activeAddress.zip);
                    }
                  "
                  >far fa-copy</v-icon
                ></span
              >

              <span style="font-weight:bold;font-size:14px;" class="white--text"
                >{{$i18n.locale === "ar" ? "Phone" : $t("phone")}}:</span
              >
              <br />
              <span
                style="font-size:17px;display:block;margin-bottom:8px;"
                class="white--text"
                >{{ this.activeAddress.phone }}
                <v-icon
                  v-if="this.activeAddress.phone"
                  size="20"
                  style="cursor: pointer; color: gris;"
                  @click="
                    () => {
                      copy(this.activeAddress.phone);
                    }
                  "
                  >far fa-copy</v-icon
                ></span
              >
            </v-card-text>

              <v-card-text style="font-size:16px;" class="white--text">
              <b>{{ $t('note') }}: </b> 

              <ul class="mx-5 px-3 mt-3">
                <li>{{ $t('phoneNote') }}</li>
                <li class="mt-3"> {{ $t('addresslineTwoNotice') }}</li>
              </ul>
            </v-card-text>      
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-snackbar left color="green" v-model="snackbar" timeout="2000">
      <v-icon color="white" left size="15">fa-solid fa-copy</v-icon>
      {{ $t('copied') }}
    </v-snackbar>
  </div>
</template>

<script>
import DashboardNavbar from '../components/DashboardNavbar';
import CountrySelector from '../components/CountrySelector';

import { backendUrl } from '../config/config';
import axios from 'axios';
import auth from '../helpers/auth';

import Loading from "vue-loading-overlay";
// Import stylesheet
import "vue-loading-overlay/dist/vue-loading.css";

const newAxios = axios.create();
newAxios.defaults.headers.common = {};

export default {
  name: 'AddressPage',
  components: { DashboardNavbar, CountrySelector, Loading },
  metaInfo: {
    title: 'French Address | Porsay',
  },
  data() {
    return {
      backendUrl,
      companyAddresses: [],
      activeAddress: {},
      toolbarColor: 'indigo',
      userHash: '',
      user: auth.user(),
      lockerCount: 0,
      snackbar: false,
      loading: false
    };
  },
  mounted() {
    if (this.$i18n.locale === 'ar') {
      this.$vuetify.rtl = true;
    }

    this.userHash = auth.user().addressHash;

    newAxios.get('/api/addresses/company').then((res) => {
      this.companyAddresses = res.data.addresses;
      this.activeAddress = localStorage.getItem('activeAddress') ? JSON.parse(localStorage.getItem('activeAddress')) : res.data.addresses[0]
    });

    newAxios
      .get('/api/users/packages/get', {
        headers: {
          Authorization: `bearer ${auth.token()}`,
        },
      })
      .then((res) => {
        this.lockerCount = res.data.packages.length;
      });
  },

  methods: {
    copy(text) {
      try {
        navigator.clipboard.writeText(text);
        this.snackbar = true;
      } catch (error) {
        throw error;
      }
    },
  },
  watch: {
    activeAddress(){
      this.loading = true
      const wait = setInterval(()=>{
        this.loading = false
        clearInterval(wait)
      }, 500)
    }
  }
};
</script>

<style scoped>
@media screen and (max-width: 500px) {
  .empty-col {
    display: none;
  }

  .mobile-margin{
    margin-bottom: 65px;
  }
}
</style>
