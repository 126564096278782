<template>
  <v-col sm="12" md="6" lg="3">
    <v-card>
      <div class="flag-icon" @click="dialog = !dialog">
        <v-img 
        :src="backendUrl + '/' + flagImage" alt="" ></v-img>
      </div>
      <v-img
        @click="dialog = !dialog"
        height="250"
        :src="backendUrl + '/' + this.userPackage.images[0]"
      >
      </v-img>

      <v-card-text>
        <div v-if="this.userPackage.status">
          {{ $t('status') }} :
          <span class="success--text">{{ $t('shipped') }}</span>
        </div>
        <div v-else>
          {{ $t('status') }} :
          <span class="warning--text">{{ $t('awaitingShipping') }}</span>
        </div>

        <div v-if="this.userPackage.status">
          {{ $t('shipCompany') }} :
          <span>{{
            userPackage.shipMethod ? userPackage.shipMethod.company.name : ''
          }}</span>
        </div>
        <div v-if="this.userPackage.status">
          {{ $t('trackNumber') }} :
          <span>{{ this.userPackage.shippedTrackNumber }}</span>
        </div>
        <div v-if="this.userPackage.status">
          {{ $t('shipDate') }} :
          <span>{{ formatDate(this.userPackage.shipDate) }}</span>
        </div>
        <div v-if="this.userPackage.status">
          <span>{{ $t('fullName') }}</span> :
          <span>{{ this.userPackage.shippingAddress.name }}</span>
        </div>
      </v-card-text>
    </v-card>

    <!-- Package Dialog -->

    <v-dialog v-model="dialog" width="650">
      <v-card class="pa-1">
        <v-card-text v-if="userPackage.status">
          <v-row>
            <v-col class="pa-0 pt-1" cols="12" md="6">
              <v-icon size="14">fas fa-ruler-horizontal</v-icon>
              <span style="text-transform:uppercase;font-size:13px">
                {{ $t('length') }} : {{ this.userPackage.shipLength }}
                {{ $t('width') }} : {{ this.userPackage.shipWidth }}
                {{ $t('height') }} : {{ this.userPackage.shipHeight }}
              </span>
            </v-col>

            <v-col class="pa-0 pt-1" cols="12" md="6">
              <v-icon size="14">fas fa-barcode</v-icon>
              <span> {{ this.userPackage.shippedTrackNumber }} </span>
            </v-col>

            <v-col class="pa-0 pt-1" cols="4" md="6">
              <v-icon size="14">fas fa-calendar</v-icon>
              <span style="text-transform:uppercase">
                {{ formatDate(this.userPackage.shipDate) }}
              </span>
            </v-col>

            <v-col class="pa-0 pt-1" cols="4" md="6">
              <v-icon size="14">fas fa-weight-hanging</v-icon>
              <span> {{ this.userPackage.shipWeight }} kg </span>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-text v-else>
          <v-row>
            <v-col class="pa-0 pt-1" cols="12" md="6">
              <v-icon size="14">fas fa-ruler-horizontal</v-icon>
              <span style="text-transform:uppercase;font-size:13px">
                {{ $t('length') }} : {{ this.userPackage.length }}
                {{ $t('width') }} : {{ this.userPackage.width }}
                {{ $t('height') }} : {{ this.userPackage.height }}
              </span>
            </v-col>

            <v-col class="pa-0 pt-1" cols="12" md="6">
              <v-icon size="14">fas fa-barcode</v-icon>
              <span> {{ this.userPackage.trackNumber }} </span>
            </v-col>

            <v-col class="pa-0 pt-1" cols="4" md="6">
              <v-icon size="14">fas fa-calendar</v-icon>
              <span style="text-transform:uppercase">
                {{ formatDate(this.userPackage.createdAt) }}
              </span>
            </v-col>

            <v-col class="pa-0 pt-1" cols="4" md="6">
              <v-icon size="14">fas fa-weight-hanging</v-icon>
              <span> {{ this.userPackage.weight }} kg </span>
            </v-col>
          </v-row>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-text>
          <v-row>
            <v-col
              v-viewer="{ toolbar: false, title: false }"
              sm="12"
              v-for="img in this.userPackage.images"
              :key="img"
              md="4"
            >
              <img width="100%" class="ma-1" :src="backendUrl + '/' + img" />
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-text v-if="userPackage.status">
          <div style="font-size:17px" class="green--text pa-1">
            {{ $t('shippingPrice') }} :
            {{ userPackage.shipMethod ? userPackage.shipMethod.price : '' }}
            &euro;
          </div>

          <div>
            <span>{{ $t('trackLink') }}</span> :
            <a :href="this.userPackage.trackLink">{{
              this.userPackage.trackLink
            }}</a>
          </div>

          <div>
            <span>{{ $t('shipWeight') }}</span> :
            <span>{{ this.userPackage.shipWeight }}</span>
          </div>

          <div>
            <span>{{ $t('fullName') }}</span> :
            <span>{{ this.userPackage.shippingAddress.name }}</span>
          </div>

          <div>
            <span>{{ $t('address') }}</span> :
            <span>{{
              `${this.userPackage.shippingAddress.addressLineOne}, ${this.userPackage.shippingAddress.addressLineTwo}, ${this.userPackage.shippingAddress.city}, ${this.userPackage.shippingAddress.country}`
            }}</span>
          </div>
        </v-card-text>

        <v-divider></v-divider>
      </v-card>
    </v-dialog>

    <!-- Package Dialog End -->
  </v-col>
</template>

<script>
import { backendUrl } from '../config/config';
import axios from 'axios';
import auth from '../helpers/auth';

const newAxios = axios.create();
newAxios.defaults.headers.common = {};

export default {
  name: 'Package',
  data() {
    return {
      user: auth.user(),
      dialog: false,
      backendUrl,
      loading: false,
      flagImage: '',
    };
  },
  mounted() {
    if (this.$i18n.locale === 'ar') {
      this.$vuetify.rtl = true;
    }

    this.flagImage = this.userPackage.warehouse.flagImage;
  },
  props: {
    userPackage: {
      type: Object,
    },
  },
  methods: {
    formatDate(date) {
      var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

      if (month.length < 2) month = '0' + month;
      if (day.length < 2) day = '0' + day;

      return [year, month, day].join('-');
    },
  },
};
</script>

<style scoped>
.flag-icon{
  z-index: 1;
  position: absolute;
  top: 2%;
  right: 2%;
  width: 30px;
  height: 30px;
  cursor: pointer;
}
</style>
