<template>
  <div>
    <DashboardNavbar />

    <v-container>
      <v-data-table
        :headers="tableHeaders"
        :items="transactions"
        :header-props="headerProps"
        class="elevation-2 grey lighten-5 ma-3"
      >
        <template v-slot:item.date="{ item }">
          <div>{{ formatDate(item.createdAt) }}</div>
        </template>

        <template v-slot:item.status="{ item }">
          <div :class="item.complete ? 'success--text' : 'error--text'">
            {{ item.complete ? $t('complete') : $t('incomplete') }}
          </div>
        </template>

        <template v-slot:item.paymentId="{ item }">
          <div>{{ item.paymentId }}</div>
        </template>

        <template v-slot:item.type="{ item }">
          <div>{{ item.type }}</div>
        </template>

        <template v-slot:item.trackNumber="{ item }">
          <div>{{ item.package ? item.package.trackNumber : '' }}</div>
        </template>

        <template v-slot:item.amount="{ item }">
          <div class="success--text">{{ item.amount }} &euro;</div>
        </template>

        <template v-slot:item.actions="{ item }">
        <div>
          <v-btn
            class="ma-2"
            @click="selectTransaction(item)"
            small
            :disabled="!item.complete"
            tile
            outlined
            color="primary"
          >
            <v-icon size="15" left>fas fa-eye</v-icon> Bill
          </v-btn>
        </div>
      </template>
      </v-data-table>

      <v-dialog v-model="factureDialog" max-width="600">
      <v-card style="direction:ltr" class="facture">
        <div class="facture__container">
          <img class="facture__logo" src="/img/icons/logo.png" alt="logo" />

          <v-btn
            class="ma-2"
            @click="generatePDF()"
            small
            tile
            outlined
            color="primary"
          >
            <v-icon size="15" left>fa-file-arrow-down</v-icon>
            Download
          </v-btn>
        </div>
        <div class="facture__header">
          <div class="facture__header-info">
            <span class="facture__header-info--adr">{{
              `${
                selectedTransaction.user
                  ? selectedTransaction.user.firstName
                  : ""
              } ${
                selectedTransaction.user
                  ? selectedTransaction.user.lastName
                  : ""
              } ${
                selectedTransaction.package
                  ? selectedTransaction.package.shippingAddress.addressLineOne
                  : ""
              }`
            }}</span>
            <span class="facture__header-info--adr">{{
              `${
                selectedTransaction.package
                  ? selectedTransaction.package.shippingAddress.addressLineTwo
                  : ""
              }`
            }}</span>
          </div>
        </div>
        <div class="facture__title">
          <h3>Invoice</h3>
        </div>
        <div class="facture__info">
          <div class="facture__info-container">
            <div class="facture__info-row">
              <span class="facture__info-title">Invoice Number : </span>
              <span class="facture__info-value">{{
                selectedTransaction.paymentId
              }}</span>
            </div>

            <div class="facture__info-row">
              <span class="facture__info-title">Date of Invoice : </span
              ><span class="facture__info-value">{{
                moment(selectedTransaction.createdAt).format("L")
              }}</span>
            </div>

            <div class="facture__info-row">
              <span class="facture__info-title">Invoice Currency: </span
              ><span class="facture__info-value">{{
                selectedTransaction.currency
              }}</span>
            </div>

            <div class="facture__info-row">
              <span class="facture__info-title">Customer Reference : </span
              ><span class="facture__info-value">{{
                selectedTransaction.user
                  ? selectedTransaction.user.addressHash
                  : ""
              }}</span>
            </div>
          </div>
        </div>
        <div class="facture__table">
          <table>
            <thead>
              <tr>
                <th>Service description</th>
                <th>VAT Rate</th>
                <th>Quantity</th>
                <th>Unit Price Excl VAT</th>
                <th>Total incl VAT</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  Forwarding Shipping N° 1260404
                  {{
                    selectedTransaction.package
                      ? selectedTransaction.package.trackNumber
                      : ""
                  }}
                  Shipped from France (FR) Destination:
                  {{
                    selectedTransaction.package
                      ? selectedTransaction.package.shippingAddress.country
                      : ""
                  }}
                </td>
                <td>0%</td>
                <td>1</td>
                <td>{{ selectedTransaction.amount }} €</td>
                <td>{{ selectedTransaction.amount }} €</td>
              </tr>
            </tbody>
          </table>
          <div class="facture__data">
            <div class="facture__data-info">
              <div>
                <span class="facture__data-info--title"
                  >Amount collected online: </span
                ><span class="facture__data-info--value"
                  >{{ selectedTransaction.amount }} €</span
                >
              </div>

              <div>
                <span class="facture__data-info--title">Payment Date: </span
                ><span class="facture__data-info--value">{{
                  moment(selectedTransaction.createdAt).format("L")
                }}</span>
              </div>

              <div>
                <span class="facture__data-info--title">Payment Method: </span
                ><span class="facture__data-info--value">{{
                  selectedTransaction.type
                }}</span>
              </div>
            </div>
            <div class="facture__data-table">
              <table>
                <thead>
                  <tr>
                    <th>Total excl VAT</th>
                    <th>VAT (20%)</th>
                    <th>Total incl VAT</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{{ selectedTransaction.amount }} €</td>
                    <td>0 €</td>
                    <td>{{ selectedTransaction.amount }} €</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="facture__legal-info">
            <span>ANDALUSIA INTERNATIONAL CO., LIMITED</span>
            <span>Registration No. 1517212</span>
            <span
              >flat 3b, 3/f, bank tower, nos.351 & 353 klng’s road, north point,
              hong kong</span
            >
          </div>
        </div>
      </v-card>
    </v-dialog>
    <vue-html2pdf
      :show-layout="false"
      :float-layout="true"
      :enable-download="true"
      :preview-modal="true"
      :paginate-elements-by-height="1400"
      :filename="selectedTransaction.paymentId"
      :pdf-quality="2"
      :manual-pagination="false"
      pdf-format="a4"
      :pdf-margin="10"
      pdf-orientation="portrait"
      pdf-content-width="800px"
      ref="html2Pdf"
    >
      <section class="facture" slot="pdf-content">
        <img class="facture__logo" src="/img/icons/logo.png" alt="logo" />

        <div class="facture__header">
          <div class="facture__header-info">
            <span class="facture__header-info--adr">{{
              `${
                selectedTransaction.user
                  ? selectedTransaction.user.firstName
                  : ""
              } ${
                selectedTransaction.user
                  ? selectedTransaction.user.lastName
                  : ""
              } ${
                selectedTransaction.package
                  ? selectedTransaction.package.shippingAddress.addressLineOne
                  : ""
              }`
            }}</span>
            <span class="facture__header-info--adr">{{
              `${
                selectedTransaction.package
                  ? selectedTransaction.package.shippingAddress.addressLineTwo
                  : ""
              }`
            }}</span>
          </div>
        </div>
        <div class="facture__title">
          <h3>Invoice</h3>
        </div>
        <div class="facture__info">
          <div class="facture__info-container">
            <div class="facture__info-row">
              <span class="facture__info-title">Invoice Number : </span>
              <span class="facture__info-value">{{
                selectedTransaction.paymentId
              }}</span>
            </div>

            <div class="facture__info-row">
              <span class="facture__info-title">Date of Invoice : </span
              ><span class="facture__info-value">{{
                moment(selectedTransaction.createdAt).format("L")
              }}</span>
            </div>

            <div class="facture__info-row">
              <span class="facture__info-title">Invoice Currency: </span
              ><span class="facture__info-value">{{
                selectedTransaction.currency
              }}</span>
            </div>

            <div class="facture__info-row">
              <span class="facture__info-title">Customer Reference : </span
              ><span class="facture__info-value">{{
                selectedTransaction.user
                  ? selectedTransaction.user.addressHash
                  : ""
              }}</span>
            </div>
          </div>
        </div>
        <div class="facture__table">
          <table>
            <thead>
              <tr>
                <th>Service description</th>
                <th>VAT Rate</th>
                <th>Quantity</th>
                <th>Unit Price Excl VAT</th>
                <th>Total incl VAT</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  Forwarding Shipping N° 1260404
                  {{
                    selectedTransaction.package
                      ? selectedTransaction.package.trackNumber
                      : ""
                  }}
                  Shipped from France (FR) Destination:
                  {{
                    selectedTransaction.package
                      ? selectedTransaction.package.shippingAddress.country
                      : ""
                  }}
                </td>
                <td>0%</td>
                <td>1</td>
                <td>{{ selectedTransaction.amount }} €</td>
                <td>{{ selectedTransaction.amount }} €</td>
              </tr>
            </tbody>
          </table>
          <div class="facture__data">
            <div class="facture__data-info">
              <div>
                <span class="facture__data-info--title"
                  >Amount collected online: </span
                ><span class="facture__data-info--value"
                  >{{ selectedTransaction.amount }} €</span
                >
              </div>

              <div>
                <span class="facture__data-info--title">Payment Date: </span
                ><span class="facture__data-info--value">{{
                  moment(selectedTransaction.createdAt).format("L")
                }}</span>
              </div>

              <div>
                <span class="facture__data-info--title">Payment Method: </span
                ><span class="facture__data-info--value">{{
                  selectedTransaction.type
                }}</span>
              </div>
            </div>
            <div class="facture__data-table">
              <table>
                <thead>
                  <tr>
                    <th>Total excl VAT</th>
                    <th>VAT (20%)</th>
                    <th>Total incl VAT</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{{ selectedTransaction.amount }} €</td>
                    <td>0 €</td>
                    <td>{{ selectedTransaction.amount }} €</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="facture__legal-info">
            <span>ANDALUSIA INTERNATIONAL CO., LIMITED</span>
            <span>Registration No. 1517212</span>
            <span
              >flat 3b, 3/f, bank tower, nos.351 & 353 klng’s road, north point,
              hong kong</span
            >
          </div>
        </div>
      </section>
    </vue-html2pdf>
    </v-container>
  </div>
</template>

<script>
import DashboardNavbar from '../components/DashboardNavbar';
import axios from 'axios';
import auth from '../helpers/auth';
import moment from "moment";
import VueHtml2pdf from "vue-html2pdf";


const newAxios = axios.create();
newAxios.defaults.headers.common = {};

export default {
  name: 'UserTransactions',
  components: { DashboardNavbar, VueHtml2pdf },

  mounted() {
    if (this.$i18n.locale === 'ar') {
      this.$vuetify.rtl = true;
    }

    newAxios
      .get('/api/users/transactions', {
        headers: {
          Authorization: `bearer ${auth.token()}`,
        },
      })
      .then((res) => {
        this.transactions = res.data.transactions;
      })
      .catch((err) => {});
  },
  data() {
    return {
      transactions: [],
      factureDialog: false,
      selectedTransaction: {},
      headerProps: {
        sortByText: this.$i18n.t('sortBy'),
      },
      tableHeaders: [
         {
          text: this.$i18n.t('actions'),
          value: 'actions',
          align: 'center',
        },
        {
          text: this.$i18n.t('date'),
          value: 'date',
          align: 'center',
        },

        {
          text: this.$i18n.t('trackNumber'),
          value: 'trackNumber',
          align: 'center',
        },

        {
          text: this.$i18n.t('transactionId'),
          value: 'paymentId',
          align: 'center',
        },

        {
          text: this.$i18n.t('paymentType'),
          value: 'type',
          align: 'center',
        },
        {
          text: this.$i18n.t('status'),
          value: 'status',
          align: 'center',
        },

        {
          text: this.$i18n.t('amount'),
          value: 'amount',
          align: 'center',
        },
        
      ],
    };
  },
  methods: {
    selectTransaction(item) {
      this.selectedTransaction = item;
      this.factureDialog = !this.factureDialog;
    },
    generatePDF() {
      this.$refs.html2Pdf.generatePdf();
    },
    moment: function (date) {
      const mom = new Date(date);
      //moment.locale(local(this.$i18n.locale))
      return moment(mom);
    },
    formatDate(date) {
      var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

      if (month.length < 2) month = '0' + month;
      if (day.length < 2) day = '0' + day;

      return [year, month, day].join('-');
    },
  },
};
</script>
