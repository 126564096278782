<template>
  <div>
    <DashboardNavbar />
    <DashboardToolbar />
    <CountrySelector :activeAddress.sync="activeAddress" />

    <v-container>
      <loading
        :active.sync="isLoading"
        :can-cancel="false"
        loader="bars"
        color="indigo"
        :is-full-page="true"
      ></loading>

      <div class="text-center" v-if="filteredPackages.length == 0">
        {{ $t('noPackages') }}
      </div>

      <v-row v-else>
        <IncomePackage
          v-for="userPackage in filteredPackages"
          :userPackage="userPackage"
          :key="userPackage._id"
        />
      </v-row>
    </v-container>
  </div>
</template>

<script>
import axios from 'axios';
import auth from '../helpers/auth';
import DashboardNavbar from '../components/DashboardNavbar';
import IncomePackage from '../components/IncomePackage';
import DashboardToolbar from '../components/DashboardToolbar';
import CountrySelector from "../components/CountrySelector.vue"


// Import component
import Loading from 'vue-loading-overlay';
// Import stylesheet
import 'vue-loading-overlay/dist/vue-loading.css';

const newAxios = axios.create();
newAxios.defaults.headers.common = {};

export default {
  name: 'AwaitingShipping',
  components: { DashboardNavbar, Loading, IncomePackage, DashboardToolbar,CountrySelector },
  watch:{
    activeAddress(val){
      this.filteredPackages = this.packages.filter(item => 
      item.warehouse._id == val._id)
    }
  },
  data() {
    return {
      packages: [],
      incomePackages: [],
      filteredredPackages: [],
      isLoading: false,
      companyAddress: {},
      activeAddress: {},
    };
  },

  mounted() {
    if (this.$i18n.locale === 'ar') {
      this.$vuetify.rtl = true;
    }

    this.isLoading = true;

    newAxios.get("/api/addresses/company").then((res) => {
      this.companyAddress = res.data.addresses[0]
      this.activeAddress = res.data.addresses[0]

    });
    

    newAxios
      .get('/api/packages/awaiting-shipping/user', {
        headers: {
          Authorization: `bearer ${auth.token()}`,
        },
      })
      .then((res) => {
        this.packages = res.data.packages;
        this.filteredPackages = this.packages.filter(item => 
          item.warehouse._id == this.activeAddress._id)
        this.isLoading = false;
      })
      .catch((err) => {
        this.isLoading = false;
      });

    newAxios
      .get('/api/users/packages/get', {
        headers: {
          Authorization: `bearer ${auth.token()}`,
        },
      })
      .then((res) => {
        this.incomePackages = res.data.packages;
      })
      .catch((err) => {});
  },
};
</script>


