<template>
  <div v-if="companyAddresses.length > 1" :class="expand ? 'selector expanded' : 'selector shrinked'"
  @click="expand = !expand"
  @mouseleave="expand = false"
  :style="{background: color}">

    <div class="country-text text-center py-2">{{ $t('changeCountry') }}</div>

    <div class="country"
    v-for="address in companyAddresses"
    :key="address._id"
    @click="handleClick(address)">
        <v-img class="flag" :src="backendUrl + '/' + address.flagImage" />
        <div style="text-align: center;letter-spacing: 1px;" class="country-text">{{$t(address.country)}}</div>
    </div>

  </div>
</template>

<script>
import { backendUrl } from '../config/config';
import axios from 'axios';

const newAxios = axios.create();
newAxios.defaults.headers.common = {};

export default {
    props: {
        activeAddress: Object
    },
    data(){
        return {
            backendUrl,
            expand: false,
            companyAddresses: [],
            color: 'hsl(231, 48%, 65%)'
        }
    },
    mounted(){
      newAxios.get('/api/addresses/company').then((res) => {
        this.companyAddresses = res.data.addresses;
        this.activeAddress = localStorage.getItem('activeAddress') ? JSON.parse(localStorage.getItem('activeAddress')) : res.data.addresses[0]
        this.color = this.activeAddress.color
      });
    },
    watch: {
        activeAddress(val){
            const index = this.companyAddresses.findIndex(item=> item._id == val._id)
            if(index !== -1)
                {this.companyAddresses.splice(index, 1)
                this.companyAddresses.unshift(val)
                this.color = val.color
                localStorage.setItem("activeAddress", JSON.stringify(val))
                }
        }
    },
    methods: {
        handleClick(address){
            if(this.activeAddress._id == address._id)
                return
            
            this.activeAddress = address
            this.$emit('update:activeAddress', this.activeAddress)
        },
    }
}
</script>

<style scoped>

.selector{
    padding: 4px;
    z-index: 10;
    position: fixed;
    align-self: center;
    top: 50%;
    right: 0;
    color: white;
    overflow: hidden;
    border-right: 0;
    box-shadow: 0 0 3px rgba(0,0,0,0.5);
    

    scrollbar-width: none;
    -ms-overflow-style: none;

    transition: 0.2s;
}

.shrinked{
    width:  75px;
    height: 50px;
    border-radius: 30px 0 0 30px;
}

.expanded{
    width: 250px;
    border-radius: 12px 0 0 12px;
}

.expanded .country{
    margin: 4px 0 8px 10px;
}
/* 
.selector:hover{
    border-radius: 0 10px 10px 0;
    transform: none;
    max-width: 200px;
    height: fit-content;
} */

.selector::-webkit-scrollbar,
.selector-expanded::-webkit-scrollbar{
    display: none;
}

.country{
    cursor: pointer;
    margin: 4px 0 8px 5px;
    display: flex;
    gap: 10px;
    align-items: center;
}

.shrinked .country-text{
    display: none;
}

.expanded .country-text{
    display: block;
}

.expanded .country:hover > .flag,
.selected{
    max-width: 45px;
    height: 45px;
}

.shrinked .flag{
    margin-right: 25px;

}


.flag{
    padding: 5px;
    max-width: 35px;
    height: 35px;
    border-radius: 50%;
    border: 2px solid white;

    transition: .1s ease-in;
}

</style>