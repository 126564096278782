<template>
  <div>
    <v-toolbar app class="indigo">
      <v-toolbar-title>
        <a href="https://www.porsay.com"
          ><v-img src="../assets/logo-white.png" width="140"
        /></a>
      </v-toolbar-title>

      <v-spacer></v-spacer>

      <v-toolbar-items class="hidden-md-and-up">
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn @click="seenNot" color="white" icon v-bind="attrs" v-on="on">
              <v-icon size="17">fas fa-bell</v-icon>
              <v-chip
                v-if="unSeen"
                color="pink"
                text-color="white"
                x-small
                :style="$i18n.locale == 'ar' ? 'left:12px' : 'right:12px'"
                class="mobile-notification"
              ></v-chip>
            </v-btn>
          </template>
          <v-card
            v-if="notCount === 0"
            class="mx-auto"
            max-width="344"
            outlined
          >
            <v-card-text>{{ $t('noNotifications') }}</v-card-text>
          </v-card>
          <v-card
            v-else
            max-width="344"
            max-height="400"
            style="overflow-y: scroll"
          >
            <v-list three-line max-height="500">
              <div v-for="(not, index) in notifications" :key="index">
                <v-divider></v-divider>
                <v-list-item>
                  <v-list-item-content>
                    <div class="body-2 grey--text mb-2 font-weight-light">
                      {{ moment(not.createdAt).calendar() }}
                      <v-chip
                        v-if="not.new"
                        color="pink"
                        text-color="white"
                        x-small
                        style="margin:3px"
                        >New</v-chip
                      >
                    </div>
                    <v-list-item-title>{{
                      translate(not.title)
                    }}</v-list-item-title>
                    <div class="v-markdown caption grey--text text--darken-1">
                      <p>{{ translate(not.text) }}</p>
                    </div>
                  </v-list-item-content>
                </v-list-item>
              </div>
            </v-list>
          </v-card>
        </v-menu>
      </v-toolbar-items>
      <v-toolbar-items class="hidden-sm-and-down">
        <v-btn
          :to="'/' + this.$i18n.locale + '/dashboard'"
          text
          class="dash-nav-link"
        >
          <v-icon left size="15">fas fa-door-closed</v-icon>{{ $t('locker') }}
          <v-chip
            color="indigo lighten-2"
            text-color="white"
            x-small
            style="margin:3px"
            >{{ lockerCount }}</v-chip
          >
        </v-btn>

        <v-btn
          text
          :to="'/' + this.$i18n.locale + '/calculator'"
          class="dash-nav-link"
        >
          <v-icon left size="15">fas fa-calculator</v-icon
          >{{ $t('pricingCalc') }}
        </v-btn>

        <v-btn
          :to="`/${this.$i18n.locale}/addressbook`"
          text
          class="dash-nav-link"
        >
          <v-icon left size="15">fas fa-address-book</v-icon
          >{{ $t('addressBook') }}
        </v-btn>

        <!-- <v-btn @click="goSupport" text class="dash-nav-link">
          <v-icon left size="15">fas fa-envelope</v-icon>{{$t('support')}}
          <v-chip
          color="indigo lighten-2"
          text-color="white"
          x-small
          style="margin:3px"
          v-if="supportCount > 0"
          >{{supportCount}}</v-chip>
        </v-btn> -->

        <v-btn :to="`/${this.$i18n.locale}/address`" text class="dash-nav-link">
          <v-icon left size="15">fas fa-map-pin</v-icon>{{ $t('myAddress') }}
        </v-btn>

        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn @click="seenNot" color="white" icon v-bind="attrs" v-on="on">
              <v-badge overlap color="pink">
                <v-icon size="17">fas fa-bell</v-icon>
              </v-badge>
              <v-chip
                v-if="unSeen"
                color="pink"
                text-color="white"
                x-small
                :style="
                  $i18n.locale == 'ar'
                    ? 'position:relative;left:10px;bottom:5px;padding:7px'
                    : 'position:relative;right:10px;bottom:5px;padding:7px'
                "
              ></v-chip>
            </v-btn>
          </template>
          <v-card
            v-if="notCount === 0"
            class="mx-auto"
            max-width="344"
            outlined
          >
            <v-card-text>{{ $t('noNotifications') }}</v-card-text>
          </v-card>
          <v-card
            v-else
            max-width="344"
            max-height="400"
            style="overflow-y: scroll"
          >
            <v-list three-line max-height="500">
              <div v-for="(not, index) in notifications" :key="index">
                <v-divider></v-divider>
                <v-list-item>
                  <v-list-item-content>
                    <div class="body-2 grey--text mb-2 font-weight-light">
                      {{ moment(not.createdAt).calendar() }}
                      <v-chip
                        v-if="not.new"
                        color="pink"
                        text-color="white"
                        x-small
                        style="margin:3px"
                        >New</v-chip
                      >
                    </div>
                    <v-list-item-title>{{
                      translate(not.title)
                    }}</v-list-item-title>
                    <div class="v-markdown caption grey--text text--darken-1">
                      <p>{{ translate(not.text) }}</p>
                    </div>
                  </v-list-item-content>
                </v-list-item>
              </div>
            </v-list>
          </v-card>
        </v-menu>

        <v-menu offset-y :close-on-content-click="false">
          <template v-slot:activator="{ on }">
            <v-btn text class="dash-nav-link" v-on="on">
              <v-icon left size="25" class="ma-2">fas fa-user-circle</v-icon>
            </v-btn>
          </template>
          <v-list
            :style="$i18n.locale == 'ar' ? 'direction:rtl' : 'direction:ltr'"
          >
            <v-list-item :to="'/' + this.$i18n.locale + '/user/edit'">
              <v-list-item-title
                ><v-icon
                  size="20"
                  :right="$i18n.locale == 'ar' ? true : false"
                  :left="$i18n.locale != 'ar' ? true : false"
                  >fas fa-edit</v-icon
                >{{ $t('EditProfile') }}</v-list-item-title
              >
            </v-list-item>

            <v-list-item :to="'/' + this.$i18n.locale + '/transactions'">
              <v-list-item-title
                ><v-icon
                  size="20"
                  :right="$i18n.locale == 'ar' ? true : false"
                  :left="$i18n.locale != 'ar' ? true : false"
                  >fas fa-money-bill</v-icon
                >{{ $t('transactions') }}</v-list-item-title
              >
            </v-list-item>

            <v-list-item color="white">
              <v-list-item-content>
                <v-menu offset-y nudge-top="20" nudge-right="105">
                  <template v-slot:activator="{ on }">
                    <v-list-item-title
                      style="cursor:pointer"
                      class="navbar-title"
                      v-on="on"
                      text
                    >
                      <v-icon
                        size="20"
                        :right="$i18n.locale == 'ar' ? true : false"
                        :left="$i18n.locale != 'ar' ? true : false"
                        >fas fa-language</v-icon
                      >
                      {{ $i18n.locale.toUpperCase() }}
                    </v-list-item-title>
                  </template>
                  <v-list>
                    <v-list-item
                      @click="setLang('en')"
                      :href="$route.path.split('/')[2]?'/en/' + $route.path.split('/')[2]: '/en/dashboard'"
                    >
                      <v-list-item-title>EN</v-list-item-title>
                    </v-list-item>
                    <v-list-item
                      @click="setLang('fr')"
                      :href="$route.path.split('/')[2]?'/fr/' + $route.path.split('/')[2]: '/fr/dashboard'"
                    >
                      <v-list-item-title>FR</v-list-item-title>
                    </v-list-item>
                    <v-list-item
                      @click="setLang('ar')"
                      :href="$route.path.split('/')[2]?'/ar/' + $route.path.split('/')[2]: '/ar/dashboard'"
                    >
                      <v-list-item-title>AR</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-list-item-content>
            </v-list-item>

            <v-list-item @click="logout">
              <v-list-item-title
                ><v-icon
                  size="20"
                  :right="$i18n.locale == 'ar' ? true : false"
                  :left="$i18n.locale != 'ar' ? true : false"
                  >fas fa-sign-out-alt</v-icon
                >{{ $t('logout') }}</v-list-item-title
              >
            </v-list-item>
          </v-list>
        </v-menu>
      </v-toolbar-items>

      <v-toolbar-items class="hidden-md-and-up">
        <v-btn text @click.stop="drawer = !drawer"
          ><v-icon color="white" size="25">fas fa-bars</v-icon></v-btn
        >
      </v-toolbar-items>
    </v-toolbar>

    <v-navigation-drawer
      v-model="drawer"
      temporary
      app
      hide-overlay
      :right="this.$i18n.locale == 'ar' ? false : true"
      :left="this.$i18n.locale != 'ar' ? false : true"
      width="300"
      id="drawer"
    >
      <v-list nav>
        <v-list-item :to="'/' + this.$i18n.locale + '/dashboard'">
          <div>
            <v-icon
              size="20"
              color="grey darken-1"
              :right="this.$i18n.locale == 'ar' ? true : false"
              :left="this.$i18n.locale != 'ar' ? true : false"
              >fas fa-door-closed</v-icon
            >
          </div>

          <v-list-item-content>
            <v-list-item-title>{{ $t('locker') }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item :to="'/' + this.$i18n.locale + '/calculator'">
          <div>
            <v-icon
              size="20"
              color="grey darken-1"
              :right="this.$i18n.locale == 'ar' ? true : false"
              :left="this.$i18n.locale != 'ar' ? true : false"
              >fas fa-calculator</v-icon
            >
          </div>

          <v-list-item-content>
            <v-list-item-title>{{ $t('pricingCalc') }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item :to="'/' + this.$i18n.locale + '/addressbook'">
          <div>
            <v-icon
              size="20"
              color="grey darken-1"
              :right="this.$i18n.locale == 'ar' ? true : false"
              :left="this.$i18n.locale != 'ar' ? true : false"
              >fas fa-address-book</v-icon
            >
          </div>

          <v-list-item-content>
            <v-list-item-title>{{ $t('addressBook') }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item :to="'/' + this.$i18n.locale + '/user/edit'">
          <div>
            <v-icon
              size="20"
              color="grey darken-1"
              :right="this.$i18n.locale == 'ar' ? true : false"
              :left="this.$i18n.locale != 'ar' ? true : false"
              >fas fa-edit</v-icon
            >
          </div>

          <v-list-item-content>
            <v-list-item-title>{{ $t('EditProfile') }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item :to="'/' + this.$i18n.locale + '/transactions'">
          <v-list-item-title
            ><v-icon
              size="20"
              :right="$i18n.locale == 'ar' ? true : false"
              :left="$i18n.locale != 'ar' ? true : false"
              >fas fa-money-bill</v-icon
            >{{ $t('transactions') }}</v-list-item-title
          >
        </v-list-item>

        <v-list-item :to="'/' + this.$i18n.locale + '/address'">
          <div>
            <v-icon
              size="20"
              color="grey darken-1"
              :right="this.$i18n.locale == 'ar' ? true : false"
              :left="this.$i18n.locale != 'ar' ? true : false"
              >fas fa-map-pin</v-icon
            >
          </div>

          <v-list-item-content>
            <v-list-item-title>{{ $t('myAddress') }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item @click="logout">
          <div>
            <v-icon
              size="20"
              :right="this.$i18n.locale == 'ar' ? true : false"
              :left="this.$i18n.locale != 'ar' ? true : false"
              >fas fa-sign-out-alt</v-icon
            >
          </div>

          <v-list-item-content>
            <v-list-item-title>{{ $t('logout') }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item color="white">
          <div>
            <v-icon
              size="20"
              :right="this.$i18n.locale == 'ar' ? true : false"
              :left="this.$i18n.locale != 'ar' ? true : false"
              >fas fa-language</v-icon
            >
          </div>

          <v-list-item-content>
            <v-menu offset-y>
              <template v-slot:activator="{ on }">
                <v-list-item-title
                  style="cursor:pointer"
                  class="navbar-title"
                  v-on="on"
                  text
                >
                  {{ $i18n.locale.toUpperCase() }}
                </v-list-item-title>
              </template>
              <v-list>
                    <v-list-item
                      @click="setLang('en')"
                      :href="$route.path.split('/')[2]?'/en/' + $route.path.split('/')[2]: '/en/dashboard'"
                    >
                      <v-list-item-title>EN</v-list-item-title>
                    </v-list-item>
                    <v-list-item
                      @click="setLang('fr')"
                      :href="$route.path.split('/')[2]?'/fr/' + $route.path.split('/')[2]: '/fr/dashboard'"
                    >
                      <v-list-item-title>FR</v-list-item-title>
                    </v-list-item>
                    <v-list-item
                      @click="setLang('ar')"
                      :href="$route.path.split('/')[2]?'/ar/' + $route.path.split('/')[2]: '/ar/dashboard'"
                    >
                      <v-list-item-title>AR</v-list-item-title>
                    </v-list-item>
                  </v-list>
            </v-menu>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <v-alert
      v-model="alert"
      @input="closeAlert"
      border="left"
      :type="alertType"
      close-text="Close Alert"
      width="80%"
      dismissible
      style="position: fixed; bottom: 0%; left: 50%; transform: translateX(-50%); z-index:1; overflow: hidden"
    >
      {{ translate(alertText) }}
    </v-alert>

    <v-dialog v-model="dialog" width="600">
      <v-card>
        <v-card-title>{{ $t('newTicket') }}</v-card-title>
        <v-card-text>
          <v-form ref="form" @submit.prevent="handleSubmit" v-model="valid">
            <v-textarea
              filled
              :label="$t('content')"
              auto-grow
              v-model="msg"
            ></v-textarea>

            <v-spacer></v-spacer>

            <v-spacer></v-spacer>

            <div v-if="loading" class="d-flex justify-center">
              <v-progress-circular
                :indeterminate="true"
                size="40"
                color="indigo"
              ></v-progress-circular>
            </div>

            <div v-else class="d-flex justify-center">
              <v-btn class="ma-2 mx-auto" type="submit" dark color="indigo">{{
                $t('send')
              }}</v-btn>
            </div>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-snackbar left :color="color" v-model="snackbar">
      {{ message }}
    </v-snackbar>
  </div>
</template>

<script>
import auth from '../helpers/auth';
import moment from 'moment';
import axios from 'axios';

const newAxios = axios.create();
newAxios.defaults.headers.common = {};

newAxios.interceptors.response.use(
  function(response) {
    return response;
  },
  function(error) {
    if (401 === error.response.status) {
      localStorage.removeItem("token");
      window.location = "/en/login";
    } else {
      return Promise.reject(error);
    }
  }
);

export default {
  name: 'DashboardNavbar',
  data() {
    return {
      drawer: false,
      alert: false,
      dialog: false,
      msg: '',
      lockerCount: 0,
      notifications: [],
      ticket: {},
      notCount: 0,
      unSeen: false,
      newNot: false,
      supportCount: 0,
      alertType: '',
      alertText: {},
      loading: false,
      message: '',
      snackbar: false,
      color: '',
    };
  },
  sockets: {
    loadNotifications(data) {
      // Fired when the socket connects.
      this.notifications = data.notifications;
      this.notCount = data.notifications.length;
      this.unSeen = data.unSeen;
    },
    newNotification(data) {
      //console.log(data)
      this.notifications.unshift({ ...data, new: true });
      this.newNot = true;
      this.notCount++;
      this.unSeen = true;
      // Update lOCKER
      newAxios
        .get('/api/users/packages/get', {
          headers: {
            Authorization: `bearer ${auth.token()}`,
          },
        })
        .then((res) => {
          this.lockerCount = res.data.packages.length;
        })
        .catch((err) => {
          console.log(err.message);
        });
    },
  },

  methods: {
    closeAlert() {
      this.alert = false;

      this.$socket.emit('clearAlert', {
        userId: auth.user().id,
        alertId: alert.alertId,
      });
      localStorage.setItem('alert', 'seen');
    },
    setLang(item){
      localStorage.setItem('lang', item)
    },
    logout() {
      auth.logout();
      this.$emit('authChange');
      window.location.href = 'https://www.porsay.com/';
    },
    moment: function(date) {
      const mom = new Date(date);
      //moment.locale(local(this.$i18n.locale))
      return moment(mom).locale(this.$i18n.locale === 'ar' ? 'ar-tn' : this.$i18n.locale);
    },
    translate: function(data) {
      if (this.$i18n.locale === 'en') return data.en;
      if (this.$i18n.locale === 'ar') return data.ar;
      if(this.$i18n.locale === 'fr') return data.fr;
    },

    seenNot() {
      this.unSeen = false;
      this.$socket.emit('seenNotifications', auth.user().id);
    },

    goSupport() {
      //  console.log(this.ticket)
      if (!this.ticket) {
        this.dialog = true;
      } else {
        this.$router.push(
          '/' + this.$i18n.locale + '/support/' + this.ticket._id
        );
      }
    },

    handleSubmit() {
      if (!this.msg) {
        return;
      }
      this.loading = true;
      this.$recaptcha('createSupport').then((token) => {
        //console.log(token) // Will print the token
        newAxios
          .post(
            'api/users/create/userTicket',
            {
              about: this.msg,
              captcha: token,
            },
            {
              headers: {
                Authorization: `bearer ${auth.token()}`,
              },
            }
          )
          .then((res) => {
            if (!res.data.error) {
              if (this.$i18n.locale == 'ar') {
                this.message = 'تمت الأضافة';
              } else if (this.$i18n.locale == 'en') {
                this.message = 'Created Successfully';
              }
              this.snackbar = true;
              this.color = 'success';
              this.loading = false;
              this.$refs.form.reset();
              this.dialog = false;

              this.$router.push(
                '/' +
                  this.$i18n.locale +
                  '/support/' +
                  res.data.supportTicket._id
              );
            } else {
              if (this.$i18n.locale == 'ar') {
                this.message = 'حدث خطأ الرجاء المحاولة مجدداً';
              } else if (this.$i18n.locale == 'en') {
                this.message = 'An error happened please try again';
              }
              this.snackbar = true;
              this.color = 'error';
              this.loading = false;
            }
          })
          .catch((err) => {
            if (this.$i18n.locale == 'ar') {
              this.message = 'حدث خطأ الرجاء المحاولة مجدداً';
            } else if (this.$i18n.locale == 'en') {
              this.message = 'An error happened please try again';
            }
            this.snackbar = true;
            this.color = 'error';
            this.loading = false;

            console.log(err);
          });
      });
    },
  },

  mounted() {
    newAxios
      .get('/api/users/support/user', {
        headers: {
          Authorization: `bearer ${auth.token()}`,
        },
      })
      .then((res) => {
        this.ticket = res.data.supportTicket;
      });

    newAxios
      .get('/api/users/packages/get', {
        headers: {
          Authorization: `bearer ${auth.token()}`,
        },
      })
      .then((res) => {
        //console.log(res.data.packages.length)
        this.lockerCount = res.data.packages.length;
      })
      .catch((err) => {
        console.log(err.message);
      });

    newAxios
      .get('/api/users/support/count', {
        headers: {
          Authorization: `bearer ${auth.token()}`,
        },
      })
      .then((res) => {
        this.supportCount = res.data.count;
      });

    newAxios
      .get('/api/users/alert/get', {
        headers: {
          Authorization: `bearer ${auth.token()}`,
        },
      })
      .then((res) => {
        if (res.data.alert) {
          this.alertType = res.data.alert.type;
          this.alertText = res.data.alert.text;

          this.alert = true;
          console.log(res.data.alert);
        }
      });

    // Send the "getNotifications" event to the server.
    this.$socket.emit('join', auth.user().id);
    this.$socket.emit('userActive', auth.user().id);
    //this.$socket.emit('getNofications', auth.user())
  },
};
</script>

<style scoped>
* {
  text-transform: none !important;
}

.dash-nav-link {
  color: white !important;
}

.dash-nav-link:hover {
  background: rgba(255, 255, 255, 0.061);
}

@media screen and (max-width: 970px) {
  .empty-col {
    display: none;
  }

  .mobile-notification {
    position: relative;
    bottom: 6px;
    padding: 7px;
  }
}
</style>
