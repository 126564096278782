<template>
  <v-container>

    <v-btn class="my-4" @click="addCompanyDialog = !addCompanyDialog" color="success"><v-icon small left color="white">fas fa-plus</v-icon>Add Company</v-btn>

    <div v-for="warehouse in companies">
      <h2>{{warehouse.warehouse}}</h2>
      
      <div style="margin-bottom:40px" v-for="company in warehouse.companies" :key="company._id">
        
        <div class="d-flex align-center">
          <h4>{{company.name}}</h4>

          <!-- <v-btn @click.stop="removeCompany(company._id)" icon tile class="ml-auto">
            <v-icon color="error">fas fa-trash</v-icon>
          </v-btn> -->

          <v-btn @click="openUpdateCompanyDialog(company)" class="mx-2" fab dark small color="info">
            <v-icon dark>mdi-pencil</v-icon>
          </v-btn>
          
        </div>

        <v-btn color="indigo" @click="onRangeAdd(company)" class="ma-2 white--text">Add Range</v-btn>

        <div class="mt-2 ma-4">
          <div v-if="company.ranges.length != 0">
            <v-row class="mx-1" >
              <v-col class="ma-0 pa-0" cols="3">Range: </v-col>
              <v-col class="ma-0 pa-0" cols="3">Price: </v-col>
              <v-col class="ma-0 pa-0" cols="3">Zone: </v-col>
              <v-col class="ma-0 pa-0" cols="3">Actions: </v-col>
            </v-row>
          </div>
            <v-card v-for="range in company.ranges" :key="range._id">
                <v-card-text class="ma-2">
                    <v-row>
                        <v-col class="ma-0 pa-0" cols="3">
                          <p class="mt-2">{{range.from +'-' + range.to}} kg
                          </p></v-col>
                        <v-col class="ma-0 pa-0" cols="3">
                          <p class="mt-2 ">{{range.price}} &euro;</p>
                        </v-col>
                        <v-col class="ma-0 pa-0" cols="3">
                          <p class="mt-2 ">{{zones.filter(item => item.value == range.zone)[0].text}}
                          </p>
                        </v-col>
                        <v-col class="ma-0 pa-0" cols="3">
                          <v-btn class="mx-1" @click="onRangeEdit(range)" color="success">update</v-btn>
                          <v-btn class="mx-1" @click="handleDeleteRange(range)" color="error">delete</v-btn>
                          </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </div>

          
      </div>

      <v-divider class="ma-4" />
    </div>


    <v-dialog max-width="650" v-model="updateCompanyDialog">
        <v-card>
            <v-card-title>Update shipping company</v-card-title>

            <v-card-text>
                <v-text-field
                  label="Company Name"
                  v-model="updatedCompanyName"
                ></v-text-field>

                <v-text-field
                  label="Company Arabic Name"
                  v-model="updatedCompanyArName"
                ></v-text-field>

                <v-text-field
                  label="Dimensional Weight Coefficent"
                  v-model="updatedCompanyDimWeight"
                 
                ></v-text-field>

                <v-select
                label="Warehouse"
                v-model="updatedWarehouse"
                :items="warehouses"
                item-text="country"
                item-value="_id"
                color="indigo"
              ></v-select>

                <v-text-field
                  label="minimum shipping time "
                  v-model="updatedMinShipTime"
                ></v-text-field>

                <v-text-field
                  label="maximum shipping time"
                  v-model="updatedMaxShipTime"
                ></v-text-field>

            <div class="d-flex justify-center">
              <v-progress-circular v-if="loading" :indeterminate="true" size="40" color="indigo"></v-progress-circular>

              <v-btn v-else @click="handleUpdateShippingCompany" style="color:white" color="indigo">update</v-btn>
            </div>
            </v-card-text>
        </v-card>
      </v-dialog>

    

    <v-snackbar left :color="color" v-model="snackbar">{{ message }}</v-snackbar>

    <v-dialog max-width="650" v-model="addCompanyDialog">
      <v-card>
          <v-card-title>Add a new shipping company</v-card-title>

          <v-card-text>
              <v-text-field
                label="Company Name"
                v-model="companyName"
              ></v-text-field>

              <v-text-field
                label="Company Arabic Name"
                v-model="companyNameAR"
              ></v-text-field>

              <v-text-field
                label="Dimensional Weight Coefficent"
                v-model="dimensionalWeight"
              ></v-text-field>

              <v-text-field
                label="minShipTime"
                v-model="minShipTime"
              ></v-text-field>

            

              <v-text-field
                label="maxShipTime"
                v-model="maxShipTime"
              ></v-text-field>

              <v-select
                label="Warehouse"
                v-model="warehouse"
                :items="warehouses"
                item-text="country"
                item-value="_id"
                color="indigo"
                required
              ></v-select>

              <v-file-input
              accept="image/*"
              chips
              label="Logo Image"
              prepend-icon="fas fa-paperclip"
              @change="this.onFilesPicked"
              ></v-file-input>

          <div class="d-flex justify-center">
            <v-progress-circular v-if="loading" :indeterminate="true" size="40" color="indigo"></v-progress-circular>

            <v-btn v-else @click="handleShippingCompany" color="success">create</v-btn>
          </div>
          </v-card-text>
      </v-card>
    </v-dialog>


    <v-dialog max-width="650" v-model="addRangeDialog">
        <v-card>
          <v-card-title>Add Range</v-card-title>

          <v-card-text>
              <v-text-field
                label="From"
                v-model="from"
              ></v-text-field>

              <v-text-field
                label="To"
                v-model="to"
              ></v-text-field>

              
              <v-select 
              label="Zone"
              :items="zones"
              v-model="zone"
              />

              <v-text-field
                label="Price"
                v-model="price"
              ></v-text-field>

              <div class="d-flex justify-center">
            <v-progress-circular v-if="loading" :indeterminate="true" size="40" color="indigo"></v-progress-circular>

            <v-btn v-else @click="createRange" color="success">create</v-btn>
          </div>
          </v-card-text>
        </v-card>
    </v-dialog>

    <v-dialog v-model="deleteDialog" width="400">
      <v-card>
        <v-card-title>
          Delete range?
        </v-card-title>
        <v-card-text>
          <v-alert type="error" elevation="2">
            Are you sure you want to delete this range?
          </v-alert>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="mb-2" color="error" @click="deleteRange">Delete</v-btn>
          <v-btn class="mb-2" color="white" @click="deleteDialog = !deleteDialog">Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog max-width="650" v-model="editRangeDialog">
        <v-card>
          <v-card-title>Edit Range</v-card-title>

          <v-card-text>
              <v-text-field
                label="From"
                v-model="from"
              ></v-text-field>

              <v-text-field
                label="To"
                v-model="to"
              ></v-text-field>

              <v-select 
              label="Zone"
              :items="zones"
              v-model="zone"
              />


              <v-text-field
                label="Price"
                v-model="price"
              ></v-text-field>

              <div class="d-flex justify-center">
            <v-progress-circular v-if="loading" :indeterminate="true" size="40" color="indigo"></v-progress-circular>

            <v-btn v-else @click="editRange" color="success">Update</v-btn>
          </div>
          </v-card-text>
        </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import axios from 'axios';
import auth from '../helpers/auth';

export default {
  name: "ShippingRates",
  data() {
    return {
      admin: auth.admin(),
      adminId: 1,
      supportId: 2,
      companies:[],
      message: "",
      snackbar: false,
      deleteDialog: false,
      loading: false,
      color: "",
      addCompanyDialog:false,
      updateCompanyDialog: false,
      companyName:'',
      companyNameAR:'',
      addRangeDialog:false,
      from:'',
      to:'',
      price:'',
      zone:'',
      warehouse:'',
      selectedCompany:'',
      editRangeDialog:false,
      selectedRange:'',
      dimensionalWeight:'',
      zones:[],
      warehouses:[],
      logoFile:'',
      minShipTime: '',
      maxShipTime: '',
      updatedCompanyName: '',
      updatedCompanyArName: '',
      updatedCompanyDimWeight: '',
      updatedMinShipTime: '',
      updatedMaxShipTime: '',
      updatedCompanyId: '',
      updatedWarehouse: ''
    };
  },
  mounted(){
    if( this.admin.groupId > this.adminId ) {
      this.$router.push('support/');
    }

    axios.get("/api/addresses/company").then((res) => {
      this.warehouses = res.data.addresses
    });

    axios.get('/api/admins/get_shipping_companies',{
      headers:{
        Authorization:`Bearer ${auth.adminToken()}`
      }
    }).then((res)=>{
      this.companies = res.data.results;
    })

     axios.get('/api/zones/').then(res=>{
        this.zones= res.data.zones.map(item=>{
          let obj = {};
          obj.text = item.name;
          obj.value = item._id;

          return obj;
        });
        console.log(this.zones)
    })
  },
  methods:{
    onRangeAdd(company){
      this.selectedCompany = company;
      this.addRangeDialog = true;
      this.from = '';
      this.to = '';
      this.price = '';
      this.zone = '';
    },
    onRangeEdit(range){
      this.selectedRange = range;
      this.from = range.from;
      this.to = range.to;
      this.price = range.price;
      this.zone = range.zone;

      this.editRangeDialog = true;
    },

    openUpdateCompanyDialog(company) {
      this.updatedCompanyId = company._id
      this.updatedCompanyName = company.name
      this.updatedCompanyArName = company.nameAR
      this.updatedCompanyDimWeight = company.dimensionalWeight
      this.updatedMinShipTime = company.minShipTime
      this.updatedMaxShipTime = company.maxShipTime
      this.updatedWarehouse = company.warehouse

      this.updateCompanyDialog = true
    },

    handleUpdateShippingCompany() {
      this.loading = true;

      // const form = new FormData();

      // form.append('id',this.updatedCompanyId);
      // form.append('name',this.updatedCompanyName);
      // form.append('dimensionalWeight',this.updatedCompanyDimWeight);
      // form.append('minShipTime',this.updatedMinShipTime);
      // form.append('maxShipTime',this.updatedMaxShipTime);

      axios.post('/api/admins/update_shipping_company', {
        id: this.updatedCompanyId,
        name: this.updatedCompanyName,
        nameAR: this.updatedCompanyArName,
        dimensionalWeight: this.updatedCompanyDimWeight,
        minShipTime: this.updatedMinShipTime,
        maxShipTime: this.updatedMaxShipTime,
        warehouse: this.updatedWarehouse
      },{
        headers:{
          Authorization:`Bearer ${auth.adminToken()}`
        }
      }).then((res)=>{

        this.loading = false;

        if(!res.data.error){

          this.message = 'Updated successfully';
          this.color = 'indigo';
          this.snackbar = true;
        }else{
          this.message = 'An error occured please try again later';
          this.color = 'error'
          this.snackbar = true;
        }

        axios.get('/api/admins/get_shipping_companies',{
          headers:{
            Authorization:`Bearer ${auth.adminToken()}`
          }
        }).then((res)=>{
          this.companies = res.data.companies;
        })
        
        this.updatedCompanyId = ''
        this.updatedCompanyName = '';
        this.updatedCompanyArName = '';
        this.updatedCompanyDimWeight = '';
        this.updatedMinShipTime = '';
        this.updatedMaxShipTime = ''
        this.updateCompanyDialog = false;
        this.updatedWarehouse = '';

      }).catch((err)=>{
        this.message = 'An error occured please try again later';
        this.color = 'error'
        this.snackbar = true;
        this.loading = false;
      })
    },

    handleShippingCompany(){
      this.loading = true;

      const form = new FormData();

      form.append('name',this.companyName);
      form.append('nameAR',this.companyNameAR);
      form.append('dimensionalWeight',this.dimensionalWeight);
      form.append('logoImage',this.logoFile);
      form.append('minShipTime',this.minShipTime);
      form.append('maxShipTime',this.maxShipTime);
      form.append('warehouse',this.warehouse);

      axios.post('/api/admins/create_shipping_company',form,{
        headers:{
          Authorization:`Bearer ${auth.adminToken()}`
        }
      }).then((res)=>{

        this.loading = false;

        if(!res.data.error){

          this.message = 'Created successfully';
          this.color = 'indigo';
          this.snackbar = true;
        }else{
          this.message = 'An error occured please try again later';
          this.color = 'error'
          this.snackbar = true;
        }

        this.companyName = '';
        this.dimensionalWeight = '';
        this.logoFile = '';
        this.addCompanyDialog = false;

        this.companies = [...this.companies,res.data.company]
      }).catch((err)=>{
        this.message = 'An error occured please try again later';
        this.color = 'error'
        this.snackbar = true;
        this.loading = false;
      })
    },
    createRange(){
      this.loading = true;

      axios.post('/api/admins/create_range',{
        from:this.from,
        to:this.to,
        price:this.price,
        company:this.selectedCompany._id,
        zone:this.zone,
      },{
        headers:{
          Authorization:`Bearer ${auth.adminToken()}`
        }
      }).then((res)=>{

        this.loading = false;

        if(!res.data.error){

          this.message = 'Created successfully';
          this.color = 'indigo';
          this.snackbar = true;
        }else{
          this.message = 'An error occured please try again later';
          this.color = 'error'
          this.snackbar = true;
        }

        this.from = '';
        this.to = '';
        this.price = '';
        this.addRangeDialog = false;

        this.selectedCompany.ranges.push(res.data.range);

        this.selectedCompany = '';
      }).catch((err)=>{
        this.message = 'An error occured please try again later';
        this.color = 'error'
        this.snackbar = true;
        this.loading = false;
      })
    },
    editRange(){
      this.loading = true;

      axios.post('/api/admins/edit_range',{
        from:this.from,
        to:this.to,
        price:this.price,
        rangeId:this.selectedRange._id,
        zone:this.zone,
      },{
        headers:{
          Authorization:`Bearer ${auth.adminToken()}`
        }
      }).then((res)=>{

        this.loading = false;

        if(!res.data.error){

          this.message = 'edited successfully';
          this.color = 'indigo';
          this.snackbar = true;
        }else{
          this.message = 'An error occured please try again later';
          this.color = 'error'
          this.snackbar = true;
        }

        this.from = '';
        this.to = '';
        this.price = '';
        this.editRangeDialog = false;

        this.selectedRange.from = res.data.range.from;
        this.selectedRange.price = res.data.range.price;
        this.selectedRange.to = res.data.range.to;
        this.selectedRange.zone = res.data.range.zone;

      }).catch((err)=>{
        this.message = 'An error occured please try again later';
        this.color = 'error'
        this.snackbar = true;
        this.loading = false;
      })
    },
    handleDeleteRange(range){
      this.deleteDialog = true
      this.selectedRange = range
    },
    deleteRange(){
      this.loading = true;

      axios.post(`/api/admins/delete_range/`,{rangeId: this.selectedRange._id},{
        headers:{
          Authorization:`Bearer ${auth.adminToken()}`
        }
      }).then((res)=>{

        this.loading = false;

        if(!res.data.error){
          this.message = 'deleted successfully';
          this.color = 'success';
          this.snackbar = true;

          this.deleteDialog = false

          setTimeout(()=>{
            window.location.reload();
          },500)
        }else{
          this.message = 'An error occured please try again later';
          this.color = 'error'
          this.snackbar = true;
        }

      }).catch((err)=>{
        this.message = 'An error occured please try again later';
        this.color = 'error'
        this.snackbar = true;
        this.loading = false;
      })
    },

    removeCompany(id){

      axios.get('/api/admins/remove_company/'+id).then(res=>{

        if(!res.data.error){
          this.snackbar = true;
          this.color= 'warning';
          this.message = res.data.message;

          setTimeout(()=>{
            window.location.reload();
          },2000)
        }else{
          this.snackbar = true;
          this.color = 'error';
          this.message = res.data.message;
        }
      }).catch(err=>{
        console.log(err);
        this.snackbar = true;
        this.color = 'error';
        this.message = 'An error happened please try again';
      })
    },
    onFilesPicked(f){
          this.logoFile = f;
      }
  }
};
</script>

<style scoped>
.detail{
  font-size: 20px;
  font-weight: bold;
}
</style>