<template>
    <v-container>

        <h3>Packages</h3>
        <v-data-table
            :headers="tableHeaders"
            :items="packages"
            class="elevation-2 grey lighten-5 ma-3"
            :page.sync="page"
            :server-items-length="packageCount"
            @update:page="pageUpdateFunction"
            @update:items-per-page="itemsPerPageUpdateFunction"
            :items-per-page.sync="itemsPerPage"
        
        >
            <template v-slot:top class="align-center">
                <v-row class="d-flex align-center">
                    <v-col :cols="10">
                        <v-text-field v-model="query" placeholder="Search" class="mx-4"></v-text-field>
                    </v-col>
                    <v-col :cols="2">
                        <v-btn class="mx-4" @click="searchUpdateFunction" color="primary">Search</v-btn>
                    </v-col>
                </v-row>
            </template>

            <template v-slot:item.username="{ item }">
                <router-link 
                    style="text-decoration:none;font-size:16px;color:#2E86C1"
                    :to="'/en/admin/user/' + item.user.id"
                >
                    <v-icon color="red" class="mx-3" size="15" v-if="(item.user) ? item.user.suspicious : false">fas fa-exclamation-triangle</v-icon>    {{(item.user) ? item.user.firstName + ' ' +  item.user.lastName : ''}}
                </router-link>
            </template>

             <template v-slot:item.userHash="{ item }">
                <div :class="isExpired(item.createdAt)">{{(item.user)? item.user.addressHash :''}}</div>
            </template>

            <template v-slot:item.trackNumber="{ item }">
                <div :class="isExpired(item.createdAt)">{{item.trackNumber}}</div>
            </template>

            <template v-slot:item.packageId="{ item }">
                <div :class="isExpired(item.createdAt)">{{item.packageId}}</div>
            </template>

            <template v-slot:item.status="{ item }">
                <div :class="isExpired(item.createdAt)">{{(item.status)? 'Yes' : 'No'}}</div>
            </template>

            <template v-slot:item.assemble="{ item }">
                <div :class="isExpired(item.createdAt)">{{(item.assemble)? 'Yes' : 'No'}}</div>
            </template>

            <template v-slot:item.disassemble="{ item }">
                <div :class="isExpired(item.createdAt)">{{(item.disassembled)? 'Yes' : 'No'}}</div>
            </template>


            <template v-slot:item.date="{ item }">
                <div :class="isExpired(item.createdAt)">{{ formatDate(item.createdAt)}}</div>
            </template>

            

            <template v-slot:item.actions="{ item }">
                <div>
                    <v-btn @click="showPackage(item)"  class="ma-2" small tile outlined color="primary">
                        <v-icon size="15" left>fas fa-eye</v-icon> Show
                    </v-btn>


                    <v-btn v-if="user.groupId <= adminId" class="ma-2"  small tile outlined @click="setEdit(item)" :disabled="item.status == 'closed'" color="success">
                        <v-icon size="15" left>fas fa-edit</v-icon> Edit
                    </v-btn>

                    <v-btn v-if="user.groupId <= adminId" class="ma-2"  small tile outlined @click="deletePackage(item)" :disabled="loading" color="error">
                        <v-icon size="15" left>fas fa-edit</v-icon> Delete
                    </v-btn>
                </div>
            </template>

            <template v-slot:footer>
                <v-row justify="center">
                    <v-col cols="8">
                        <v-container class="max-width">
                            <v-pagination
                            v-model="page"
                            class="my-4"
                            :total-visible="7"
                            :length="pageCount"
                            @input="pageUpdateFunction"
                            ></v-pagination>
                        </v-container>
                    </v-col>
                </v-row>
            </template>

        </v-data-table>

        

        <v-dialog v-model="dialog" width="600">
                <v-card>
                    <v-card-title>Package</v-card-title>
                    <v-card-text>
                        <v-form ref="form" @submit.prevent="handleSubmit" v-model="valid">
                            <v-text-field
                                v-model="selectedPackage.trackNumber"
                                name="trackNumber"
                                label="Track Number"
                                id="trackNumber"
                                type="text"
                                color="indigo"
                                required
                                :rules="[(v) => !!v || $i18n.t('codeRequired')]"
                            ></v-text-field>

                            <v-text-field
                                v-model="selectedPackage.weight"
                                name="weight"
                                label="Weight"
                                id="weight"
                                type="text"
                                color="indigo"
                                required
                                :rules="[(v) => !!v || $i18n.t('codeRequired')]"
                            ></v-text-field>

                        <v-text-field
                                v-model="selectedPackage.length"
                                name="length"
                                label="Length"
                                id="length"
                                type="text"
                                color="indigo"
                                required
                                :rules="[(v) => !!v || $i18n.t('codeRequired')]"
                            ></v-text-field>


                            <v-text-field
                                v-model="selectedPackage.width"
                                name="width"
                                label="Width"
                                id="width"
                                type="text"
                                color="indigo"
                                required
                                :rules="[(v) => !!v || $i18n.t('codeRequired')]"
                            ></v-text-field>

                               <v-select
                                v-model="shippingOptions.hasDangerous"
                                :items="[
                                    {text:'Yes',value:true},
                                    {text:'No',value:false}
                                ]"
                                :label="$t('dangerousQst')"
                                required
                            
                            ></v-select>
                               <v-select
                                v-model="shippingOptions.hasLithium"
                                :items="[
                                    {text:'Yes',value:true},
                                    {text:'No',value:false}
                                ]"
                                :label="$t('lithiumQst')"
                                required
                            
                            ></v-select>

                            <v-text-field
                                v-model="selectedPackage.height"
                                name="height"
                                label="Hieght"
                                id="height"
                                type="text"
                                color="indigo"
                                required
                                :rules="[(v) => !!v || $i18n.t('codeRequired')]"
                            ></v-text-field>

                            <v-text-field
                                v-model="selectedPackage.fees"
                                name="fees"
                                label="Fees"
                                id="fees"
                                type="text"
                                color="indigo"
                                required
                                :rules="[(v) => !!v || $i18n.t('codeRequired')]"
                            ></v-text-field>

                             <v-file-input
                                    accept="image/*"
                                    chips
                                    multiple
                                    label="Package Images"
                                    prepend-icon="fas fa-paperclip"
                                    @change="this.onFilesPicked"
                                    ></v-file-input>

                            <template v-for="info in selectedPackage.details" >
              <v-row>
                <v-col>
                  <v-text-field
                    v-model="info.description"
                    name="description"
                    label="Description"
                    id="description"
                    type="text"
                    color="indigo"
                    required
                    :rules="[(v) => !!v || $i18n.t('codeRequired')]"
                    ></v-text-field>
                </v-col>
                <v-col>
                  <v-text-field
                    v-model="info.quantity"
                    name="quantity"
                    label="Quantity"
                    id="quantity"
                    type="number"
                    min="0"
                    color="indigo"
                    required
                    :rules="[(v) => !!v || $i18n.t('codeRequired')]"
                    >
                    </v-text-field>
                </v-col>
                <v-col>
                  <v-text-field
                    v-model="info.price"
                    name="price"
                    label="Price"
                    id="price"
                    type="number"
                    min="0"
                    color="indigo"
                    required
                    :rules="[(v) => !!v || $i18n.t('codeRequired')]"
                    ></v-text-field>
                </v-col>
                <v-col>
                  <v-text-field
                    v-model="info.madeIn"
                    name="madeIn"
                    label="Where was the item made in?"
                    id="madeIn"
                    type="text"
                    color="indigo"
                    required
                    :rules="[(v) => !!v || $i18n.t('codeRequired')]"
                    ></v-text-field>
                </v-col>
                <v-col>
                  <v-select
                    v-model="info.type"
                    :items="['Mail', 'Parcels']"
                    label="Type"
                    required
                    :rules="[(v) => !!v || $i18n.t('codeRequired')]"
                    @change="$v.select.$touch()"
                    @blur="$v.select.$touch()"
                  ></v-select>
                </v-col>
                
                <v-col>
                  <v-btn icon color="error" @click="deleteLine(info)"><v-icon color="error" size="30" class="fas fa-times-circle"></v-icon></v-btn>   
                </v-col>
              </v-row>
            </template>
            <v-btn
              depressed
              color="primary"
              @click="addLine"
            >
              New line
            </v-btn>

                                    <div v-if="loading" class="d-flex justify-center">
                                <v-progress-circular :indeterminate="true" size="40" color="indigo"></v-progress-circular>
                            </div>

                            <div v-else class="d-flex justify-center">
                                <v-btn class="ma-2 mx-auto" type="submit" dark color="indigo">Update</v-btn>
                            </div>
                        </v-form>
                    </v-card-text>
                </v-card>
            </v-dialog>

             <v-dialog v-model="showDialog" max-width="600">
                    <v-card class="pa-3">
                        <v-card-title>Information</v-card-title>
                        <v-card-text>
                            <div class="ma-1" style="font-size:16px">
                                <b>Package Id:</b> <span>{{selectedPackage.packageId}}</span>
                            </div>
                            <div class="ma-1" style="font-size:16px">
                                <b>Track Number:</b> <span>{{selectedPackage.trackNumber}}</span>
                            </div>
                            <div class="ma-1" style="font-size:16px">
                                <b>Weight:</b> <span>{{selectedPackage.weight}} kg</span>
                            </div>
                            <div class="ma-1" style="font-size:16px">
                                <b>Dimensions:</b> <span>{{selectedPackage.length}} * {{selectedPackage.width}} * {{selectedPackage.height}} cm</span>
                            </div>
                            <div class="ma-1" style="font-size:16px">
                                <b>Fees:</b> <span>{{selectedPackage.fees ? `${selectedPackage.fees} €` : 'not mentioned'}}</span>
                            </div>

                            <div class="ma-1" style="font-size:16px">
                                <b>Has dangerous materials:</b> <span>{{this.shippingOptions.hasDangerous ? 'Yes' : 'No'}}</span>
                            </div>

                            <div class="ma-1" style="font-size:16px">
                                <b>Has lithium batteries:</b> <span>{{this.shippingOptions.hasLithium ? 'Yes' : 'No'}}</span>
                            </div>
                            
                            <template v-for="detail in selectedPackage.details" >
                                <v-card>
                                    <v-card-text>
                                        <div class="ma-1" style="font-size:16px">
                                            <b>Description:</b> <span>{{detail.description}}</span>
                                        </div>

                                        <div class="ma-1" style="font-size:16px">
                                            <b>Made In:</b> <span>{{detail.madeIn}}</span>
                                        </div>

                                        <div class="ma-1" style="font-size:16px">
                                            <b>Type:</b> <span>{{detail.type}}</span>
                                        </div>

                                        <div class="ma-1" style="font-size:16px">
                                            <b>Quantity:</b> <span>x{{detail.quantity}}</span>
                                        </div>

                                        <div class="ma-1" style="font-size:16px">
                                            <b>price:</b> <span>{{detail.price}}</span>
                                        </div>
                                    </v-card-text>
                                </v-card>
                                
                            </template>

                            <div class="ma-2" style="font-size:20px;font-weight:bold">Images</div>

                            <v-row>
                                <v-col class="images" v-viewer v-for="img in selectedPackage.images" :key="img" sm="4">
                                    <img height="150" class="ma-2 elevation-3" :src="backendUrl + '/' + img"></img>
                                </v-col>
                            </v-row>

                        </v-card-text>

                        <v-card-text v-if="selectedPackage.disassembled">
                            <b>Disassembled Packages:</b>
                            <v-card class="pa-0 ma-4 grey lighten-4" v-for="pack in disassembledPackages" v-viewer :key="pack.id" >
                                <v-row>
                                    <v-col sm="6">
                                        <img height="150" class="ma-2 elevation-3" :src="backendUrl + '/' + pack.images[0]"></img>
                                    </v-col>

                                    <v-col sm="6">
                                        <div class="ma-1" style="font-size:16px"><b>Track Number: </b> <span>{{pack.packageId}}</span></div>
                                        <div class="ma-1" style="font-size:16px"><b>Track Number: </b> <span>{{pack.trackNumber}}</span></div>
                                        <div class="ma-1" style="font-size:16px"><b>Weight: </b> <span>{{pack.weight}} kg</span></div>
                                        <div class="ma-1" style="font-size:16px"><b>dimensions: </b> <span>{{pack.length}} x {{pack.width}} x {{pack.height}} cm </span></div>
                                    </v-col>
                                </v-row>
                            </v-card>
                        </v-card-text>

                        <v-card-text v-if="selectedPackageGroup.length > 0">
                            <b>Grouped with:</b>
                            <v-card class="pa-0 ma-4 grey lighten-4" v-for="pack in selectedPackageGroup" v-viewer :key="pack.id" >
                                <v-row>
                                    <v-col sm="6">
                                        <img height="150" class="ma-2 elevation-3" :src="backendUrl + '/' + pack.images[0]"></img>
                                    </v-col>

                                    <v-col sm="6">
                                        <div class="ma-1" style="font-size:16px"><b>Track Number: </b> <span>{{pack.packageId}}</span></div>
                                        <div class="ma-1" style="font-size:16px"><b>Track Number: </b> <span>{{pack.trackNumber}}</span></div>
                                        <div class="ma-1" style="font-size:16px"><b>Weight: </b> <span>{{pack.weight}} kg</span></div>
                                        <div class="ma-1" style="font-size:16px"><b>dimensions: </b> <span>{{pack.length}} x {{pack.width}} x {{pack.height}} cm </span></div>
                                    </v-col>
                                </v-row>
                            </v-card>
                        </v-card-text>

                        <v-card-actions class="d-flex justify-end">
                        <v-btn @click="showDialog=!showDialog" class="ma-2" color="grey white--text">cancel</v-btn>
                        </v-card-actions>

                    </v-card>
            </v-dialog>

            <v-dialog v-model="deleteDialog" max-width="600">

                <v-card>
                    <v-card-title >Please confirm your action</v-card-title>
                    <v-spacer></v-spacer>
                    <v-card-text>
                    <v-alert
                        type="error"
                        elevation="2"
                    >
                        Are you sure that you want to delete the package with <b>trackNumber: {{selectedPackage.trackNumber}}</b> ?
                    </v-alert>
                    </v-card-text>

                    <v-card-text>
                        <v-textarea
                        label="Reason"
                        v-model="deleteReason"
                        >

                        </v-textarea>
                    </v-card-text>

                    <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn depressed @click="handleDeletePackage(selectedPackage._id)" color="error">Delete</v-btn>
                    <v-btn @click="deleteDialog=!deleteDialog" class="ma-2" color="grey white--text">close</v-btn>
                    </v-card-actions>
                </v-card>

            </v-dialog>



        <v-snackbar left :color="color" v-model="snackbar">
                {{ message }}
            </v-snackbar>
    </v-container>
</template>

<script>
import axios from 'axios';
import auth from '../helpers/auth';
import {backendUrl} from '../config/config';
import moment from 'moment'

export default {
    name:'ManagePackages',
    data(){
        return {
            user: auth.admin(),
            adminId: 1,
            supportId: 2,
            page: 1,
            pageCount: 5,
            packageCount: 1,
            itemsPerPage: 10,
            tableHeaders:[
                {
                    text:'Username',
                    value:'username',
                    align:'center'
                },
                {
                    text:'User Hash',
                    value:'userHash',
                    align:'center'
                },
                {
                    text:'Track Num',
                    value:'trackNumber',
                    align:'center'
                },
                {
                    text:'Pack Id',
                    value:'packageId',
                    align:'center'
                },
                {
                    text:'Date',
                    value:'date',
                    align:'center'
                },
                {
                    text:'Shipped?',
                    value:'status',
                    align:'center'
                },
                {
                    text:'Assemble?',
                    value:'assemble',
                    align:'center'
                },
                {
                    text:'Disassembled?',
                    value:'disassemble',
                    align:'center'
                },
                {
                    text:'Actions',
                    value:'actions',
                    align:'center'
                }
            ],
            packages:[],
            snackbar:false,
            message:'',
            color:'',
            dialog:false,
            selectedPackage:'',
            selectedPackageGroup: {},
            loading:false,
            showDialog:false,
            imgDialog: false,
            deleteDialog: false,
            deleteReason: '',
            displayImg: '',
            backendUrl,
            images:[],
            query: '',
            search:'',
            valid:false,
             shippingOptions: {
                hasDangerous: false,
                hasLithium: false,
            },
          details: [
            {
              description: '',
              madeIn: '',
              type: '',
              quantity: 0,
              price: 0,
              index: 0
            }
          ],
          disassembledPackages: [],
          
        }
    },
    mounted(){
        const urlParams = new URLSearchParams(window.location.search);
        const packageId = urlParams.get('packageId');
        if(packageId) {
            axios.get('/api/packages/get/all?packageId='+packageId).then(res => {
                this.packageCount = res.data.packageCount
                this.pageCount = res.data.pageCount
                this.packages = res.data.packages
            })
        } else {
            axios.get('/api/packages/get/all').then(res => {
                this.packageCount = res.data.packageCount
                this.pageCount = res.data.pageCount
                this.packages = res.data.packages
            })
        }
    },
    watch: {
        query: function (newQuery) {
            if (newQuery === '') {
                axios.get('/api/packages/get/all').then(res => {
                    this.page = 1
                    this.packageCount = res.data.packageCount
                    this.pageCount = res.data.pageCount
                    this.packages = res.data.packages
                })
            }
        }
    },
    sockets: {
        updatePackage(data) {
            const index = this.packages.findIndex(pack => pack._id == data.package._id)
            this.packages[index].details = data.package.details
        }
    },
    methods:{
        isExpired(item){
            const itemDate = moment(item)
            if(itemDate.isBefore(moment().subtract(6, 'months')) === true){
                return "expired"
            } else{
                return ''
            }
        },
        addLine() {

        this.selectedPackage.details.push({description: '', quantity: 0, price: 0, index: this.selectedPackage.details.length * Date.now()})
        },

      deleteLine(info) {
        this.selectedPackage.details = this.selectedPackage.details.filter(item => item.index ? item.index != info.index : item._id != info._id)
      },
        formatDate(date) {
            var d = new Date(date) ,
                month = '' + (d.getMonth() + 1),
                day = '' + d.getDate(),
                year = d.getFullYear(),
                hour = d.getHours(),
                mins = d.getMinutes(),
                secs = d.getSeconds();
            if (month.length < 2) 
                month = '0' + month;
            if (day.length < 2) 
                day = '0' + day;
            if(mins < 10)
            mins = '0' + mins
            if(secs < 10)
            secs = '0' + secs
            if(hour < 10)
            hour = '0' + hour
            return [year, month, day].join('-') + ` ${hour}:${mins}:${secs}`;
        },
        deletePackage(pack) {
            this.dialog = false;
            this.showDialog = false;
            this.selectedPackage = pack;
            this.deleteDialog = true; 
        },
    pageUpdateFunction(newPageNumber) {
        axios.get(`/api/packages/get/all?page=${newPageNumber-1}&perPage=${this.itemsPerPage}`).then(res=>{
            this.packageCount = res.data.packageCount
            this.pageCount = res.data.pageCount
            this.packages = res.data.packages
        })
    },
    itemsPerPageUpdateFunction(length) {
        this.page= 1
        this.pageCount = 1
        axios.get(`/api/packages/get/all?page=${this.pageCount -1}&perPage=${length}`).then(res=>{
            //console.log(res.data)
            this.pageCount = res.data.pageCount
            this.packages = res.data.packages
            this.itemsPerPage = length
        })
    },
    searchUpdateFunction(){
        const newQuery = this.query
        if (newQuery) {
            axios.get(`/api/packages/get/all?query=${newQuery}`).then(res => {
               this.packageCount = res.data.packageCount
               this.pageCount = res.data.pageCount
               this.packages = res.data.packages
           })
        }
    },
    showPackage(pack){
        axios.get(`/api/packages/assemble/${pack._id}`).then(res=>{
            this.selectedPackageGroup = res.data.packagesGroup
        })
        if(pack.disassembled){
            axios.get(`/api/packages/disassemble/get/${pack._id}`).then(res=>{
                this.disassembledPackages = res.data.request.addedPackages
                console.log(this.disassembledPackages)
            });
        }else{
            this.disassembledPackages = []
        }
        this.selectedPackage = pack;
        this.shippingOptions.hasDangerous = pack.shippingOptions.hasDangerous
        this.shippingOptions.hasLithium = pack.shippingOptions.hasLithium
        this.showDialog = true;
    },
    setEdit(item){
        this.selectedPackage = item;
        this.dialog = true;
        this.shippingOptions= item.shippingOptions;
    },
   
    // customFilter (item, queryText, itemText) {
    //     console.log(queryText)
        
    //     const addressHash = item.addressHash.toLowerCase()
    //     const trackNumber = item.trackNumber.toLowerCase();
    //     const searchText = queryText.toLowerCase()
    //     return addressHash.indexOf(searchText) > -1 || trackNumber.indexOf(searchText) > -1
    //   },
    handleDeletePackage(id){

        if(this.deleteReason == ''){
            this.snackbar = true;
            this.color = 'error';
            this.message = 'Please enter a reason for deleting'
            return
        }
        this.loading = true;
        axios.post('/api/packages/delete/'+id,{
            deleteReason: this.deleteReason
        }).then(res=>{
            this.loading = false;
            if(!res.data.error){
                this.snackbar = true;
                this.color = 'warning';
                this.message = 'Deleted Successfully';
                this.packages = this.packages.filter(pack=> pack._id != id)
                this.deleteDialog = false
            }else{
                this.snackbar = true;
                this.color = 'error';
                this.message = 'An error happend please try again later'
            }
            this.loading = false;
        }).catch(err=>{
            console.log(err)
            this.loading = false;
            this.snackbar = true;
            this.color = 'error';
            this.message = 'An error happend please try again later'
        })
    },
    handleSubmit(){
        if (!this.$refs.form.validate()) {
        return;
        }

        this.loading = true;
        const data = new FormData();
        data.append('height',this.selectedPackage.height)
        data.append('weight',this.selectedPackage.weight)
        data.append('length',this.selectedPackage.length)
        data.append('width',this.selectedPackage.width)
        data.append('trackNumber',this.selectedPackage.trackNumber)
        this.images.forEach((item)=>{
           data.append('images',item)
        })

        this.shippingOptions = JSON.stringify(this.shippingOptions);
        data.append("shippingOptions", this.shippingOptions);
        
        data.append('details',JSON.stringify(this.selectedPackage.details))

        axios.post('/api/packages/update/'+this.selectedPackage._id,data).then((res)=>{
             if(!res.data.error){
                 this.loading = false;
                 this.message = 'Updated Successfully';
                 this.color = 'indigo';
                 this.snackbar = true;
                 //emptying the fields
                 this.selectedPackage = res.data.package;
                 this.dialog = false;
                 
             }else{
                 this.loading = false;
                 this.message = res.data.message;
                 this.snackbar = true;
                 this.color = 'error';
             }
         }).catch(err=>{
             console.log(err)
             this.loading = false;
             this.message = 'An error occurred please try again later';
             this.color = 'error';
             this.snackbar = true;
         })
      },
      onFilesPicked(f){
          this.images = f;
      }
  }
}
</script>

<style scoped>
    .expired{
        color: red;
        font-weight: bold;
    }
</style>