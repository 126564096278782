<template>
  <v-container>
    <v-data-table
      :headers="tableHeaders"
      :items="requests"
      :server-items-length="requestCount"
      :page.sync="page"
      :search="search"
      :custom-filter="customFilter"
      class="elevation-2 grey lighten-5 ma-3"
      @update:page="pageUpdateFunction"
      @update:items-per-page="itemsPerPageUpdateFunction"
      :items-per-page.sync="itemsPerPage"
    >
      <template v-slot:top>
        <v-row class="d-flex align-center">
          <v-col :cols="10">
            <v-text-field
              v-model="search"
              placeholder="Search"
              class="mx-4"
            ></v-text-field>
          </v-col>
          <v-col :cols="2">
            <v-btn class="mx-4" @click="searchUpdateFunction" color="primary"
              >Search</v-btn
            >
          </v-col>
        </v-row>
      </template>

      <template v-slot:item.trackNumber="{ item }">
        <div>{{ item.package ? item.package.trackNumber : "" }}</div>
      </template>

      <template v-slot:item.userHash="{ item }">
        <router-link
          style="text-decoration:none;font-size:16px;color:#2E86C1"
          :to="'/en/admin/user/' + item.user.id"
        >
          <v-icon
            color="red"
            class="mx-3"
            size="15"
            v-if="item.user ? item.user.suspicious : false"
            >fas fa-exclamation-triangle</v-icon
          >
          {{ item.user ? item.user.addressHash : "" }}
        </router-link>
      </template>

      <template v-slot:item.status="{ item }">
        <div>{{ item.complete ? "Complete" : "Incomplete" }}</div>
      </template>

      <template v-slot:item.date="{ item }">
        <div>{{ formatDate(item.createdAt) }}</div>
      </template>

      <template v-slot:item.actions="{ item }">
        <div>
          <v-btn
            v-if="user.groupId <= adminId"
            @click="handleFileSelect(item.package._id, item._id)"
            class="ma-2"
            small
            tile
            outlined
            color="primary"
          >
            <v-icon size="15" left>fas fa-upload</v-icon> upload
          </v-btn>

          <v-btn
            v-if="user.groupId <= adminId"
            class="ma-2"
            @click="deleteRequest(item)"
            small
            tile
            outlined
            color="error"
          >
            <v-icon size="15" left>fas fa-trash</v-icon> delete
          </v-btn>

          <v-btn
            v-if="user.groupId <= adminId"
            class="ma-2"
            @click="handleMarkAsComplete(item._id)"
            small
            tile
            outlined
            :disabled="item.complete"
            color="success"
          >
            <v-icon size="15" left>fas fa-check</v-icon> mark as complete
          </v-btn>
        </div>
      </template>

      <template v-slot:footer>
        <v-row justify="center">
          <v-col cols="8">
            <v-container class="max-width">
              <v-pagination
                v-model="page"
                class="my-4"
                :total-visible="7"
                :length="pageCount"
                @input="pageUpdateFunction"
              ></v-pagination>
            </v-container>
          </v-col>
        </v-row>
      </template>
    </v-data-table>

    <v-dialog v-model="deleteDialog" max-width="600">
      <v-card>
        <v-card-title class="headline">Please confirm your action</v-card-title>
        <v-spacer></v-spacer>
        <v-card-text>
          <v-alert border="top" colored-border type="error" elevation="2">
            Are you sure that you want to delete the request with
            <b
              >trackNumber:
              {{
                selectedRequest.package
                  ? selectedRequest.package.trackNumber
                  : ""
              }}</b
            >
            ?
          </v-alert>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            depressed
            @click="handleDeleteRequest(selectedRequest._id)"
            color="error"
            >Delete</v-btn
          >
          <v-btn
            @click="deleteDialog = !deleteDialog"
            class="ma-2"
            color="grey white--text"
            >close</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialog" max-width="600">
      <v-card class="pa-3">
        <v-card-title>Images Upload</v-card-title>
        <v-card-text>
          <v-file-input
            ref="picker"
            accept="image/*"
            chips
            multiple
            label="Package Images"
            prepend-icon="fas fa-paperclip"
            @change="this.onFilesPicked"
          ></v-file-input>
        </v-card-text>

        <v-card-actions v-if="!loading">
          <v-btn class="ma-2" @click="handleUpload" color="primary"
            >Upload</v-btn
          >
          <v-btn class="ma-2" @click="handleCancel" color="default"
            >Cancel</v-btn
          >
        </v-card-actions>

        <v-card-actions v-else class="d-flex justify-center">
          <v-progress-circular
            :indeterminate="true"
            size="40"
            color="indigo"
          ></v-progress-circular>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar left :color="color" v-model="snackbar">{{
      message
    }}</v-snackbar>
  </v-container>
</template>

<script>
import axios from "axios";
import auth from "../helpers/auth";

export default {
  name: "ImageRequests",
  data() {
    return {
      user: auth.admin(),
      adminId: 1,
      supportId: 2,
      page: 1,
      pageCount: 5,
      requestCount: 1,
      itemsPerPage: 10,
      requests: [],
      tableHeaders: [
        {
          text: "Track Number",
          value: "trackNumber",
          align: "center",
        },
        {
          text: "User Hash",
          value: "userHash",
          align: "center",
        },
        {
          text: "Request Date",
          value: "date",
          align: "center",
        },
        {
          text: "Status",
          value: "status",
          align: "center",
        },
        {
          text: "Actions",
          value: "actions",
          align: "center",
        },
      ],
      search: "",
      dialog: false,
      deleteDialog: false,
      images: [],
      selectedRequest: "",
      loading: false,
      snackbar: false,
      color: "",
      message: "",
      requestId: "",
    };
  },
  mounted() {
    axios.get("/api/packages/images/get").then((resp) => {
      this.requestCount = resp.data.requestCount;
      this.pageCount = resp.data.pageCount;
      this.requests = resp.data.imageRequests.filter((item) => item);
    });
  },

  watch: {
    search: function(newSearch) {
      if (newSearch === "") {
        axios.get("/api/packages/images/get").then((res) => {
          this.page = 1;
          this.requestCount = res.data.requestCount;
          this.pageCount = res.data.pageCount;
          this.requests = res.data.imageRequests.filter((item) => item);
        });
      }
    },
  },

  methods: {
    pageUpdateFunction(newPageNumber) {
      axios
        .get(
          `/api/packages/images/get?page=${newPageNumber - 1}&perPage=${
            this.itemsPerPage
          }`
        )
        .then((res) => {
          this.page = newPageNumber;
          this.requestCount = res.data.requestCount;
          this.pageCount = res.data.pageCount;
          this.requests = res.data.imageRequests.filter((item) => item);
        });
    },
    itemsPerPageUpdateFunction(length) {
      this.page = 1;
      this.pageCount = 1;
      axios
        .get(
          `/api/packages/images/get?page=${this.pageCount -
            1}&perPage=${length}`
        )
        .then((res) => {
          this.requestCount = res.data.requestCount;
          this.pageCount = res.data.pageCount;
          this.requests = res.data.imageRequests.filter((item) => item);
        });
    },

    searchUpdateFunction() {
      const newQuery = this.search;
      if (newQuery) {
        axios.get(`/api/packages/images/get?query=${newQuery}`).then((res) => {
          this.requestCount = res.data.requestCount;
          this.pageCount = res.data.pageCount;
          this.requests = res.data.imageRequests.filter((item) => item);
        });
      }
    },

    formatDate(date) {
      var d = new Date(date),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear(),
        hour = d.getHours(),
        mins = d.getMinutes();

      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;
      if (mins < 10) mins = "0" + mins;
      if (hour < 10) hour = "0" + hour;

      return [year, month, day].join("-") + ` ${hour}:${mins}`;
    },
    onFilesPicked(f) {
      this.images = f;
    },
    handleCancel() {
      this.dialog = false;
      this.selectedRequest = "";
    },
    handleFileSelect(id, requestId) {
      this.dialog = true;
      this.selectedRequest = id;
      this.requestId = requestId;
    },
    handleUpload() {
      this.loading = true;

      const data = new FormData();

      data.append("packageId", this.selectedRequest);
      data.append("requestId", this.requestId);

      this.images.forEach((item) => {
        data.append("images", item);
      });

      axios
        .post("/api/packages/images/update", data)
        .then((res) => {
          if (!res.data.error) {
            this.loading = false;
            this.message = "Uploaded Successfully";
            this.color = "indigo";
            this.snackbar = true;

            this.requests.forEach((item) => {
              if (item._id == this.requestId) {
                item.complete = true;
              }
            });

            this.images = [];
            this.$refs.picker.value = null;
          }
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
          this.message = "An error occurred please try again later";
          this.color = "error";
          this.snackbar = true;
        });
    },
    handleMarkAsComplete(id) {
      axios
        .post("/api/packages/images/complete", { requestId: id })
        .then((res) => {
          if (!res.error) {
            this.message = "Updated Successfully";
            this.color = "success";
            this.snackbar = true;

            this.requests.forEach((item) => {
              if (item._id == id) {
                item.complete = true;
              }
            });
          }
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
          this.message = "An error occurred please try again later";
          this.color = "error";
          this.snackbar = true;
        });
    },

    deleteRequest(request) {
      this.dialog = false;

      this.selectedRequest = request;
      this.deleteDialog = true;
    },

    handleDeleteRequest(id) {
      axios
        .post("/api/packages/images/delete", { requestId: id })
        .then((res) => {
          if (!res.error) {
            this.message = "Updated Successfully";
            this.color = "warning";
            this.snackbar = true;

            this.requests = this.requests.filter((item) => {
              return item._id != id;
            });

            this.deleteDialog = false;
          }
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
          this.message = "An error occurred please try again later";
          this.color = "error";
          this.snackbar = true;
        });
    },
    customFilter(value, queryText, item) {
      const trackNumber = item.package.trackNumber.toLowerCase();
      const searchText = queryText.toLowerCase();

      return trackNumber.indexOf(searchText) > -1;
    },
  },
};
</script>
