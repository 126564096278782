<template>
    <v-container>
        <v-data-table
            :headers="tableHeaders"
            :items="transactions"
            :items-per-page="10"
            :search="search"
            :custom-filter="customFilter"
            class="elevation-2 grey lighten-5 ma-3"
        >

            <template v-slot:top>
                <v-text-field v-model="search" placeholder="Search" class="mx-4"></v-text-field>
            </template>
            <template v-slot:item.username="{ item }">
                <router-link 
                    style="text-decoration:none;font-size:16px;color:#2E86C1"
                    :to="'/en/admin/user/' + item.user.id"
                >
                    <v-icon color="red" class="mx-3" size="15" v-if="(item.user) ? item.user.suspicious : false">fas fa-exclamation-triangle</v-icon> {{(item.user) ? item.user.fullName : ''}}
                </router-link>
            </template>

            <template v-slot:item.email="{ item }">
                <div :class="isExpired(item.createdAt)">{{item.user.email}}</div>
            </template>

            <template v-slot:item.userHash="{ item }">
                <router-link 
                    style="text-decoration:none;font-size:16px;color:#2E86C1"
                    :to="'/en/admin/user/' + item.user.id"
                >
                    {{(item.user) ? item.user.addressHash : ''}}
                </router-link>
            </template>

            <template v-slot:item.amount="{ item }">
                <div :class="isExpired(item.createdAt)">{{item.amount}}</div>
            </template>

            <template v-slot:item.type="{ item }">
                <div :class="isExpired(item.createdAt)">{{item.offlinePaymentType}}</div>
            </template>

            <template v-slot:item.date="{ item }">
                <div :class="isExpired(item.createdAt)">{{ formatDate(item.createdAt)}}</div>
            </template>

            <template v-slot:item.actions="{ item }">
                <div>
                    <v-btn v-if="!item.complete && user.groupId <= adminId" class="ma-2" @click="selectPackage(item.package)"   small tile outlined color="primary">
                        <v-icon size="15" left>fas fa-eye</v-icon> show
                    </v-btn>
                    <v-btn v-if="user.groupId <= adminId" @click.stop="confirm(item)" class="ma-2" small tile outlined :disabled="loading" color="success">
                        <v-icon size="15" left>fas fa-check-circle</v-icon> confirm
                    </v-btn>
                    
                    <v-btn v-if="!item.complete && user.groupId <= adminId" class="ma-2" @click="cancelRequest(item)"   small tile outlined color="warning">
                        <v-icon size="15" left>fas fa-times-circle</v-icon> cancel
                    </v-btn>

                </div>
            </template>
        </v-data-table>

        <v-dialog v-model="cancelDialog" max-width="600">

            <v-card>
                <v-card-title class="headline">Please confirm your action</v-card-title>
                <v-spacer></v-spacer>
                <v-card-text>
                <v-alert
                    border="top"
                    colored-border
                    type="warning"
                    elevation="2"
                >
                    Are you sure that you want to cancel this transaction ?
                </v-alert>
                </v-card-text>

                <v-card-actions>
                <v-spacer></v-spacer>

                <v-btn depressed @click="handleCancelRequest(selectedRequest._id)" color="error">Cancel Transaction</v-btn>
                <v-btn @click="cancelDialog=!cancelDialog" class="ma-2" color="grey white--text">close</v-btn>
                </v-card-actions>
            </v-card>

        </v-dialog>


        <v-dialog v-model="dialog" max-width="600">

            <v-card>
                <v-card-title class="headline">Please confirm your action</v-card-title>
                <v-spacer></v-spacer>
                <v-card-text>
                <v-alert
                    border="top"
                    colored-border
                    type="warning"
                    elevation="2"
                >
                    Are you sure that you want to accept this payment ?
                </v-alert>
                </v-card-text>

                <v-card-actions>
                <v-spacer></v-spacer>

                <v-btn depressed @click="handleConfirm(selectedRequest._id)" color="success">Confirm</v-btn>
                <v-btn @click="dialog=!dialog" class="ma-2" color="grey white--text">close</v-btn>
                </v-card-actions>
            </v-card>

        </v-dialog>

         <!-- package info modal -->

    <v-dialog v-model="packageDailog" max-width="600">
      <v-card class="pa-3">
        <v-card-title>Information</v-card-title>
        <v-card-text>
          <div class="ma-1" style="font-size:16px">
            <b>Package Id:</b> <span>{{selectedPackage.packageId}}</span>
          </div>
          <div class="ma-1" style="font-size:16px">
            <b>Track Number:</b> <span>{{selectedPackage.trackNumber}}</span>
          </div>
          <div class="ma-1" style="font-size:16px">
            <b>Weight:</b> <span>{{selectedPackage.weight}} kg</span>
          </div>
          <div class="ma-1" style="font-size:16px">
            <b>dimensions:</b> <span>{{selectedPackage.length}} * {{selectedPackage.width}} * {{selectedPackage.height}} cm</span>
          </div>
          <div class="ma-1" style="font-size:16px">
              <b>Fees:</b> <span>{{selectedPackage.fees ? `${selectedPackage.fees} €` : 'not mentioned'}}</span>
          </div>


          <template v-for="detail in selectedPackage.details" >
            <v-card>
                <v-card-text>
                    <div class="ma-1" style="font-size:16px">
                        <b>Description:</b> <span>{{detail.description}}</span>
                    </div>

                    <div class="ma-1" style="font-size:16px">
                        <b>Made In:</b> <span>{{detail.madeIn}}</span>
                    </div>

                    <div class="ma-1" style="font-size:16px">
                        <b>Type:</b> <span>{{detail.type}}</span>
                    </div>

                    <div class="ma-1" style="font-size:16px">
                        <b>Quantity:</b> <span>x{{detail.quantity}}</span>
                    </div>

                    <div class="ma-1" style="font-size:16px">
                        <b>price:</b> <span>{{detail.price}}</span>
                    </div>
                </v-card-text>
            </v-card>
          </template>


          <div class="ma-2" style="font-size:20px;font-weight:bold">Images</div>

          <v-row>
            <v-col v-for="img in selectedPackage.images" v-viewer :key="img" sm="4">
              <img height="150" class="ma-2 elevation-3" :src="backendUrl + '/' + img"></img>
            </v-col>
          </v-row>

          <div class="ma-2" style="font-size:20px;font-weight:bold">Shipping Address</div>

          <div v-if="selectedPackage.shippingAddress">

            <div class="ma-1" style="font-size:16px">
              <b>Name:</b> <span>{{selectedPackage.shippingAddress.name}}</span>
            </div>
            <div class="ma-1" style="font-size:16px">
              <b>Country:</b> <span>{{selectedPackage.shippingAddress.country}}</span>
            </div>
            <div class="ma-1" style="font-size:16px">
              <b>City:</b> <span>{{selectedPackage.shippingAddress.city}}</span>
            </div>
            <div class="ma-1" style="font-size:16px">
              <b>Address Line One:</b> <span>{{selectedPackage.shippingAddress.addressLineOne}}</span>
            </div>
            <div class="ma-1" style="font-size:16px">
              <b>Address Line Two:</b> <span>{{selectedPackage.shippingAddress.addressLineTwo}}</span>
            </div>

            <div class="ma-1" style="font-size:16px">
              <b>Phone:</b> <span>{{selectedPackage.shippingAddress.phone}}</span>
            </div>

            <div class="ma-1" style="font-size:16px">
              <b>Postal:</b> <span>{{selectedPackage.shippingAddress.zip}}</span>
            </div>
    
          </div>

          <br>
          
          <div class="ma-2" style="font-size:20px;font-weight:bold">Shipping Options</div>

          <div v-if="selectedPackage.shippingOptions">

            <div class="ma-1" style="font-size:16px">
              <b>Does it contain lithium batteries?:</b> <span :style="selectedPackage.shippingOptions.hasLithium ? '' : 'color:red'">{{selectedPackage.shippingOptions.hasLithium ? 'Yes' : 'No'}}</span>
            </div>
            <div class="ma-1" style="font-size:16px">
              <b>Does it contain dangerous substances (such as perfumes)?:</b> <span :style="selectedPackage.shippingOptions.hasDangerous ? '' : 'color:red'">{{selectedPackage.shippingOptions.hasDangerous ? 'Yes' : 'No'}}</span>
            </div>
            <div class="ma-1" style="font-size:16px">
              <b>Remove bills:</b> <span :style="selectedPackage.shippingOptions.removeBills ? '' : 'color:red'">{{selectedPackage.shippingOptions.removeBills ? 'Yes' : 'No'}}</span>
            </div>
            <div class="ma-1" style="font-size:16px">
              <b>Remove shoe boxes:</b> <span :style="selectedPackage.shippingOptions.removeShoeBoxes ? '' : 'color:red'">{{selectedPackage.shippingOptions.removeShoeBoxes ? 'Yes' : 'No'}}</span>
            </div>
            <div class="ma-1" style="font-size:16px">
              <b>Additional notes:</b> <br> 
              <span>{{selectedPackage.shippingOptions.additionalNote}}</span>
            </div>

             <div class="mt-5" style="font-size:20px;font-weight:bold">Shipping Company</div>

          <div v-if="selectedPackage.shipMethod">

              <div class="ma-1" style="font-size:16px">
            <b>Name:</b> <span>{{selectedPackage.shipMethod.company.name}}</span>
          </div>

          </div>

          </div>
        </v-card-text>          
          <v-btn @click="packageDailog=!packageDailog" class="ma-2" color="grey white--text">close</v-btn>
        </v-card-actions>

      </v-card>
    </v-dialog>

        <v-snackbar left :color="color" v-model="snackbar">{{ message }}</v-snackbar>
    </v-container>
</template>

<script>
import axios from "axios";
import moment from "moment"
import auth from "../helpers/auth";
import { backendUrl } from "../config/config";

export default {
  name: "OfflinePayment",
  data() {
    return {
      user: auth.admin(),
      adminId: 1,
      supportId: 2,
      selectedPackage: {},
      packageDailog: false,
      tableHeaders: [
        {
          text: "Username",
          value: "username",
          align: "center",
        },
        {
          text: "Email",
          value: "email",
          align: "center",
        },
        {
          text: "User Hash",
          value: "userHash",
          align: "center",
        },
        {
          text: "Type",
          value: "type",
          align: "center",
        },
        {
          text: "Transaction Date",
          value: "date",
          align: "center",
        },
        {
          text: "Amount (EUR)",
          value: "amount",
          align: "center",
        },
        {
          text: "Actions",
          value: "actions",
          align: "center",
        },
      ],
      search: "",
      dialog: false,
      cancelDialog: false,
      transactions: [],
      selectedRequest: "",
      loading: false,
      snackbar: false,
      color: "",
      message: "",
      requestId: "",
      backendUrl,
    };
  },
  mounted() {
    axios.get("/api/admins/transactions/offline").then((resp) => {
      this.transactions = resp.data.transactions;
    });
  },

  sockets: {
    loadinOfflineTransactions(transactions) {
      //console.log(data.notifications)
      // Fired when a approval requests got updated.
      this.transactions = transactions;
    },
  },

  methods: {
    isExpired(item){
        const itemDate = moment(item)
        if(itemDate.isBefore(moment().subtract(6, 'months')) === true){
            return "expired"
        } else{
            return ''
        }
    },
    selectPackage(item) {
      this.selectedPackage = item;
      this.packageDailog = !this.packageDailog;
    },
    formatDate(date) {
      var d = new Date(date),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear(),
        hour = d.getHours(),
        mins = d.getMinutes();

      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;
      if (mins < 10) mins = "0" + mins;
      if (hour < 10) hour = "0" + hour;

      return [year, month, day].join("-") + ` ${hour}:${mins}`;
    },

    handleConfirm(id) {
      this.loading = true;

      axios
        .get("/api/admins/bitcoin/confirm_payment/" + id)
        .then((res) => {
          if (!res.data.error) {
            this.loading = false;
            this.message = "Confirmed Successfully";
            this.color = "success";
            this.snackbar = true;

            this.transactions = this.transactions.filter(
              (item) => item._id != id
            );
            this.dialog = false;
          }
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
          this.message = "An error occurred please try again later";
          this.color = "error";
          this.snackbar = true;
        });
    },

    confirm(request) {
      this.cancelDialog = false;
      this.selectedRequest = request;
      this.dialog = true;
    },

    cancelRequest(item) {
      this.dialog = false;
      this.selectedRequest = item;
      this.cancelDialog = true;
    },

    handleCancelRequest(id) {
      axios
        .post("/api/admins/transactions/offline/cancel", {
          transactionId: this.selectedRequest._id,
          userId: this.selectedRequest.user.id,
          packageId: this.selectedRequest.package,
        })
        .then((res) => {
          if (!res.error) {
            this.message = "Request was canceled Successfully";
            this.color = "warning";
            this.snackbar = true;

            this.transactions = this.transactions.filter((item) => {
              return item._id != id;
            });

            this.cancelDialog = false;
          }
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
          this.message = "An error occurred please try again later";
          this.color = "error";
          this.snackbar = true;
        });
    },

    customFilter(value, queryText, item) {
      const trackNumber = item.package.trackNumber.toLowerCase();
      const searchText = queryText.toLowerCase();

      return trackNumber.indexOf(searchText) > -1;
    },
  },
};
</script>

<style scoped>
    .expired{
        color: red;
        font-weight: bold;
    }
</style>