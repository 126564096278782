<template>
  <v-col sm="12" md="12" lg="3">
    <v-card>
      <!-- <b class="flag-emoji">🇫🇷</b> -->
      <div class="flag-icon" @click="dialog = !dialog">
        <v-img 
        :src="backendUrl + '/' + flagImage" alt="" ></v-img>
      </div>
      <v-img
        style="cursor: pointer"
        @click="dialog = !dialog"
        height="250"
        :src="backendUrl + '/' + this.userPackage.images[0]"
      >
        <div
          class="package__info"
          :class="`package__info ${
            this.$i18n.locale == 'ar'
              ? 'package__info--ar'
              : 'package__info--en'
          }`"
        >
          <span class="package__tag">
            <v-icon class="package__tag-icon">fas fa-qrcode</v-icon>
            {{ this.userPackage.packageId }}
          </span>
          <span class="package__tag">
            <v-icon class="package__tag-icon">fas fa-barcode</v-icon>
            {{ this.userPackage.trackNumber }}
          </span>
        </div>
      </v-img>

      <!-- Diassemble Dialog  -->
      <v-dialog v-model="disassembleDialog" width="650">
        <v-card class="pa-1">
          <v-card-title
            style="font-size: 20px; word-break: break-word"
            class="ma-2"
            >{{ $t("disassemble") }}</v-card-title
          >

          <v-card-text>
            <v-list>
              <v-list-item-group>
                <v-list-item @click="removeShoeBoxes = !removeShoeBoxes">
                  <v-list-item-action>
                    <v-checkbox
                      color="primary"
                      :value="removeShoeBoxes"
                    ></v-checkbox>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title
                      >{{ $t("removeShoeBoxes") }} -
                      {{ $t("free") }}</v-list-item-title
                    >
                    <v-list-item-subtitle>{{
                      $t("removeShoeBoxesDesc")
                    }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item @click="removeBills = !removeBills">
                  <v-list-item-action>
                    <v-checkbox
                      color="primary"
                      :value="removeBills"
                    ></v-checkbox>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title
                      >{{ $t("removeBills") }} -
                      {{ $t("free") }}</v-list-item-title
                    >
                    <v-list-item-subtitle>{{
                      $t("removeBillsDesc")
                    }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
            <v-textarea
              outlined
              name="input-7-4"
              hide-details="false"
              v-model="disassembleNote"
              :label="$t('disassembleNotes')"
              required
              value="..."
              class="mb-2"
            ></v-textarea>
            <span>{{ $t("disassembleNotesDesc") }}</span>
          </v-card-text>

          <v-card-actions class="d-flex justify-center">
            <v-btn @click="handleDisassemble" v-if="!loading" color="success">{{
              $t("disassembleRequest")
            }}</v-btn>
            <v-progress-circular
              v-else
              :indeterminate="true"
              size="40"
              color="indigo"
            ></v-progress-circular>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- Assemble Dialog  -->
      <v-dialog v-model="assembleDialog" width="650">
        <v-card class="pa-1">
          <v-card-title
            style="font-size: 20px; word-break: break-word"
            class="ma-2"
            >{{ $t("assembleText") }}</v-card-title
          >

          <v-card-text>
            <v-row>
              <v-col
                v-for="item in this.assemblePackages"
                :key="item._id"
                sm="12"
                md="6"
                lg="4"
              >
                <v-card elevation="4">
                  <v-label>
                    <v-img
                      style="cursor: pointer"
                      height="150"
                      :src="backendUrl + '/' + item.image"
                      for="assembleCheckbox"
                    >
                      <div
                        class="d-flex justify-end align-end"
                        style="height: 100%; width: 100%"
                      >
                        <input
                          id="assembleCheckbox"
                          type="checkbox"
                          v-model="item.checkbox"
                          :disabled="item.disabled"
                          style="width: 25px; height: 25px"
                        />
                      </div>
                    </v-img>
                  </v-label>
                </v-card>
              </v-col>
            </v-row>
            <br />
            <v-divider></v-divider>
            <br />
            <!-- Here -->
            <v-list>
              <v-list-item-group>
                <v-list-item @click="removeShoeBoxes = !removeShoeBoxes">
                  <v-list-item-action>
                    <v-checkbox
                      color="primary"
                      :value="removeShoeBoxes"
                    ></v-checkbox>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title
                      >{{ $t("removeShoeBoxes") }} -
                      {{ $t("free") }}</v-list-item-title
                    >
                    <v-list-item-subtitle>{{
                      $t("removeShoeBoxesDesc")
                    }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item @click="removeBills = !removeBills">
                  <v-list-item-action>
                    <v-checkbox
                      color="primary"
                      :value="removeBills"
                    ></v-checkbox>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title
                      >{{ $t("removeBills") }} -
                      {{ $t("free") }}</v-list-item-title
                    >
                    <v-list-item-subtitle>{{
                      $t("removeBillsDesc")
                    }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
            <v-textarea
              outlined
              name="input-7-4"
              hide-details="false"
              v-model="assembleNote"
              :label="$t('additionalNotes')"
              value="..."
              class="mb-2"
            ></v-textarea>
            <span>{{ $t("assembleNotesDesc") }}</span>
          </v-card-text>

          <v-card-actions class="d-flex justify-center">
            <v-btn @click="handleAssemble" v-if="!loading" color="success">{{
              $t("assemblyRequest")
            }}</v-btn>
            <v-progress-circular
              v-else
              :indeterminate="true"
              size="40"
              color="indigo"
            ></v-progress-circular>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="modifyDialog" width="650">
        <v-card class="pa-1 package__dialog">
          <v-card-title style="font-size: 20px" class="ma-2">
            {{ $t("modifyPackDesc") }}
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-card-text>
              <v-row wrap v-for="detail in this.updatedPackage" :key="detail">
                <v-col cols="12" md="6">
                  <v-text-field
                    :value="detail.description"
                    name="description"
                    :label="$t('description')"
                    id="description"
                    color="indigo"
                    type="text"
                    disabled
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="detail.price"
                    name="price"
                    :label="$t('priceEuro')"
                    id="price"
                    type="number"
                    min="0"
                    color="indigo"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-btn @click="toggleUpdateDesc" color="danger">{{
              $t("cancel")
            }}</v-btn>
            <v-btn @click="updateDescription" color="success">{{
              $t("save")
            }}</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="addressDialog" width="650">
        <v-card class="pa-1">
          <v-card-title style="font-size: 20px" class="ma-2">{{
            $t(currentTitle)
          }}</v-card-title>

          <v-card-text>
            <v-btn
              v-if="step === 1"
              @click="addAddressDialog = !addAddressDialog"
              color="success"
              >{{ $t("addAddress") }}</v-btn
            >
          </v-card-text>

          <v-card-text>
            <v-window v-model="step">
              <v-window-item :value="1">
                <v-card
                  class="ma-2"
                  v-for="address in userAddresses"
                  :key="address._id"
                >
                  <v-card-text>
                    <div class="ma-1">
                      <span class="blue--text">{{ $t("name") }}: </span>
                      {{ address.name }}
                    </div>
                    <div class="ma-1">
                      <span class="blue--text">{{ $t("addressFirst") }}: </span>
                      {{ address.addressLineOne }}
                    </div>
                    <div class="ma-1">
                      <span class="blue--text"
                        >{{ $t("addressSecond") }}:
                      </span>
                      {{ address.addressLineTwo }}
                    </div>
                    <div class="ma-1">
                      <span class="blue--text">{{ $t("country") }}: </span>
                      {{ address.country }}
                    </div>
                    <div class="ma-1">
                      <span class="blue--text">{{ $t("city") }}: </span>
                      {{ address.city }}
                    </div>
                    <div class="ma-1">
                      <span class="blue--text">{{ $t("phone") }}: </span>
                      {{ address.phone }}
                    </div>
                    <div class="ma-1">
                      <span class="blue--text">{{ $t("postal") }}: </span>
                      {{ address.zip }}
                    </div>
                  </v-card-text>

                  <v-card-actions>
                    <v-btn
                      v-if="selectedAddress != address._id"
                      @click="selectAddress(address)"
                      color="success"
                      >{{ $t("select") }}</v-btn
                    >
                    <!-- Here -->
                    <div
                      v-else-if="
                        selectedCountry == 'Algeria' &&
                        selectedAddressNationalID === ''
                      "
                      class="error--text"
                    >
                      {{ $t("addNationalID") }}
                    </div>
                    <div v-else class="success--text">{{ $t("selected") }}</div>
                  </v-card-actions>
                </v-card>
              </v-window-item>

              <v-window-item :value="2">
                <v-list>
                  <v-list-item-group>
                    <v-list-item @click="removeShoeBoxes = !removeShoeBoxes">
                      <v-list-item-action>
                        <v-checkbox
                          color="primary"
                          :value="removeShoeBoxes"
                        ></v-checkbox>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title
                          >{{ $t("removeShoeBoxes") }} -
                          {{ $t("free") }}</v-list-item-title
                        >
                        <v-list-item-subtitle>{{
                          $t("removeShoeBoxesDesc")
                        }}</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>

                    <v-list-item @click="removeBills = !removeBills">
                      <v-list-item-action>
                        <v-checkbox
                          color="primary"
                          :value="removeBills"
                        ></v-checkbox>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title
                          >{{ $t("removeBills") }} -
                          {{ $t("free") }}</v-list-item-title
                        >
                        <v-list-item-subtitle>{{
                          $t("removeBillsDesc")
                        }}</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list-item-group>
                </v-list>
                <br />
                <v-divider></v-divider>
                <br />
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-textarea
                      v-bind="attrs"
                      v-on="on"
                      outlined
                      name="input-7-4"
                      v-model="additionalNote"
                      :label="$t('additionalNotes')"
                      value="..."
                    ></v-textarea>
                  </template>
                  <span>{{ $t("additionalNotesDesc") }}</span>
                </v-tooltip>
              </v-window-item>
            </v-window>
          </v-card-text>

          <v-card-actions v-if="approveLoading" class="d-flex justify-center">
            <v-progress-circular
              :indeterminate="true"
              size="40"
              color="indigo"
            ></v-progress-circular>
          </v-card-actions>

          <v-card-actions v-else class="d-flex justify-center">
            <v-btn :disabled="step === 1" text @click="step--">
              {{ $t("back") }}
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
              :disabled="step === 2"
              v-if="step !== 2 && selectedAddress"
              color="primary"
              depressed
              @click="step++"
            >
              {{ $t("next") }}
            </v-btn>
            <v-btn
              width="100"
              @click.stop="requestPackageApprove"
              v-if="selectedAddress && step === 2"
              color="primary"
            >
              {{ $t("continue") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="dialog" width="800">
        <v-card class="pa-1">
          <v-card-text
            style="
              display: flex;
              justify-content: space-between;
              padding-left: 0;
            "
          >
            <v-row>
              <v-col class="pa-0 pt-1" cols="12" md="4">
                <ul style="list-style: none">
                  <li>
                    <v-icon class="package__dialog-icon" size="14"
                      >fas fa-arrows-alt-v</v-icon
                    >
                    <span style="text-transform: uppercase; font-size: 14px"
                      >{{ " " }} <b>{{ $t("height") }}:</b>
                      {{ this.userPackage.height }}
                      {{ $t("cm") }}
                    </span>
                  </li>

                  <li>
                    <v-icon class="package__dialog-icon" size="14"
                      >fas fa-ruler-horizontal</v-icon
                    >
                    <span style="text-transform: uppercase; font-size: 14px"
                      >{{ " " }} <b>{{ $t("length") }}:</b>
                      {{ this.userPackage.length }}
                      {{ $t("cm") }}
                    </span>
                  </li>
                  <li>
                    <v-icon class="package__dialog-icon" size="14"
                      >fas fa-arrows-alt-h</v-icon
                    >
                    <span style="text-transform: uppercase; font-size: 14px"
                      >{{ " " }} <b>{{ $t("width") }}:</b>
                      {{ this.userPackage.width }}
                      {{ $t("cm") }}
                    </span>
                  </li>
                  <li>
                    <v-icon class="package__dialog-icon" size="14"
                      >fas fa-weight-hanging</v-icon
                    >
                    <span
                      >{{ " " }}<b>{{ $t("weight") }}:</b>
                      {{ this.userPackage.weight }} kg
                    </span>
                  </li>
                </ul>
              </v-col>

              <v-col class="pa-0 pt-1" cols="12" md="4">
                <ul style="list-style: none">
                  <li>
                    <v-icon class="package__dialog-icon" size="14"
                      >fas fa-warehouse</v-icon
                    >
                    <span style="text-transform: uppercase; font-size: 14px"
                      >{{ " " }} <b>{{ $t("warehouse") }}:</b>
                      {{this.userPackage.warehouse.country}}
                    </span>
                  </li>
                  <li>
                    <v-icon class="package__dialog-icon" size="14"
                      >fas fa-barcode</v-icon
                    >
                    <span
                      >{{ " " }} <b>{{ $t("trackNumber") }}:</b>
                      {{ this.userPackage.trackNumber }}
                    </span>
                  </li>
                  <li>
                    <v-icon class="package__dialog-icon" size="14"
                      >fas fa-qrcode</v-icon
                    >
                    <span
                      >{{ " " }}<b>{{ $t("packageId") }}:</b>
                      {{ this.userPackage.packageId }}
                    </span>
                  </li>
                </ul>
              </v-col>

              <v-col class="pa-0 pt-1" cols="12" md="4">
                <ul style="list-style: none">
                  <li>
                    <v-icon class="package__dialog-icon" size="14"
                      >fas fa-exclamation-triangle</v-icon
                    >
                    <span style="text-transform: uppercase">
                      {{ " " }}<b>{{ $t("dangerousInfo") }}</b>
                      {{
                        this.userPackage.shippingOptions.hasDangerous
                          ? $t("affirmative")
                          : $t("negative")
                      }}
                    </span>
                  </li>
                  <li>
                    <v-icon class="package__dialog-icon" size="14"
                      >fas fa-battery-three-quarters</v-icon
                    >
                    <span style="text-transform: uppercase">
                      {{ " " }}<b>{{ $t("lithiumInfo") }}</b>
                      {{
                        this.userPackage.shippingOptions.hasLithium
                          ? $t("affirmative")
                          : $t("negative")
                      }}
                    </span>
                  </li>
                  <li>
                    <v-icon class="package__dialog-icon" size="14"
                      >fas fa-calendar</v-icon
                    >
                    <span style="text-transform: uppercase">
                      {{ " " }}<b>{{ $t("date") }}:</b>
                      {{ formatDate(this.userPackage.createdAt) }}
                    </span>
                  </li>
                  <li
                    v-if="
                      this.userPackage.awaitingApprove === true ||
                      this.userPackage.approved === true
                    "
                  >
                    <v-icon class="package__dialog-icon" size="14"
                      >fas fa-user</v-icon
                    >
                    <span
                      >{{ " " }}<b>{{ $t("fullName") }}:</b>
                      {{ this.userPackage.shippingAddress.name }}
                    </span>
                  </li>
                  <li
                    v-if="
                      this.userPackage.awaitingApprove === true ||
                      this.userPackage.approved === true
                    "
                  >
                    <v-icon class="package__dialog-icon" size="14"
                      >fas fa-map</v-icon
                    >
                    <span
                      >{{ " " }}<b>{{ $t("address") }}:</b>
                      {{
                        `${this.userPackage.shippingAddress.addressLineOne}, ${this.userPackage.shippingAddress.addressLineTwo}, ${this.userPackage.shippingAddress.city}, ${this.userPackage.shippingAddress.country}`
                      }}
                    </span>
                  </li>
                </ul>
              </v-col>
            </v-row>

            <v-btn icon color="error" @click="dialog = false"
              ><v-icon
                color="error"
                size="30"
                class="fas fa-times-circle"
              ></v-icon
            ></v-btn>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-text>
            <v-row>
              <v-col
                v-viewer="{ toolbar: false, title: false }"
                sm="12"
                v-for="img in this.userPackage.images"
                :key="img"
                md="4"
              >
                <img
                  style="cursor: pointer; max-height: 250px"
                  width="100%"
                  class="ma-1"
                  :src="backendUrl + '/' + img"
                />
              </v-col>
            </v-row>
          </v-card-text>

          <v-divider v-if="this.userPackage.details.length > 0"></v-divider>

          <v-card-text>
            <v-row
              wrap
              v-for="detail in this.userPackage.details"
              :key="detail"
            >
              <v-col cols="12" sm="12" md="4">
                <v-text-field
                  :value="detail.description"
                  name="description"
                  :label="$t('description')"
                  id="description"
                  type="text"
                  color="indigo"
                  readonly
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="4">
                <v-text-field
                  :value="detail.quantity"
                  name="quantity"
                  :label="$t('quantity')"
                  id="quantity"
                  type="number"
                  min="0"
                  color="indigo"
                  readonly
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="4">
                <v-text-field
                  :value="detail.price"
                  name="price"
                  :label="$t('price')"
                  id="price"
                  type="number"
                  min="0"
                  color="indigo"
                  readonly
                ></v-text-field>
              </v-col>
            </v-row>
          </v-card-text>

          <v-divider v-if="this.userPackage.details.length > 0"></v-divider>

          <v-card-text
            v-if="
              !userPackage.approved &&
              !userPackage.awaitingApprove &&
              !userPackage.awaitingAssemble &&
              !userPackage.awaitingDisassemble
            "
          >
            <v-row style="justify-content: center">
              <v-col sm="12" :lg="this.userPackage.assemble ? '6' : '3'">
                <v-btn
                  block
                  @click="toggleUpdateDesc"
                  :class="
                    this.$i18n.locale == 'ar'
                      ? 'white--text ma-1 mr-auto'
                      : 'white--text ma-1 ml-auto'
                  "
                  style="text-transform: none"
                  color="warning"
                >
                  <v-icon
                    :right="this.$i18n.locale == 'ar' ? true : false"
                    :left="this.$i18n.locale != 'ar' ? true : false"
                    class="ma-2"
                  >
                    fas fa-pen
                  </v-icon>

                  {{ $t("modify") }}
                </v-btn>
              </v-col>
              <v-col
                v-if="!this.userPackage.assemble && setting.imageRequest"
                sm="12"
                xs="12"
                lg="3"
              >
                <v-btn
                  v-if="setting.imageRequest"
                  @click="requestPhoto"
                  :disabled="requestImageLoading"
                  block
                  class="white--text ma-1"
                  style="text-transform: none"
                  color="blue"
                >
                  <v-icon
                    :right="this.$i18n.locale == 'ar' ? true : false"
                    :left="this.$i18n.locale != 'ar' ? true : false"
                    class="ma-2"
                    >fas fa-camera</v-icon
                  >

                  {{ $t("photoRequest") }}
                </v-btn>
              </v-col>

              <v-col v-if="!this.userPackage.assemble" sm="12" lg="3">
                <v-btn
                  @click="toggleAssemble(userPackage)"
                  block
                  class="white--text ma-1"
                  style="text-transform: none"
                  color="indigo"
                >
                  <v-icon
                    :right="this.$i18n.locale == 'ar' ? true : false"
                    :left="this.$i18n.locale != 'ar' ? true : false"
                    class="ma-2"
                  >
                    fas fa-box-open
                  </v-icon>

                  {{ $t("assembleRequest") }}
                </v-btn>
              </v-col>

              <v-col
                v-if="
                  !this.userPackage.assemble &&
                  !this.userPackage.fromDisassemble
                "
                sm="12"
                xs="12"
                lg="3"
              >
                <v-btn
                  v-if="
                    !this.userPackage.assemble &&
                    !this.userPackage.fromDisassemble
                  "
                  @click="toggleDisassemble(userPackage)"
                  block
                  class="white--text ma-1"
                  style="text-transform: none"
                  color="blue darken-4"
                >
                  <v-icon
                    :right="this.$i18n.locale == 'ar' ? true : false"
                    :left="this.$i18n.locale != 'ar' ? true : false"
                    class="ma-3"
                    >fas fa-boxes-stacked</v-icon
                  >

                  {{ $t("disassemble") }}
                </v-btn>
              </v-col>

              <v-col sm="12" :lg="this.userPackage.assemble ? '6' : '3'">
                <v-btn
                  block
                  @click="addressDialog = !addressDialog"
                  :class="
                    this.$i18n.locale == 'ar'
                      ? 'white--text ma-1 mr-auto'
                      : 'white--text ma-1 ml-auto'
                  "
                  style="text-transform: none"
                  color="green"
                >
                  <v-icon
                    :right="this.$i18n.locale == 'ar' ? true : false"
                    :left="this.$i18n.locale != 'ar' ? true : false"
                    class="ma-2"
                  >
                    fas fa-shipping-fast
                  </v-icon>

                  {{ $t("ship") }}
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>

          <v-card-text
            v-if="userPackage.approved"
            class="d-flex justify-center"
          >
            <v-row class="d-flex justify-center align-center">
              <v-col sm="12" lg="4">
                <v-btn
                  @click="shipDialog = !shipDialog"
                  style="text-transform: none"
                  color="success"
                  width="100%"
                >
                  <v-icon
                    :right="this.$i18n.locale == 'ar' ? true : false"
                    :left="this.$i18n.locale != 'ar' ? true : false"
                    class="ma-2 mx-1"
                    size="18"
                    >fas fa-shipping-fast</v-icon
                  >

                  {{ $t("chooseShippingMethod") }}
                </v-btn>
              </v-col>

              <v-col sm="12" lg="4">
                <v-btn
                  @click="toggleUpdateDesc"
                  :class="
                    this.$i18n.locale == 'ar'
                      ? 'white--text ma-1 mr-auto'
                      : 'white--text ma-1 ml-auto'
                  "
                  style="text-transform: none"
                  color="warning"
                  width="100%"
                >
                  <v-icon
                    :right="this.$i18n.locale == 'ar' ? true : false"
                    :left="this.$i18n.locale != 'ar' ? true : false"
                    class="ma-2 mx-1"
                    size="15"
                  >
                    fas fa-pen
                  </v-icon>

                  {{ $t("modify") }}
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>

          <v-card-text
            v-if="
              userPackage.awaitingAssemble &&
              new Date().getTime() -
                new Date(userPackage.assembleTime).getTime() <
                900000
            "
            class=""
          >
            <p class="mt-5" style="text-align: center">
              <b>{{ $t("note") }}: </b>{{ $t("cancelRequestNote") }}
            </p>

            <v-row class="d-flex justify-center align-center">
              <v-col sm="12" lg="4">
                <v-btn
                  @click="cancelAssembleDialog = !cancelAssembleDialog"
                  :class="
                    this.$i18n.locale == 'ar'
                      ? 'white--text ma-1 mr-auto'
                      : 'white--text ma-1 ml-auto'
                  "
                  style="text-transform: none; font-size: 16px"
                  color="warning"
                  width="100%"
                >
                  <v-icon
                    :right="this.$i18n.locale == 'ar' ? true : false"
                    :left="this.$i18n.locale != 'ar' ? true : false"
                    class="ma-2 mx-1"
                    size="15"
                  >
                    fas fa-undo
                  </v-icon>

                  {{ $t("cancelAssemble") }}
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>

          <!-- Cancel Approval Request if it hasn't been 15 minutes -->

          <v-card-text
            v-if="
              userPackage.awaitingApprove &&
              new Date().getTime() -
                new Date(userPackage.approveTime).getTime() <
                900000
            "
            class=""
          >
            <p class="mt-5" style="text-align: center">
              <b>{{ $t("note") }}: </b>{{ $t("cancelRequestNote") }}
            </p>

            <v-row class="d-flex justify-center align-center">
              <v-col sm="12" lg="4">
                <v-btn
                  @click="cancelApprovalDialog = !cancelApprovalDialog"
                  :class="
                    this.$i18n.locale == 'ar'
                      ? 'white--text ma-1 mr-auto'
                      : 'white--text ma-1 ml-auto'
                  "
                  style="text-transform: none; font-size: 16px"
                  color="warning"
                  width="100%"
                >
                  <v-icon
                    :right="this.$i18n.locale == 'ar' ? true : false"
                    :left="this.$i18n.locale != 'ar' ? true : false"
                    class="ma-2 mx-1"
                    size="15"
                  >
                    fas fa-undo
                  </v-icon>

                  {{ $t("cancelApprovalRequest") }}
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-dialog>

      <!-- ship Dialog -->
      <v-dialog max-width="650" v-model="shipDialog">
        <v-card>
          <v-card-title>{{ $t("selectShippingCompany") }}</v-card-title>

          <v-card-text style="overflow: hidden" class="px-2">
            <v-row>
              <v-col
                v-for="method in userPackage.methods"
                :key="method.id"
                cols="12"
                @click.stop="selectedMethod = method"
              >
                <v-card>
                  <v-card-text class="pa-0">
                    <v-row class="align-center pa-0 ma-0">
                      <v-col cols="12" md="7">
                        <div
                          style="
                            display: flex;
                            align-items: center;
                            height: 1em;
                            line-height: 1em;
                            margin-right: 0.5em;
                          "
                        >
                          <div style="width: 5em">
                            <v-img
                              style="cursor: pointer"
                              :src="backendUrl + '/' + method.company.logoImage"
                            />
                          </div>

                          <b
                            :style="
                              $i18n.locale === 'en'
                                ? 'margin-left: 1em'
                                : 'margin-right: 1em'
                            "
                            >{{ method.company.name }}</b
                          >
                        </div>
                      </v-col>
                      <v-col cols="12" md="5">
                        <div
                          style="
                            height: 100%;
                            width: 100%;
                            display: flex;
                            justify-content: space-between;
                            flex-direction: row;
                          "
                        >
                          <b>{{ method.price }} &euro;</b>
                          <v-btn
                            v-if="method._id != selectedMethod._id"
                            small
                            icon
                            color="success"
                          >
                            <v-icon color="success">far fa-circle</v-icon>
                          </v-btn>

                          <v-btn v-else small icon color="success">
                            <v-icon color="success">fas fa-check-circle</v-icon>
                          </v-btn>
                        </div>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
            <!-- end ship Dialog -->

            <!-- Payment View -->
            <h3 class="mt-7">{{ $t("paymentMethodText") }}</h3>
            <v-select
              class="mt-3 text-center"
              :label="$t('paymentMethod')"
              v-if="selectedMethod"
              :items="[
                //{text:'Paypal',value:'Paypal'},
                { text: 'Credit Card', value: 'Credit Card' },
                //{text:'Bitcoin',value:'Bitcoin'},
                'Offline Payment',
              ]"
              v-model="paymentMethod"
              @change="loadStripe()"
            />

            <v-container
              v-if="selectedMethod.price && paymentMethod == 'Credit Card'"
            >
              <!-- <v-card>
                <v-card-text style="direction: ltr" id="card-element"> </v-card-text>
              </v-card> -->

              <div class="d-flex justify-center">
                <v-btn
                  v-if="!payLoading"
                  @click.stop="handleStripePay"
                  class="mt-5"
                  color="light-blue"
                  dark
                  style="display: flex; gap: 1rem"
                >
                  <v-icon
                    size="20"
                    :class="this.$i18n.locale == 'en' ? 'mr-2' : 'ml-2'"
                    >fas fa-credit-card</v-icon
                  >
                  {{ $t("pay") }}
                </v-btn>
              </div>
            </v-container>

            <div
              class="d-flex justify-center"
              v-if="paymentMethod && selectedMethod.price && !payLoading"
            >
              <!-- <v-btn
                @click.stop="handlePay"
                class="mt-5"
                color="primary"
                v-if="paymentMethod == 'Paypal'"
              >
                <v-icon :class="this.$i18n.locale == 'en' ? 'mr-2' : 'ml-2'"
                  >fab fa-paypal</v-icon
                >
                {{ $t("pay") }}
              </v-btn> -->

              <!-- <v-btn
                @click.stop="handleBitcoinPay"
                class="mt-5"
                color="orange"
                dark
                v-if="paymentMethod == 'Bitcoin'"
              >
                <v-icon size="22" :class="this.$i18n.locale == 'en' ? 'mr-2' : 'ml-2'"
                  >fab fa-bitcoin</v-icon
                >
                {{ $t("pay") }}
              </v-btn> -->

              <div
                v-if="
                  selectedMethod.price && paymentMethod == 'Offline Payment'
                "
              >
                <v-btn
                  @click.stop="handleOfflinePay"
                  class="mt-5"
                  color="indigo"
                  dark
                >
                  <v-icon
                    size="17"
                    :class="this.$i18n.locale == 'en' ? 'mr-2' : 'ml-2'"
                    >fas fa-money-bill</v-icon
                  >
                  {{ $t("pay") }}
                </v-btn>
              </div>
            </div>

            <div v-if="payLoading" class="d-flex justify-center">
              <v-progress-circular
                :indeterminate="true"
                size="40"
                color="indigo"
              ></v-progress-circular>
            </div>
          </v-card-text>

          <!-- <v-card-text v-if="paymentMethod == 'Bitcoin'">
            <div>{{ $t("bitcoinNote") }}</div>
          </v-card-text> -->
          <!-- <v-card-text v-if="paymentMethod == 'Offline Payment'">
            <div>{{ $t("offlinePaymentNote") }}</div>
          </v-card-text> -->
          <v-card-text v-if="paymentMethod &&paymentMethod == 'Offline Payment'">
            <div>{{ $t("secondaryPaymentNote") }}</div>
          </v-card-text>
        </v-card>
      </v-dialog>
      <!-- End Payment View -->

      <!-- Add New Address View -->
      <v-dialog v-model="addAddressDialog" width="650">
        <v-card class="pa-1">
          <v-card-text class="pa-3 blue--text" style="font-size: 18px">
            {{ $t("addAddressText") }}
          </v-card-text>

          <v-divider></v-divider>

          <v-card-text>
            <v-form @submit.prevent="handleSubmit" ref="form">
              <v-text-field
                v-model="title"
                name="title"
                :label="$t('title')"
                :placeholder="$t('titlePlaceholder')"
                id="title"
                type="text"
                color="indigo"
                required
              ></v-text-field>

              <v-text-field
                v-model="firstName"
                name="name"
                :label="$t('firstName')"
                id="name"
                type="text"
                color="indigo"
                :rules="[(v) => !!v || $i18n.t('codeRequired')]"
                required
                @keyup="checkArabic"
              ></v-text-field>

              <v-text-field
                v-model="lastName"
                name="name"
                :label="$t('lastName')"
                id="name"
                type="text"
                color="indigo"
                :rules="[(v) => !!v || $i18n.t('codeRequired')]"
                required
                @keyup="checkArabic"
              ></v-text-field>

              <v-select
                :label="$t('country')"
                v-model="country"
                :items="countryList"
                :rules="[(v) => !!v || $i18n.t('codeRequired')]"
              ></v-select>

              <v-text-field
                v-model="nationalID"
                v-if="country == 'Algeria'"
                name="nationalID"
                :label="$t('nationalID')"
                id="nationalID"
                type="text"
                color="indigo"
                :rules="[(text) => text.length > 8 || $t('nationalIDValid')]"
                required
                @keyup="checkArabic"
              ></v-text-field>

              <v-text-field
                v-model="addressFirst"
                name="addressFirst"
                :label="$t('addressFirst')"
                id="address1"
                type="text"
                color="indigo"
                :rules="[(v) => !!v || $i18n.t('codeRequired')]"
                required
                @keyup="checkArabic"
              ></v-text-field>

              <v-text-field
                v-model="addressSecond"
                name="addressFirst"
                :label="$t('addressSecond')"
                id="address2"
                type="text"
                color="indigo"
                :rules="[(v) => !!v || $i18n.t('codeRequired')]"
                required
                @keyup="checkArabic"
              ></v-text-field>

              <v-text-field
                v-model="city"
                name="city"
                :label="$t('city')"
                id="city"
                type="text"
                color="indigo"
                :rules="[(v) => !!v || $i18n.t('codeRequired')]"
                required
                @keyup="checkArabic"
              ></v-text-field>

              <v-text-field
                v-model="postal"
                name="postal"
                :label="$t('postal')"
                id="postal"
                type="text"
                color="indigo"
                :rules="[(v) => !!v || $i18n.t('codeRequired')]"
                required
                @keyup="checkArabic"
              ></v-text-field>

              <v-text-field
                v-model="phone"
                name="phone"
                :label="$t('phone')"
                id="phone"
                type="text"
                color="indigo"
                :rules="[(v) => !!v || $i18n.t('codeRequired')]"
                @keyup="checkNumber"
                required
              ></v-text-field>

              <v-spacer></v-spacer>

              <v-spacer></v-spacer>

              <div v-if="addAddressLoading" class="d-flex justify-center">
                <v-progress-circular
                  :indeterminate="true"
                  size="40"
                  color="indigo"
                ></v-progress-circular>
              </div>

              <div v-else class="d-flex justify-center">
                <v-btn class="ma-2 mx-auto" type="submit" dark color="indigo">
                  {{ $t("save") }}
                </v-btn>
              </div>
            </v-form>
          </v-card-text>

          <v-divider></v-divider>
        </v-card>
      </v-dialog>

      <!-- End Add New Address -->

      <!-- Package Status Text -->
      <v-card-text class="warning--text" v-if="userPackage.awaitingApprove">
        {{ $t("awaitingApprove") }}
      </v-card-text>

      <v-card-text class="success--text" v-if="userPackage.approved">
        {{ $t("awaitingPayment") }}
      </v-card-text>

      <v-card-text class="warning--text" v-if="userPackage.awaitingAssemble">
        {{ $t("awaitingAssemble") }}
      </v-card-text>

      <v-card-text class="warning--text" v-if="userPackage.awaitingDisassemble">
        {{ $t("awaitingDisassemble") }}
      </v-card-text>
      <!-- End Package Status Text -->

      <!-- confirm cancel assemble dialog -->
      <v-dialog v-model="cancelAssembleDialog" persistent max-width="650">
        <v-card>
          <v-card-title>
            {{ $t("cancelAssemble") }}
          </v-card-title>

          <v-card-text style="font-size: 17px">
            {{ $t("cancelAssembleText") }}{{ $t("?") }}
          </v-card-text>

          <v-card-action
            v-if="!this.loading"
            class="d-flex justify-center pa-3"
          >
            <v-btn @click="handleCancelAssemble" color="success mx-2">
              {{ $t("confirm") }}
            </v-btn>

            <v-btn
              @click="cancelAssembleDialog = false"
              color="grey darken-1 white--text mx-2"
            >
              {{ $t("close") }}
            </v-btn>
          </v-card-action>

          <v-card-action v-else class="d-flex justify-center pa-3">
            <v-progress-circular
              :indeterminate="true"
              size="40"
              color="indigo"
            ></v-progress-circular>
          </v-card-action>
        </v-card>
      </v-dialog>

      <!-- confirm cancel Approval dialog -->
      <v-dialog v-model="cancelApprovalDialog" persistent max-width="650">
        <v-card>
          <v-card-title>
            {{ $t("cancelApprovalRequest") }}
          </v-card-title>

          <v-card-text style="font-size: 17px">
            {{ $t("cancelApprovalText") }}
          </v-card-text>

          <v-card-action
            v-if="!this.loading"
            class="d-flex justify-center pa-3"
          >
            <v-btn @click="handleCancelApproval" color="success mx-2">
              {{ $t("confirm") }}
            </v-btn>

            <v-btn
              @click="cancelApprovalDialog = false"
              color="grey darken-1 white--text mx-2"
            >
              {{ $t("close") }}
            </v-btn>
          </v-card-action>

          <v-card-action v-else class="d-flex justify-center pa-3">
            <v-progress-circular
              :indeterminate="true"
              size="40"
              color="indigo"
            ></v-progress-circular>
          </v-card-action>
        </v-card>
      </v-dialog>
    </v-card>

    <v-snackbar left :color="color" v-model="snackbar">{{
      message
    }}</v-snackbar>
  </v-col>
</template>

<script>
import { backendUrl, countryList } from "../config/config";
import axios from "axios";
import auth from "../helpers/auth";

const newAxios = axios.create();
newAxios.defaults.headers.common = {};

const isArabic = /[\u0621-\u064A]+/g;

export default {
  name: "Package",

  data() {
    return {
      user: auth.user(),
      additionalNote: "",
      assembleNote: "",
      removeShoeBoxes: false,
      removeBills: false,
      dialog: false,
      addAddressDialog: false,
      backendUrl,
      snackbar: false,
      color: "",
      message: "",
      requestImageLoading: false,
      assembleDialog: false,
      assemblePackages: [],
      loading: false,
      addressDialog: false,
      modifyDialog: false,
      userAddresses: [],
      approveLoading: false,
      selectedAddress: "",
      cancelAssembleDialog: false,
      cancelApprovalDialog: false,

      //disassemble fields
      disassembleDialog: false,
      disassemblePackage: {},
      disassembleLoading: false,
      disassembleNote: "",

      //add address fields
      firstName: "",
      lastName: "",
      title: "",
      addressFirst: "",
      addressSecond: "",
      phone: "",
      postal: "",
      country: "",
      city: "",
      countryList: countryList,
      addAddressLoading: false,
      selectedCountry: "",
      prices: [],
      selectedPrice: "",
      countryMenu: false,
      shipDialog: false,
      selectedMethod: "",
      paymentMethod: "",
      payLoading: false,
      setting: {},
      updatedPackage: {},
      step: 1,
      selectDangerOption: "",
      selectLithiumOption: "",
      stripe: "",
      elements: "",
      card: "",
      nationalID: "",
      selectedAddressNationalID: "",

      //stripe fields
      stripeApiToken:
      "pk_live_51MmHryHWFMN2HvFFGgxNTgAO9wo9g4GGNErwaaLWllgHFtKv5wsI6ZlBF2AuancBwJUHE3FRhLbD2fnJ4LNfLG8M00DNXufqfZ",
      //eslint-disable-next-line
      stripeConfirmParams: {
        return_url: `http://localhost:8080/${this.$i18n.locale}/dashboard?payment=success`, // success url
      },
      stripeElementOptions: {
        appearance: {},
      },
      paymentReady:false,
      //regex
      phoneRe: /^\d+$/i,
    };
  },

  computed: {
    currentTitle() {
      switch (this.step) {
        case 1:
          return "chooseAddress";
        case 2:
          return "packageContent";
        default:
          return "shippingOptions";
      }
    },
  },

  watch: {
    assembleDialog: function (newValue) {
      if (newValue === false) {
        this.assemblePackages.forEach((pkg) => {
          pkg.checkbox = false;
          pkg.disabled = false;
        });
      }
    },

    packages: function (newPackages) {
      this.assemblePackages = [];
      newPackages.forEach((item) => {
        if (
          !item.assemble &&
          !item.awaitingAssemble &&
          !item.awaitingApprove &&
          !item.approved
        ) {
          this.assemblePackages.push({
            id: item._id,
            checkbox: false,
            disabled: false,
            image: item.images[0],
          });
        }
      });
    },
  },

  mounted() {
    if (this.$i18n.locale === "ar") {
      this.$vuetify.rtl = true;
    }

    this.packages.forEach((item) => {
      if (
        !item.assemble &&
        !item.awaitingAssemble &&
        !item.awaitingApprove &&
        !item.approved
      ) {
        this.assemblePackages.push({
          id: item._id,
          checkbox: false,
          disabled: false,
          image: item.images[0],
        });
      }
    });

    newAxios
      .get("/api/users/addresses/get", {
        headers: {
          Authorization: `bearer ${auth.token()}`,
        },
      })
      .then((res) => {
        this.userAddresses = res.data.addresses;
        this.title = this.titleDefaults(this.userAddresses.length);
      })
      .catch((err) => {
        console.log(err);
      });

    newAxios
      .get("/api/users/setting", {
        headers: {
          Authorization: `bearer ${auth.token()}`,
        },
      })
      .then((res) => {
        this.setting = res.data.setting;
      })
      .catch((err) => {
        console.log(err);
      });
  },
  props: {
    userPackage: {
      type: Object,
    },
    packages: {
      type: Array,
    },
    updateData: {
      type: Function,
    },
    flagImage: {
      type: String
    }
  },

  sockets: {
    updateSetting(setting) {
      this.setting = setting;
    },
  },
  methods: {
    selectAddress(address) {
      this.selectedCountry = address.country;
      this.selectedAddress = address._id;
      this.selectedAddressNationalID = address.nationalID;
    },
    checkNumber(e) {
      if (!this.phoneRe.test(e.target.value)) {
        this[e.target.name] = "";
      }
    },
    formatDate(date) {
      var d = new Date(date),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear();

      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;

      return [year, month, day].join("-");
    },

    toggleUpdateDesc() {
      const { details } = this.userPackage;

      // In order to fix ref bug
      this.updatedPackage = JSON.parse(JSON.stringify(details));

      this.modifyDialog = !this.modifyDialog;
    },

    updateDescription() {
      this.loading = true;

      const details = this.updatedPackage;
      // Convert Quantity & price to numbers
      details.forEach((detail) => {
        detail.price = parseFloat(detail.price);
      });

      newAxios
        .post(
          `/api/packages/details/update/${this.userPackage._id}`,
          {
            details: details,
          },
          {
            headers: {
              Authorization: `bearer ${auth.token()}`,
            },
          }
        )
        .then((res) => {
          if (!res.error) {
            this.loading = false;
            this.snackbar = true;
            if (this.$i18n.locale == "ar") {
              this.message = "تم تحديث تفاصيل الحزمة بنجاح";
            } else if (this.$i18n.locale == "en") {
              this.message = "Package details has been updated successfully";
            }
            this.color = "success";
            this.modifyDialog = false;
            this.dialog = false;
            this.$emit("updateData");

            this.userPackage = res.data.package;
          }
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
          this.snackbar = true;

          if (this.$i18n.locale == "ar") {
            this.message = "لقد حصل خطأ ما الرجاء المحاولة لاحقاً";
          } else if (this.$i18n.locale == "en") {
            this.message = "An error happened please try again later";
          }
          this.color = "error";
        });
    },

    requestPhoto() {
      this.requestImageLoading = true;
      newAxios
        .post(
          "/api/packages/images/request",
          { id: this.userPackage._id },
          {
            headers: {
              Authorization: `bearer ${auth.token()}`,
            },
          }
        )
        .then((res) => {
          if (!res.data.error) {
            this.requestImageLoading = false;
            if (this.$i18n.locale == "ar") {
              this.message = "تم إرسال طلب الصورة بنجاح.";
            } else if (this.$i18n.locale == "en") {
              this.message = "Photo request has been sent successully";
            }
            this.message = "Request has been sent successully";
            this.color = "success";
            this.snackbar = true;
            this.dialog = false;
          } else {
            if (res.data.message == "sent") {
              this.requestImageLoading = false;

              if (this.$i18n.locale == "ar") {
                this.message = "تم أرسال طلبك مسبقاً";
              } else if (this.$i18n.locale == "en") {
                this.message = "Request is already sent";
              }

              this.color = "warning";
              this.snackbar = true;
            } else {
              this.requestImageLoading = false;
              this.message = "An error happened please try again";
              this.color = "error";
              this.snackbar = true;
            }
          }
        })
        .catch((err) => {
          this.requestImageLoading = false;
          this.message = "An error happened please try again";
          this.color = "error";
          this.snackbar = true;
        });
    },
    toggleAssemble(selectedPkg) {
      this.assembleNote = "";
      this.assemblePackages.forEach((pkg) => {
        if (pkg.id === selectedPkg._id) {
          pkg.checkbox = true;
          pkg.disabled = true;
        }
      });
      this.assembleDialog = !this.assembleDialog;
    },
    toggleDisassemble(selectedPkg) {
      this.disassembleNote = "";
      this.disassemblePackage = selectedPkg;
      this.disassembleDialog = !this.disassembleDialog;
    },
    handleDisassemble() {
      this.loading = true;

      if (!this.disassembleNote) {
        this.loading = false;
        this.snackbar = true;
        this.color = "error";
        this.message = this.$i18n.t("disassembleNoteRequired");
        return;
      }

      newAxios
        .post(
          `/api/packages/disassemble-request/${this.disassemblePackage._id}`,
          {
            disassembleNote: this.disassembleNote,
            removeBills: this.removeBills,
            removeShoeBoxes: this.removeShoeBoxes,
          },
          {
            headers: {
              Authorization: `bearer ${auth.token()}`,
            },
          }
        )
        .then((res) => {
          if (!res.error) {
            this.loading = false;
            this.snackbar = true;
            if (this.$i18n.locale == "ar") {
              this.message = "تم تفكيك الحزمة بنجاح";
            } else if (this.$i18n.locale == "en") {
              this.message = "Package has been disassembled successfully";
            }
            this.color = "success";
            this.disassembleDialog = false;
            this.dialog = false;
            this.$emit("updateData");
          }
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
          this.snackbar = true;

          if (this.$i18n.locale == "ar") {
            this.message = "لقد حصل خطأ ما الرجاء المحاولة لاحقاً";
          } else if (this.$i18n.locale == "en") {
            this.message = "An error happened please try again later";
          }
          this.color = "error";
        });
    },
    handleAssemble() {
      this.loading = true;

      let assemblePacks = [];

      this.assemblePackages.forEach((item) => {
        if (item.checkbox) {
          assemblePacks.push(item.id);
        }
      });

      if (assemblePacks.length <= 1) {
        this.snackbar = true;
        this.color = "error";
        this.message = this.$i18n.t("assembleMinCount");
        this.loading = false;
        return;
      }
      // Here too
      newAxios
        .post(
          "/api/packages/group",
          {
            packages: assemblePacks,
            removeBills: this.removeBills,
            removeShoeBoxes: this.removeShoeBoxes,
            assembleNote: this.assembleNote,
          },
          {
            headers: {
              Authorization: `bearer ${auth.token()}`,
            },
          }
        )
        .then((res) => {
          if (!res.error) {
            this.loading = false;
            this.snackbar = true;
            if (this.$i18n.locale == "ar") {
              this.message = "تم إرسال طلب التجميع بنجاح.";
            } else if (this.$i18n.locale == "en") {
              this.message = "The assembly request has been sent successfully.";
            }
            this.color = "success";
            this.assembleDialog = false;
            this.assembleNote = "";
            this.dialog = false;
            this.$emit("updateData");

            // Reseting the fields
            this.removeBills = false;
            this.removeShoeBoxes = false;

            this.assemblePackages = this.assemblePackages.filter(
              (item) => !assemblePacks.includes(item.id)
            );
          }
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
          this.snackbar = true;
          this.snackbar = true;

          if (this.$i18n.locale == "ar") {
            this.message = "لقد حصل خطأ ما الرجاء المحاولة لاحقاً";
          } else if (this.$i18n.locale == "en") {
            this.message = "An error happened please try again later";
          }
          this.color = "error";
        });
    },

    loadStripe() {
      // setTimeout(() => {
      //   this.includeStripe(
      //     "js.stripe.com/v3/",
      //     function () {
      //       this.configureStripe();
      //     }.bind(this)
      //   );
      // }, 200);
    },
    handlePay() {
      this.payLoading = true;

      newAxios
        .post(
          "/api/users/create_payment",
          {
            packageId: this.userPackage._id,
            methodId: this.selectedMethod._id,
          },
          {
            headers: {
              Authorization: `bearer ${auth.token()}`,
            },
          }
        )
        .then((res) => {
          if (!res.data.error) {
            window.location.href = res.data.approvalUrl;
            this.dialog = false;
            this.shipDialog = false;
            this.$emit("updateData");
          } else {
            this.payLoading = false;
            this.snackbar = true;
            this.message = "An error happened please try again";
            this.color = "error";
          }
        })
        .catch((err) => {
          console.log(err);
          this.payLoading = false;
          this.snackbar = true;
          this.message = "An error happened please try again";
          this.color = "error";
        });
    },

    handleBitcoinPay() {
      this.payLoading = true;

      newAxios
        .post(
          "/api/users/bitcoin/create_payment",
          {
            packageId: this.userPackage._id,
            methodId: this.selectedMethod._id,
          },
          {
            headers: {
              Authorization: `bearer ${auth.token()}`,
            },
          }
        )
        .then((res) => {
          if (!res.data.error) {
            window.open(res.data.link);
            this.payLoading = false;
            this.dialog = false;
            this.shipDialog = false;
            this.$emit("updateData");
          } else {
            this.payLoading = false;
            this.snackbar = true;
            this.message = "An error happened please try again";
            this.color = "error";
          }
        })
        .catch((err) => {
          console.log(err);
          this.payLoading = false;
          this.snackbar = true;
          this.message = "An error happened please try again";
          this.color = "error";
        });
    },
    handleOfflinePay() {
      this.payLoading = true;

      newAxios
        .post(
          "/api/users/offline/create_payment",
          {
            packageId: this.userPackage._id,
            methodId: this.selectedMethod._id,
            paymentType: this.paymentMethod,
          },
          {
            headers: {
              Authorization: `bearer ${auth.token()}`,
            },
          }
        )
        .then((res) => {
          if (!res.data.error) {
            this.snackbar = true;
            this.color = "success";
            this.message = this.$i18n.t("paymentRequestSuccessful");
            this.payLoading = false;
            this.dialog = false;
            this.shipDialog = false;
            this.$emit("updateData");
          } else {
            if (res.data.message == "TRAN_EXIST") {
              this.snackbar = true;
              this.color = "warning";
              this.message = this.$i18n.t("requestAlreadySent");
              this.payLoading = false;
              return;
            }
            this.payLoading = false;
            this.snackbar = true;
            this.message = "An error happened please try again";
            this.color = "error";
          }
        })
        .catch((err) => {
          console.log(err);
          this.payLoading = false;
          this.snackbar = true;
          this.message = "An error happened please try again";
          this.color = "error";
        });
    },

    // configureStripe() {
    //   this.stripe = Stripe(this.stripeApiToken);

    //   this.elements = this.stripe.elements();
    //   this.card = this.elements.create("card");

    //   this.card.mount("#card-element");
    // },

    // includeStripe(URL, callback) {
    //   let documentTag = document,
    //     tag = "script",
    //     object = documentTag.createElement(tag),
    //     scriptTag = documentTag.getElementsByTagName(tag)[0];
    //   object.src = "//" + URL;
    //   if (callback) {
    //     object.addEventListener(
    //       "load",
    //       function (e) {
    //         callback(null, e);
    //       },
    //       false
    //     );
    //   }
    //   scriptTag.parentNode.insertBefore(object, scriptTag);
    // },

    handleStripePay() {
      this.payLoading = true;
      
      newAxios
        .post(
          "/api/users/stripe/create_payment",
          {
            packageId: this.userPackage._id,
            methodId: this.selectedMethod._id,
          locale: this.$i18n.locale,
          },
          {
            headers: {
              Authorization: `bearer ${auth.token()}`,
            },
          }
        )
        .then((res) => {
          if (!res.data.error) {
            window.location.href = res.data.checkoutUrl;


            // this.stripe
            //   .confirmCardPayment(res.data.client_secret, {
            //     payment_method: {
            //       card: this.card,
            //     },
            //   })
            //   .then((result) => {
            //     if (result.error) {
            //       this.payLoading = false;
            //       this.snackbar = true;
            //       this.message = "An error happened please try again";
            //       this.color = "error";
            //       console.log(result.error.message);
            //     } else {
            //       // The payment has been processed!
            //       if (result.paymentIntent.status === "succeeded") {
            //         newAxios
            //           .post(
            //             "/api/users/stripe/confirm_payment ",
            //             {
            //               transactionToken: result.paymentIntent.id,
            //             },
            //             {
            //               headers: {
            //                 Authorization: `bearer ${auth.token()}`,
            //               },
            //             }
            //           )
            //           .then((res) => {
            //             if (!res.data.error) {
            //               this.payLoading = false;
            //               this.snackbar = true;
            //               this.message = this.$i18n.t("paymentSuccessful");
            //               this.color = "success";

            //               this.shipDialog = false;
            //               this.dialog = false;

            //               setTimeout(() => this.$emit("updateData"), 500);
            //             } else {
            //               this.payLoading = false;
            //               this.snackbar = true;
            //               this.message = "An error happened please try again";
            //               this.color = "error";
            //             }
            //           })
            //           .catch((err) => {
            //             console.log(err);
            //             this.payLoading = false;
            //             this.snackbar = true;
            //             this.message = "An error happened please try again";
            //             this.color = "error";
            //             console.log(result.error.message);
            //           });
            //       }
            //     }
            //   });
          } else {
            this.payLoading = false;
            this.snackbar = true;
            this.message = "An error happened please try again";
            this.color = "error";
          }
        })
        .catch((err) => {
          console.log(err);
          this.payLoading = false;
          this.snackbar = true;
          this.message = "An error happened please try again";
          this.color = "error";
        });
    },

    handleSubmit() {
      if (!this.$refs.form.validate()) {
        return;
      }
      if (this.country == "Algeria" && this.nationalID == "") {
        this.snackbar = true;
        this.message = this.$i18n.t("nationalIDRequired");
        this.color = "error";
        return;
      } else if (this.country != "Algeria") this.nationalID = "";
      this.addAddressLoading = true;

      this.$recaptcha("login").then((token) => {
        newAxios
          .post(
            "/api/users/addresses/add",
            {
              name: this.firstName + " " + this.lastName,
              title: this.title,
              country: this.country,
              city: this.city,
              zip: this.postal,
              addressLineOne: this.addressFirst,
              addressLineTwo: this.addressSecond,
              nationalID: this.nationalID,
              phone: this.phone,
              captcha: token,
            },
            {
              headers: {
                authorization: `bearer ${auth.token()}`,
              },
            }
          )
          .then((res) => {
            if (!res.data.error) {
              this.loading = false;
              this.message = "The address has been added successfully";
              this.color = "success";
              this.snackbar = true;

              this.userAddresses.push(res.data.address);

              this.addAddressDialog = false;

              this.$refs.form.reset();
              this.title = this.titleDefaults(this.userAddresses.length);
              this.addAddressLoading = false;
            } else {
              this.loading = false;
              this.message =
                "An error happened please check your fields and try again";
              this.color = "error";
              this.snackbar = true;
            }
          })
          .catch((err) => {
            console.log(err);

            this.loading = false;
            this.message =
              "An error happened please check your fields and try again";
            this.color = "error";
            this.snackbar = true;
          });
      });
    },
    titleDefaults(cardinal) {
      const ordinals = [
        "First",
        "Second",
        "Third",
        "Fourth",
        "Fifth",
        "Sixth",
        "Seventh",
        "Eighth",
        "Ninth",
        "Tenth",
        "Eleventh",
        "Twelfth",
        "Thirteenth",
        "Fourteenth",
        "Fifteenth",
      ];
      if (cardinal >= ordinals.length) return "Address no. " + (cardinal + 1);
      return "My " + ordinals[cardinal] + " Address";
    },
    checkArabic(e) {
      if (isArabic.test(e.target.value)) {
        e.target.value = "";
      }
    },

    calc() {
      this.payLoading = true;
      newAxios
        .post(
          "/api/users/calc_shipping",
          {
            weight: this.userPackage.weight,
            height: this.userPackage.height,
            width: this.userPackage.width,
            length: this.userPackage.length,
            country: this.selectedCountry,
          },
          {
            headers: {
              Authorization: `bearer ${auth.token()}`,
            },
          }
        )
        .then((res) => {
          this.payLoading = false;
          if (!res.data.length == 0) {
            this.snackbar = true;
            this.message = this.$i18n.t("errorMessage");
            this.color = "error";
          } else {
            this.prices = res.data.prices;
          }

          if (res.data.error) {
            this.snackbar = true;
            this.color = "error";
            this.message = this.$i18n.t("errorMessage");
          }
        })
        .catch((err) => {
          console.log(err);

          this.snackbar = true;
          this.color = "error";
          this.message = this.$i18n.t("errorMessage");
          this.payLoading = false;
        });
    },
    filterCountries() {
      let arr = [];

      if (!this.country) {
        this.countryMenu = false;
        return;
      }

      this.countryMenu = true;

      countryList.forEach((item) => {
        if (item.toLowerCase().indexOf(this.country.toLowerCase()) >= 0) {
          arr.push(item);
        }
      });

      this.countryList = arr;
    },
    setCountry(item) {
      this.country = item;
      this.countryMenu = false;
    },
    requestPackageApprove() {
      if (
        this.selectedCountry == "Algeria" &&
        this.selectedAddressNationalID == ""
      ) {
        this.snackbar = true;
        this.message = this.$i18n.t("addNationalID");
        this.color = "warning";
        return;
      }
      this.approveLoading = true;

      newAxios
        .post(
          "/api/packages/request/package-approve",
          {
            packageId: this.userPackage._id,
            address: this.selectedAddress,
            // lithiumQst:
            //   this.selectLithiumOption === "Yes"
            //     ? true
            //     : this.selectLithiumOption === "No"
            //     ? false
            //     : false,
            // dangerQst:
            //   this.selectDangerOption === "Yes"
            //     ? true
            //     : this.selectDangerOption === "No"
            //     ? false
            //     : false,
            removeShoeBoxes: this.removeShoeBoxes,
            removeBills: this.removeBills,
            additionalNote: this.additionalNote,
          },
          {
            headers: {
              Authorization: `bearer ${auth.token()}`,
            },
          }
        )
        .then((res) => {
          this.approveLoading = false;

          if (res.data.error) {
            this.snackbar = true;
            this.color = "error";
            this.message = this.$i18n.t("errorMessage");
          } else {
            this.snackbar = true;
            this.color = "success";
            this.message = this.$i18n.t("approvalRequestSuccess");

            this.dialog = false;
            this.addressDialog = false;

            this.$emit("updateData");
          }
        })
        .catch((err) => {
          this.approveLoading = false;

          this.snackbar = true;
          this.color = "error";
          this.message = this.$i18n.t("errorMessage");

          console.log(err);
        });
    },

    handleCancelAssemble() {
      this.loading = true;

      newAxios
        .post(
          "/api/packages/group/user-cancel",
          {
            packageId: this.userPackage._id,
          },
          {
            headers: {
              Authorization: `bearer ${auth.token()}`,
            },
          }
        )
        .then((res) => {
          this.loading = false;

          if (res.data.error) {
            this.snackbar = true;
            this.color = "error";
            this.message = this.$i18n.t("errorMessage");
          } else {
            this.snackbar = true;
            this.color = "success";
            this.message = this.$i18n.t("cancelAssembleRequestSuccess");

            this.dialog = false;
            this.addressDialog = false;

            this.$emit("updateData");
          }

          this.cancelAssembleDialog = false;
        })
        .catch((err) => {
          this.loading = false;

          this.snackbar = true;
          this.color = "error";
          this.message = this.$i18n.t("errorMessage");

          console.log(err);
        });
    },
    handleCancelApproval() {
      this.loading = true;

      newAxios
        .post(
          "/api/packages/request/approval/cancel",
          {
            packageId: this.userPackage._id,
          },
          {
            headers: {
              Authorization: `bearer ${auth.token()}`,
            },
          }
        )
        .then((res) => {
          this.loading = false;

          if (res.data.error) {
            this.snackbar = true;
            this.color = "error";
            this.message = this.$i18n.t("errorMessage");
          } else {
            this.snackbar = true;
            this.color = "success";
            this.message = this.$i18n.t("cancelApprovalRequestSuccess");

            this.dialog = false;
            this.addressDialog = false;

            this.$emit("updateData");
          }

          this.cancelApprovalDialog = false;
        })
        .catch((err) => {
          this.loading = false;

          this.snackbar = true;
          this.color = "error";
          this.message = this.$i18n.t("errorMessage");

          console.log(err);
        });
    },
  },
};
</script>

<style scoped>
.flag-icon{
  z-index: 1;
  position: absolute;
  top: 2%;
  right: 2%;
  width: 30px;
  height: 30px;
  cursor: pointer;
}
.flag-emoji{
  font-family: 'NotoColorEmoji';
  font-size: 1.5rem;
  z-index: 10;
  position: absolute;
  top: 2%;
  right: 5%;
}
</style>
