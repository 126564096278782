<template>
  <v-container>
    <v-card class="ma-3">
      <v-card-title>User Info:</v-card-title>

      <v-card-text>
        <div class="ma-1">
          <span style="font-weight: bold">Full Name:</span> {{ user.fullName }}
        </div>
        <div class="ma-1">
          <span style="font-weight: bold">Email:</span> {{ user.email }}
        </div>
        <div class="ma-1">
          <span style="font-weight: bold">Phone:</span> {{ user.phone }}
        </div>
        <div class="ma-1">
          <span style="font-weight: bold">Code:</span> {{ user.addressHash }}
        </div>
        <div class="ma-1">
          <span style="font-weight: bold">Confirmed:</span> {{ user.isConfirmed ? "Yes" : "No" }}
        </div>
        <div class="ma-1">
          <span style="font-weight: bold">Registration Date:</span> {{ formatDate(user.createdAt) }}
        </div>
        <div class="ma-1">
          <span style="font-weight: bold">Last Activity:</span> {{ user.lastLogin ? formatDate(user.lastLogin) : "N/A" }}
        </div>
        <div v-if="user.suspicious">
          <span style="font-weight: bold">Suspicion Reason:</span> {{ user.suspicionReason }}
        </div>

        <div class="ma-1">
          <p class="d-flex align-center" style="font-weight: bold">
            Mark As Suspicious:<v-checkbox
              class="mx-3"
              v-if="!this.updatingSus"
              v-model="suspicious"
              :disabled="this.updatingSus"
              @change="handleUpdateSuspicious"
              color="red"
            ></v-checkbox>
            <v-progress-circular
              class="mx-3"
              v-else
              :indeterminate="true"
              size="25"
              color="indigo"
            ></v-progress-circular>
          </p>
        </div>

        
      </v-card-text>

      <v-card-actions class="d-flex justify-end mx-4 mb-5">
        <v-btn
          v-if="admin.groupId === adminId"
          @click.stop="setItem"
          color="success"
          >Edit</v-btn
        >
      </v-card-actions>
    </v-card>

    <v-dialog max-width="650" v-model="editDialog">
      <v-card>
        <v-card-title>Edit User</v-card-title>

        <v-card-text>
          <v-text-field
            label="first Name"
            v-model="selectedItem.firstName"
            required
          ></v-text-field>
          <v-text-field
            label="Last Name"
            v-model="selectedItem.lastName"
            required
          ></v-text-field>
          <v-text-field
            label="Email"
            v-model="selectedItem.email"
            required
          ></v-text-field>
          <v-text-field
            label="Phone"
            v-model="selectedItem.phone"
          ></v-text-field>
        </v-card-text>

        <v-card-action class="d-flex justify-center pa-5">
          <v-progress-circular
            v-if="loading"
            :indeterminate="true"
            size="40"
            color="indigo"
          ></v-progress-circular>

          <v-btn v-else @click.stop="editUser" dark color="indigo"
            >Update</v-btn
          >
        </v-card-action>
      </v-card>
    </v-dialog>

    <h3 class="ma-5">User Packages</h3>

    <v-data-table
      :headers="packagesTableHeaders"
      :items="packages"
      :items-per-page="10"
      class="elevation-2 grey lighten-5 ma-3"
    >
      <template v-slot:item.trackNumber="{ item }">
        <div>
          <router-link
            style="text-decoration: none; font-size: 16px; color: #2e86c1"
            :to="'/en/admin/manage-packages?packageId=' + item.packageId"
          >
          <div v-if="item.assemble">{{ item.packageId }}</div>
          <div v-else>{{ item.trackNumber ? item.trackNumber : item.packageId }}</div>
            
          </router-link>
        </div>
      </template>

      <template v-slot:item.weight="{ item }">
        <div>{{ item.weight }} kg</div>
      </template>

      <template v-slot:item.length="{ item }">
        <div>{{ item.length }}</div>
      </template>

      <template v-slot:item.width="{ item }">
        <div>{{ item.width }}</div>
      </template>

      <template v-slot:item.height="{ item }">
        <div>{{ item.height }}</div>
      </template>

      <template v-slot:item.date="{ item }">
        <div>{{ formatDate(item.createdAt) }}</div>
      </template>

      <template v-slot:item.awaitingAssemble="{ item }">
        <div>{{ item.awaitingAssemble ? "yes" : "no" }}</div>
      </template>

      <template v-slot:item.confirmPayment="{ item }">
        <div>{{ item.confirmPayment ? "yes" : "no" }}</div>
      </template>

      <template v-slot:item.awaitingApproval="{ item }">
        <div>{{ item.awaitingApprove ? "yes" : "no" }}</div>
      </template>

      <template v-slot:item.approved="{ item }">
        <div>{{ item.approved ? "yes" : "no" }}</div>
      </template>

      <template v-slot:item.shipped="{ item }">
        <div>{{ item.status ? "yes" : "no" }}</div>
      </template>

      <template v-slot:item.shipDate="{ item }">
        <div>{{ item.shipDate ? formatDate(item.shipDate) : "" }}</div>
      </template>
    </v-data-table>

    <h3 class="ma-5">User Packages (Storage)</h3>

    <v-data-table
      :headers="packagesTableHeaders"
      :items="remainingPackages"
      :items-per-page="10"
      class="elevation-2 grey lighten-5 ma-3"
    >
      <template v-slot:item.trackNumber="{ item }">
        <div>
          <router-link
            style="text-decoration: none; font-size: 16px; color: #2e86c1"
            :to="'/en/admin/manage-packages?packageId=' + item.packageId"
          >
          <div v-if="item.assemble">{{ item.packageId }}</div>
          <div v-else>{{ item.trackNumber ? item.trackNumber : item.packageId }}</div>
            
          </router-link>
        </div>
      </template>

      <template v-slot:item.weight="{ item }">
        <div>{{ item.weight }} kg</div>
      </template>

      <template v-slot:item.length="{ item }">
        <div>{{ item.length }}</div>
      </template>

      <template v-slot:item.width="{ item }">
        <div>{{ item.width }}</div>
      </template>

      <template v-slot:item.height="{ item }">
        <div>{{ item.height }}</div>
      </template>

      <template v-slot:item.date="{ item }">
        <div>{{ formatDate(item.createdAt) }}</div>
      </template>

      <template v-slot:item.awaitingAssemble="{ item }">
        <div>{{ item.awaitingAssemble ? "yes" : "no" }}</div>
      </template>

      <template v-slot:item.confirmPayment="{ item }">
        <div>{{ item.confirmPayment ? "yes" : "no" }}</div>
      </template>

      <template v-slot:item.awaitingApproval="{ item }">
        <div>{{ item.awaitingApprove ? "yes" : "no" }}</div>
      </template>

      <template v-slot:item.approved="{ item }">
        <div>{{ item.approved ? "yes" : "no" }}</div>
      </template>

      <template v-slot:item.shipped="{ item }">
        <div>{{ item.status ? "yes" : "no" }}</div>
      </template>

      <template v-slot:item.shipDate="{ item }">
        <div>{{ item.shipDate ? formatDate(item.shipDate) : "" }}</div>
      </template>
    </v-data-table>

    <!-- User Deleted Packages -->
    <h3 class="ma-5">User Trash</h3>

    <v-data-table
      :headers="trashTableHeaders"
      :items="trash"
      :items-per-page="10"
      class="elevation-2 grey lighten-5 ma-3"
    >
      <template v-slot:item.trackNumber="{ item }">
        <div>
          <router-link
            style="text-decoration: none; font-size: 16px; color: #2e86c1"
            :to="
              '/en/admin/deleted-packages?packageId=' +
              item.trackNumber +
              '&deleted=1'
            "
          >
            <div v-if="item.assemble">{{ item.packageId }}</div>
            <div v-else>{{ item.trackNumber }}</div>
          </router-link>
        </div>
      </template>

      <template v-slot:item.weight="{ item }">
        <div>{{ item.weight }} kg</div>
      </template>

      <template v-slot:item.length="{ item }">
        <div>{{ item.length }}</div>
      </template>

      <template v-slot:item.width="{ item }">
        <div>{{ item.width }}</div>
      </template>

      <template v-slot:item.height="{ item }">
        <div>{{ item.height }}</div>
      </template>

      <template v-slot:item.date="{ item }">
        <div>{{ formatDate(item.createdAt) }}</div>
      </template>

      <template v-slot:item.confirmPayment="{ item }">
        <div>{{ item.confirmPayment ? "yes" : "no" }}</div>
      </template>

      <template v-slot:item.approved="{ item }">
        <div>{{ item.approved ? "yes" : "no" }}</div>
      </template>

      <template v-slot:item.shipped="{ item }">
        <div>{{ item.status ? "yes" : "no" }}</div>
      </template>

      <template v-slot:item.shipDate="{ item }">
        <div>{{ item.shipDate ? formatDate(item.shipDate) : "" }}</div>
      </template>

      <template v-slot:item.deleted="{ item }">
        <div>{{ item.deleted ? "yes" : "no" }}</div>
      </template>
    </v-data-table>

    <h3 class="ma-5">User Transactions</h3>

    <v-data-table
      :headers="transactionsTableHeaders"
      :items="user.transactions"
      :items-per-page="10"
      class="elevation-2 grey lighten-5 ma-3"
    >
      <template v-slot:item.status="{ item }">
        <div>{{ item.complete ? "Complete" : "Incomplete" }}</div>
      </template>

      <template v-slot:item.date="{ item }">
        <div>{{ formatDate(item.createdAt) }}</div>
      </template>

      <template v-slot:item.paymentId="{ item }">
        <div>{{ item.paymentId }}</div>
      </template>

      <template v-slot:item.trackNumber="{ item }">
        <div>{{ item.package.trackNumber }}</div>
      </template>

      <template v-slot:item.amount="{ item }">
        <div class="success--text">{{ item.amount }}$</div>
      </template>
    </v-data-table>

    <v-dialog max-width="600" v-model="suspiciousDialog">
      <v-card>
        <v-card-title>Susipcion Reason:</v-card-title>

        <v-card-text>
          <v-textarea
            required
            name="Reason"
            label="Reason"
            v-model="suspicionReason"
            color="indigo"
          ></v-textarea>
        </v-card-text>

        <v-card-text class="d-flex justify-center">
          <v-btn @click="updateSuspicious" class="white--text" color="indigo"
            >Submit</v-btn
          >
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-snackbar v-model="snackbar" :color="color" left bottom>
      {{ message }}
    </v-snackbar>
  </v-container>
</template>

<script>
import axios from "axios";
import auth from "../helpers/auth";

export default {
  name: "User",
  data() {
    return {
      admin: auth.admin(),
      adminId: 1,
      supportId: 2,
      user: {},
      packages: [],
      remainingPackages: [],
      trash: [],
      editDialog: false,
      selectedItem: {},
      snackbar: false,
      color: "",
      message: "",
      loading: false,
      suspicious: false,
      updatingSus: false,
      suspiciousDialog: false,
      suspicionReason: "",
      packagesTableHeaders: [
        {
          text: "Track Number",
          value: "trackNumber",
          align: "center",
        },

        {
          text: "Weight",
          value: "weight",
          align: "center",
        },
        {
          text: "Length",
          value: "length",
          align: "center",
        },

        {
          text: "Width",
          value: "width",
          align: "center",
        },
        {
          text: "Height",
          value: "height",
          align: "center",
        },

        {
          text: "Request Date",
          value: "date",
          align: "center",
        },
        {
          text: "Awaiting Assemble",
          value: "awaitingAssemble",
          align: "center",
        },
        {
          text: "Confirm Payment",
          value: "confirmPayment",
          align: "center",
        },
        {
          text: "Approved",
          value: "approved",
          align: "center",
        },
        {
          text: "Awaiting Approval",
          value: "awaitingApproval",
          align: "center",
        },
        {
          text: "Shipped",
          value: "shipped",
          align: "center",
        },
        {
          text: "Ship Date",
          value: "shipDate",
          align: "center",
        },
      ],
      transactionsTableHeaders: [
        {
          text: "Date",
          value: "date",
          align: "center",
        },
        {
          text: "Status",
          value: "status",
          align: "center",
        },
        {
          text: "Payment ID",
          value: "paymentId",
          align: "center",
        },
        {
          text: "Package Track Number",
          value: "trackNumber",
          align: "center",
        },
        {
          text: "Amount",
          value: "amount",
          align: "center",
        },
      ],
      trashTableHeaders: [
        {
          text: "Track Number",
          value: "trackNumber",
          align: "center",
        },

        {
          text: "Weight",
          value: "weight",
          align: "center",
        },
        {
          text: "Length",
          value: "length",
          align: "center",
        },

        {
          text: "Width",
          value: "width",
          align: "center",
        },
        {
          text: "Height",
          value: "height",
          align: "center",
        },

        {
          text: "Request Date",
          value: "date",
          align: "center",
        },
        {
          text: "Confirm Payment",
          value: "confirmPayment",
          align: "center",
        },
        {
          text: "Approved",
          value: "approved",
          align: "center",
        },
        {
          text: "Shipped",
          value: "shipped",
          align: "center",
        },
        {
          text: "Ship Date",
          value: "shipDate",
          align: "center",
        },
        {
          text: "Deleted",
          value: "deleted",
          align: "center",
        },
      ],
    };
  },
  mounted() {
    axios
      .get("/api/users/id/" + this.$route.params.id)
      .then((res) => {
        this.user = res.data.user;
        this.packages = this.user.packages.reverse().filter((item) => !item.deleted);
        this.remainingPackages = this.user.packages.filter((item) => !item.status && item.show && !item.deleted);
        this.trash = this.user.packages.filter((item) => item.deleted);
        this.suspicious = this.user.suspicious;
      })
      .catch((err) => {
        console.log(err);
      });
  },
  methods: {
    formatDate(date) {
      var d = new Date(date),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear(),
        hour = d.getHours(),
        mins = d.getMinutes(),
        secs = d.getSeconds();
      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;
      if (mins < 10) mins = "0" + mins;
      if (secs < 10) secs = "0" + secs;
      if (hour < 10) hour = "0" + hour;
      return [year, month, day].join("-") + ` ${hour}:${mins}:${secs}`;
    },
    renderStatus(item) {
      if (item.confirmPayment && item.status) {
        return "shipped";
      } else if (item.confirmPayment) {
        return "await Shipping";
      } else if (!item.confirmPayment) {
        return "Not Payed";
      }
    },
    setItem() {
      this.selectedItem.email = this.user.email;
      this.selectedItem.phone = this.user.phone;
      this.selectedItem.firstName = this.user.firstName;
      this.selectedItem.lastName = this.user.lastName;

      this.editDialog = true;
    },
    handleUpdateSuspicious() {
      if (this.suspicious) {
        this.suspiciousDialog = true;
      } else {
        this.updateSuspicious();
      }
    },
    updateSuspicious() {
      if (this.suspicious && !this.suspicionReason) {
        this.snackbar = true;
        this.message = "Reason Required";
        this.color = "error";
        return;
      }
      this.updatingSus = true;

      axios
        .post("/api/users/update/suspicious/" + this.$route.params.id, {
          sus: this.suspicious,
          reason: this.suspicionReason,
        })
        .then((res) => {
          if (!res.data.error) {
            this.snackbar = true;
            this.color = "indigo";
            this.message = "Updated Successfully";
            this.suspicionReason = '';
          } else {
            this.snackbar = true;
            this.color = "error";
            this.message = "An error happend please try again";
          }

          this.updatingSus = false;
        })
        .catch((err) => {
          console.log(err);
          this.snackbar = true;
          this.color = "error";
          this.message = "An error happend please try again";
        });
    },
    editUser() {
      if (
        !this.selectedItem.firstName ||
        !this.selectedItem.lastName ||
        !this.selectedItem.email ||
        !this.selectedItem.phone
      ) {
        this.snackbar = true;
        this.color = "error";
        this.message = "Please fill all fields";

        return;
      }

      this.loading = true;

      axios
        .post("/api/admins/users/edit/" + this.user._id, {
          phone: this.selectedItem.phone,
          email: this.selectedItem.email,
          firstName: this.selectedItem.firstName,
          lastName: this.selectedItem.lastName,
        })
        .then((res) => {
          this.loading = false;

          if (!res.data.error) {
            this.snackbar = true;
            this.color = "success";
            this.message = res.data.message;

            this.user.email = this.selectedItem.email;
            this.user.phone = this.selectedItem.phone;
            this.user.firstName = this.selectedItem.firstName;
            this.user.lastName = this.selectedItem.lastName;
            this.user.fullName =
              this.selectedItem.firstName + " " + this.selectedItem.lastName;

            this.editDialog = false;
          }
        })
        .catch((err) => {
          console.log(err);

          this.snackbar = true;
          this.color = "error";
          this.message = "An error happend please try again";
        });
    },
  },
};
</script>
