import Vue from "vue";
import Router from "vue-router";
import i18n from "./plugins/i18n";
import RouterView from "./components/RouterView.vue";
import Login from "./views/Login";
import Signup from "./views/Signup";
import Dashboard from "./views/Dashboard";
import ConfirmationPage from "./views/ConfirmationPage";
import AddressBook from "./views/AddressBook";
import AdminLogin from "./views/AdminLogin";
import AddressManagment from "./views/ManageAddress";
import ManageRates from "./views/ManageRates";
import ManageSideRates from "./views/ManageSideRates";
import AddPackage from "./views/AddPackage";
import AddParcel from "./views/add-package/AddParcel";
import AddMail from "./views/add-package/AddMail";
import ManageUsers from "./views/ManageUsers";
import EditUser from "./views/EditUser";
import ImageRequests from "./views/ImageRequests";
import AssembleRequests from "./views/AssembleRequests";
import ManageShipping from "./views/ManageShipping";
import Transactions from "./views/Transactions";
import ChangePass from "./views/ChangePass";
import User from "./views/User";
import ShippingCalculator from "./views/ShippingCalculator";
import SideCalcShipping from "./views/SideCalculator";
import EditProfile from "./views/EditProfile";
import UserSupport from "./views/UserSupport";
import SupportTicket from "./views/SupportTicket";
import ManageSupport from "./views/ManageSupport";
import AdminSupport from "./views/AdminSupport";
import AddressPage from "./views/AddressPage";
import RecoverPass from "./views/RecoverPass";
import ShippedPackages from "./views/ShippedPackages";
import UserShippedPackages from "./views/UserShippedPackages";
import Zones from "./views/Zones";
import Page404 from "./views/404";
import ManageShops from "./views/ManageShops";
import ManageShippingRequests from "./views/ManageShippingRequests";
import BitcoinPayment from "./views/BitcoinPayment";
import ManagePackages from "./views/ManagePackages";
import ManageAlerts from "./views/ManageAlerts";
import OfflinePayment from "./views/OfflinePayment";
import MarketFrame from "./views/MarketFrame";
import DeletedPackages from "./views/DeletedPackages";
import UserTransactions from "./views/UserTransaction";
import ManageAssemble from "./views/ManageAssemble";
import ManageExpiredPackages from "./views/ManageExpiredPackages";
import AwaitingShippingPackages from "./views/AwaitingShippingPackages";
import SearchPackages from "./views/SearchPackages";
import ManageDisassemble from './views/ManageDisassemble'

import auth from "./helpers/auth";

Vue.use(Router);

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/:lang",
      component: RouterView,
      beforeEnter(to, from, next) {
        const lang = to.params.lang;

        if (!["en", "ar", "fr"].includes(lang)) return next("ar");

        if (i18n.locale !== lang) {
          i18n.locale = lang;
        }

        return next();
      },
      children: [
        {
          path: "",
          name: "dashboard",
          component: Dashboard,
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "confirm-email",
          name: "Email Confirmation",
          component: ConfirmationPage,
          meta: {
            requiresNotConfirmed: true,
          },
        },
        {
          path: "side-calculator",
          name: "Shipping Calculator",
          component: SideCalcShipping,
        },
        {
          path: "market-frame",
          name: "Market",
          props: true,
          component: MarketFrame,
        },
        {
          path: "login",
          name: "Login",
          component: Login,
          meta: {
            notLoggedIn: true,
          },
        },
        {
          path: "recoverpass",
          name: "Recover Password",
          component: RecoverPass,
          meta: {
            notLoggedIn: true,
          },
        },
        {
          path: "signup",
          name: "SignUp",
          component: Signup,
          meta: {
            notLoggedIn: true,
          },
        },
        {
          path: "404",
          component: Page404,
          meta: {
            notLoggedIn: true,
          },
        },
        {
          path: "calculator",
          name: "Shipping Calculator",
          component: ShippingCalculator,
          // meta:{
          //   requiresAuth:true
          // }
        },
        {
          path: "dashboard",
          name: "dashboard",
          component: Dashboard,
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "shipped",
          name: "Shipped Packages",
          component: UserShippedPackages,
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "awaiting-shipping",
          name: "Awaiting Shipping Packages",
          component: AwaitingShippingPackages,
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "search",
          name: "Search Packages",
          component: SearchPackages,
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "user/edit",
          name: "Edit Profile",
          component: EditProfile,
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "addressbook",
          name: "addresssBook",
          component: AddressBook,
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "support",
          name: "User Support",
          component: UserSupport,
          meta: {
            requiresAuth: true,
          },
        },

        {
          path: "address",
          name: "Address Page",
          component: AddressPage,
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "transactions",
          name: "Transaction Page",
          component: UserTransactions,
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "support/:id",
          name: "Support Ticket",
          component: SupportTicket,
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "panel",
          name: "Admin Login",
          component: AdminLogin,
          meta: {
            notAdminLoggedIn: true,
          },
        },
        {
          path: "admin",
          name: "Admin Panel",
          meta: {
            requiresAdmin: true,
          },
          component: () => {
            if (auth.admin().groupId <= 1 || auth.admin().groupId == 2) {
              return import("./views/AdminPanel");
            } else if (auth.admin().groupId == 3) {
              return import("./views/sub-admins/AdminAssemble");
            } else if (auth.admin().groupId == 4) {
              return import("./views/sub-admins/AdminApproval");
            }
          },
          children: [
            {
              path: "",
              name: "Address Management",
              component: AddressManagment,
            },
            {
              path: "manage-assemble",
              name: "Manage Assemble",
              component: ManageAssemble,
            },
            {
              path: "manage-expired",
              name: "Manage Expired Packages",
              component: ManageExpiredPackages,
            },
            {
              path: "rates",
              name: "Shipping Rates",
              component: ManageRates,
            },
            {
              path: "side-rates",
              name: "Shipping Rates",
              component: ManageSideRates,
            },
            {
              path: "addpackage/parcel",
              name: "Add Parcel",
              component: AddParcel,
            },
            {
              path: "addpackage/mail",
              name: "Add Mail",
              component: AddMail,
            },
            {
              path: "manage-packages",
              name: "Manage Package",
              component: ManagePackages,
            },
            {
              path: "deleted-packages",
              name: "Manage Trash",
              component: DeletedPackages,
            },
            {
              path: "manageusers",
              name: "Manage Users",
              component: ManageUsers,
            },
            {
              path: "manage-shops",
              name: "Manage Shops",
              component: ManageShops,
            },
            {
              path: "edituser/:id",
              name: "Edit User",
              component: EditUser,
            },
            {
              path: "requests/images",
              name: "Image Requests",
              component: ImageRequests,
            },
            {
              path: "payment/offline",
              name: "Offline Payment",
              component: OfflinePayment,
            },
            {
              path: "requests/bitcoin",
              name: "Bitcoin Requests",
              component: BitcoinPayment,
            },
            {
              path: "requests/assemble",
              name: "Assemble Requests",
              component: AssembleRequests,
            },
            {
              path: "requests/disassemble",
              name: "Disassemble Requests",
              component: ManageDisassemble,
            },
            {
              path: "requests/shipping",
              name: "Shipping Requests",
              component: ManageShipping,
            },
            {
              path: "requests/shipping-requests",
              name: "Shipping Requests",
              component: ManageShippingRequests,
            },
            {
              path: "transactions",
              name: "Transactions",
              component: Transactions,
            },
            {
              path: "changepass",
              name: "Change Password",
              component: ChangePass,
            },
            {
              path: "user/:id",
              name: "Manage User",
              component: User,
            },
            {
              path: "support",
              name: "Manage Support",
              component: ManageSupport,
            },
            {
              path: "support/:id",
              name: "Admin Support",
              component: AdminSupport,
            },
            {
              path: "packages/shipped",
              name: "Shipped Packages",
              component: ShippedPackages,
            },
            {
              path: "zones",
              name: "Zones",
              component: Zones,
            },
            {
              path: "manage-alerts",
              name: "Manage Alerts",
              component: ManageAlerts,
            },
          ],
        },
      ],
    },

    {
      path: "*",
      redirect: "ar",
    },
  ],
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 };
  },
});

router.beforeEach(async(to, from, next) => {
  // let lang = localStorage.getItem('lang');
  // if(lang){
  //    to.params.lang = lang;
  // }else{
  //   localStorage.setItem('lang','ar')
  // }
  // console.log(await auth.confirmed())
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    // const confirmed = !await auth.confirmed()
    if (!await auth.authenticated()) {
      next("/" + to.params.lang + "/login");
    } else {
      if(!await auth.confirmed()){
          next("/" + to.params.lang + "/confirm-email");
        }
        else{
          next();
        }
    }
  } else if (to.matched.some((record) => record.meta.requiresNotConfirmed)) {
    if (!await auth.authenticated()) {
      next("/" + to.params.lang + "/login");
    } else {
      if (await auth.confirmed()) {
        return next("/" + to.params.lang + "/dashboard");
      } else {
        next();
      }
    }
  } else if (to.matched.some((record) => record.meta.notLoggedIn)) {
    if (await auth.authenticated()) {
      return next("/" + to.params.lang + "/dashboard");
    } else {
      next();
    }
  } else if (to.matched.some((record) => record.meta.requiresAdmin)) {
    if (auth.adminAuthenticated()) {
      to.params.lang = "en";

      if (auth.admin().groupId != 0 && auth.admin().groupId != 1 && auth.admin().groupId != 2) {
        let currentPath = to.path.split("/").pop();

        if (!currentPath) {
          currentPath = to.path.split("/").pop();
        }

        let allowedRoutes = [
          "shipping-requests",
          "changepass",
          "manage-assemble",
          "assemble",
          "addpackage",
          "admin",
          "support",
          "panel",
        ];

        if (!allowedRoutes.includes(currentPath)) {
          return next("/en/admin/support");
        } else {
          console.log(currentPath);
        }
      }
      next();
    } else {
      return next("/en/panel");
    }
  } else if (to.matched.some((record) => record.meta.notAdminLoggedIn)) {
    if (auth.adminAuthenticated()) {
      return next("/en/admin/address");
    } else {
      next();
    }
  } else {
    next(); // make sure to call next at the end
  }
});
export default router;
