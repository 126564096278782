<template>
  <v-container>
    <v-data-table
      :headers="tableHeaders"
      :items="transactions"
      :page.sync="page"
      :server-items-length="requestCount"
      class="elevation-2 grey lighten-5 ma-3"
      @update:page="pageUpdateFunction"
      @update:items-per-page="itemsPerPageUpdateFunction"
      :items-per-page.sync="itemsPerPage"
    >

      <template v-slot:top>
                <v-row class="d-flex align-center">
                    <v-col :cols="10">
                        <v-text-field v-model="search" placeholder="Search" class="mx-4"></v-text-field>
                    </v-col>
                    <v-col :cols="2">
                        <v-btn class="mx-4" @click="searchUpdateFunction" color="primary">Search</v-btn>
                    </v-col>
                </v-row>
            </template>

      <template v-slot:item.username="{ item }">
        <router-link
          style="text-decoration: none; font-size: 16px; color: #2e86c1"
          :to="'/en/admin/user/' + item.user.id"
        >
          <v-icon
            color="red"
            class="mx-3"
            size="15"
            v-if="item.user ? item.user.suspicious : false"
            >fas fa-exclamation-triangle</v-icon
          >
          {{ item.user ? item.user.fullName : "" }}
        </router-link>
      </template>

      <template v-slot:item.userHash="{ item }">
        <div>{{ item.user.addressHash }}</div>
      </template>

      <template v-slot:item.status="{ item }">
        <div>{{ item.complete ? "Complete" : "Incomplete" }}</div>
      </template>

      <template v-slot:item.date="{ item }">
        <div>{{ formatDate(item.createdAt) }}</div>
      </template>

      <template v-slot:item.paymentId="{ item }">
        <div>{{ item.paymentId }}</div>
      </template>

      <template v-slot:item.type="{ item }">
        <div>{{ item.type }}</div>
      </template>

      <template v-slot:item.trackNumber="{ item }">
        <div>{{ item.package ? item.package.trackNumber : "" }}</div>
      </template>

      <template v-slot:item.amount="{ item }">
        <div class="success--text" @click="selectTransaction(item)">
          {{ item.amount }} &euro;
        </div>
      </template>

      <template v-slot:item.actions="{ item }">
        <div>
          <v-btn
            class="ma-2"
            @click="selectTransaction(item)"
            small
            tile
            outlined
            color="primary"
          :disabled="!item.complete"
          >
            <v-icon size="15" left>fas fa-eye</v-icon> Bill
          </v-btn>
        </div>
      </template>

      <template v-slot:footer>
        <v-row justify="center">
          <v-col cols="8">
            <v-container class="max-width">
              <v-pagination
                v-model="page"
                class="my-4"
                :total-visible="7"
                :length="pageCount"
                @input="pageUpdateFunction"
              ></v-pagination>
            </v-container>
          </v-col>
        </v-row>
      </template>
    </v-data-table>

    <v-dialog v-model="factureDialog" max-width="600">
      <v-card class="facture">
        <div class="facture__container">
          <img class="facture__logo" src="/img/icons/logo.png" alt="logo" />

          <v-btn
            class="ma-2"
            @click="generatePDF()"
            small
            tile
            outlined
            color="primary"
          >
            <v-icon size="15" left>fa-file-arrow-down</v-icon>
            Download
          </v-btn>
        </div>
        <div class="facture__header">
          <div class="facture__header-info">
            <span class="facture__header-info--adr">{{
              `${
                selectedTransaction.user
                  ? selectedTransaction.user.firstName
                  : ""
              } ${
                selectedTransaction.user
                  ? selectedTransaction.user.lastName
                  : ""
              } ${
                selectedTransaction.package
                  ? selectedTransaction.package.shippingAddress.addressLineOne
                  : ""
              }`
            }}</span>
            <span class="facture__header-info--adr">{{
              `${
                selectedTransaction.package
                  ? selectedTransaction.package.shippingAddress.addressLineTwo
                  : ""
              }`
            }}</span>
          </div>
        </div>
        <div class="facture__title">
          <h3>Invoice</h3>
        </div>
        <div class="facture__info">
          <div class="facture__info-container">
            <div class="facture__info-row">
              <span class="facture__info-title">Invoice Number : </span>
              <span class="facture__info-value">{{
                selectedTransaction.paymentId
              }}</span>
            </div>

            <div class="facture__info-row">
              <span class="facture__info-title">Date of Invoice : </span
              ><span class="facture__info-value">{{
                moment(selectedTransaction.createdAt).format("L")
              }}</span>
            </div>

            <div class="facture__info-row">
              <span class="facture__info-title">Invoice Currency: </span
              ><span class="facture__info-value">{{
                selectedTransaction.currency
              }}</span>
            </div>

            <div class="facture__info-row">
              <span class="facture__info-title">Customer Reference : </span
              ><span class="facture__info-value">{{
                selectedTransaction.user
                  ? selectedTransaction.user.addressHash
                  : ""
              }}</span>
            </div>
          </div>
        </div>
        <div class="facture__table">
          <table>
            <thead>
              <tr>
                <th>Service description</th>
                <th>VAT Rate</th>
                <th>Quantity</th>
                <th>Unit Price Excl VAT</th>
                <th>Total incl VAT</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  Forwarding Shipping N° 1260404
                  {{
                    selectedTransaction.package
                      ? selectedTransaction.package.trackNumber
                      : ""
                  }}
                  Shipped from France (FR) Destination:
                  {{
                    selectedTransaction.package
                      ? selectedTransaction.package.shippingAddress.country
                      : ""
                  }}
                </td>
                <td>0%</td>
                <td>1</td>
                <td>{{ selectedTransaction.amount }} €</td>
                <td>{{ selectedTransaction.amount }} €</td>
              </tr>
            </tbody>
          </table>
          <div class="facture__data">
            <div class="facture__data-info">
              <div>
                <span class="facture__data-info--title"
                  >Amount collected online: </span
                ><span class="facture__data-info--value"
                  >{{ selectedTransaction.amount }} €</span
                >
              </div>

              <div>
                <span class="facture__data-info--title">Payment Date: </span
                ><span class="facture__data-info--value">{{
                  moment(selectedTransaction.createdAt).format("L")
                }}</span>
              </div>

              <div>
                <span class="facture__data-info--title">Payment Method: </span
                ><span class="facture__data-info--value">{{
                  selectedTransaction.type
                }}</span>
              </div>
            </div>
            <div class="facture__data-table">
              <table>
                <thead>
                  <tr>
                    <th>Total excl VAT</th>
                    <th>VAT (20%)</th>
                    <th>Total incl VAT</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{{ selectedTransaction.amount }} €</td>
                    <td>0 €</td>
                    <td>{{ selectedTransaction.amount }} €</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="facture__legal-info">
            <span>ANDALUSIA INTERNATIONAL CO., LIMITED</span>
            <span>Registration No. 1517212</span>
            <span
              >flat 3b, 3/f, bank tower, nos.351 & 353 klng’s road, north point,
              hong kong</span
            >
          </div>
        </div>
      </v-card>
    </v-dialog>
    <vue-html2pdf
      :show-layout="false"
      :float-layout="true"
      :enable-download="true"
      :preview-modal="true"
      :paginate-elements-by-height="1400"
      :filename="selectedTransaction.paymentId"
      :pdf-quality="2"
      :manual-pagination="false"
      pdf-format="a4"
      :pdf-margin="10"
      pdf-orientation="portrait"
      pdf-content-width="800px"
      ref="html2Pdf"
    >
      <section class="facture" slot="pdf-content">
        <img class="facture__logo" src="/img/icons/logo.png" alt="logo" />

        <div class="facture__header">
          <div class="facture__header-info">
            <span class="facture__header-info--adr">{{
              `${
                selectedTransaction.user
                  ? selectedTransaction.user.firstName
                  : ""
              } ${
                selectedTransaction.user
                  ? selectedTransaction.user.lastName
                  : ""
              } ${
                selectedTransaction.package
                  ? selectedTransaction.package.shippingAddress.addressLineOne
                  : ""
              }`
            }}</span>
            <span class="facture__header-info--adr">{{
              `${
                selectedTransaction.package
                  ? selectedTransaction.package.shippingAddress.addressLineTwo
                  : ""
              }`
            }}</span>
          </div>
        </div>
        <div class="facture__title">
          <h3>Invoice</h3>
        </div>
        <div class="facture__info">
          <div class="facture__info-container">
            <div class="facture__info-row">
              <span class="facture__info-title">Invoice Number : </span>
              <span class="facture__info-value">{{
                selectedTransaction.paymentId
              }}</span>
            </div>

            <div class="facture__info-row">
              <span class="facture__info-title">Date of Invoice : </span
              ><span class="facture__info-value">{{
                moment(selectedTransaction.createdAt).format("L")
              }}</span>
            </div>

            <div class="facture__info-row">
              <span class="facture__info-title">Invoice Currency: </span
              ><span class="facture__info-value">{{
                selectedTransaction.currency
              }}</span>
            </div>

            <div class="facture__info-row">
              <span class="facture__info-title">Customer Reference : </span
              ><span class="facture__info-value">{{
                selectedTransaction.user
                  ? selectedTransaction.user.addressHash
                  : ""
              }}</span>
            </div>
          </div>
        </div>
        <div class="facture__table">
          <table>
            <thead>
              <tr>
                <th>Service description</th>
                <th>VAT Rate</th>
                <th>Quantity</th>
                <th>Unit Price Excl VAT</th>
                <th>Total incl VAT</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  Forwarding Shipping N° 1260404
                  {{
                    selectedTransaction.package
                      ? selectedTransaction.package.trackNumber
                      : ""
                  }}
                  Shipped from France (FR) Destination:
                  {{
                    selectedTransaction.package
                      ? selectedTransaction.package.shippingAddress.country
                      : ""
                  }}
                </td>
                <td>0%</td>
                <td>1</td>
                <td>{{ selectedTransaction.amount }} €</td>
                <td>{{ selectedTransaction.amount }} €</td>
              </tr>
            </tbody>
          </table>
          <div class="facture__data">
            <div class="facture__data-info">
              <div>
                <span class="facture__data-info--title"
                  >Amount collected online: </span
                ><span class="facture__data-info--value"
                  >{{ selectedTransaction.amount }} €</span
                >
              </div>

              <div>
                <span class="facture__data-info--title">Payment Date: </span
                ><span class="facture__data-info--value">{{
                  moment(selectedTransaction.createdAt).format("L")
                }}</span>
              </div>

              <div>
                <span class="facture__data-info--title">Payment Method: </span
                ><span class="facture__data-info--value">{{
                  selectedTransaction.type
                }}</span>
              </div>
            </div>
            <div class="facture__data-table">
              <table>
                <thead>
                  <tr>
                    <th>Total excl VAT</th>
                    <th>VAT (20%)</th>
                    <th>Total incl VAT</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{{ selectedTransaction.amount }} €</td>
                    <td>0 €</td>
                    <td>{{ selectedTransaction.amount }} €</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="facture__legal-info">
            <span>ANDALUSIA INTERNATIONAL CO., LIMITED</span>
            <span>Registration No. 1517212</span>
            <span
              >flat 3b, 3/f, bank tower, nos.351 & 353 klng’s road, north point,
              hong kong</span
            >
          </div>
        </div>
      </section>
    </vue-html2pdf>
  </v-container>
</template>

<script>
import axios from "axios";
import moment from "moment";
import VueHtml2pdf from "vue-html2pdf";

export default {
  name: "Transactions",
  components: {
    VueHtml2pdf,
  },
  mounted() {
    axios.get("/api/admins/transactions").then((res) => {
      this.requestCount = res.data.requestCount;
      this.pageCount = res.data.pageCount;
      this.transactions = res.data.transactions;
    });
  },
  data() {
    return {
      page: 1,
      pageCount: 5,
      itemsPerPage: 10,
      requestCount: 1,
      transactions: [],
      factureDialog: false,
      search: "",
      selectedTransaction: {},
      tableHeaders: [
        {
          text: "User Name",
          value: "username",
          align: "center",
        },
        {
          text: "User Hash",
          value: "userHash",
          align: "center",
        },
        {
          text: "Date",
          value: "date",
          align: "center",
        },
        {
          text: "Status",
          value: "status",
          align: "center",
        },
        {
          text: "Payment ID",
          value: "paymentId",
          align: "center",
        },
        {
          text: "Payment Type",
          value: "type",
          align: "center",
        },
        {
          text: "Track Number",
          value: "trackNumber",
          align: "center",
        },
        {
          text: "Amount",
          value: "amount",
          align: "center",
        },
        {
          text: "Actions",
          value: "actions",
          align: "center",
        },
      ],
    };
  },

  watch: {
        search: function (newSearch) {
            if (newSearch === '') {
                axios.get("/api/admins/transactions").then((res) => {
                  this.requestCount = res.data.requestCount;
                  this.pageCount = res.data.pageCount;
                  this.transactions = res.data.transactions;
                });
            }
        }
    },

  methods: {
    selectTransaction(item) {
      this.selectedTransaction = item;
      this.factureDialog = !this.factureDialog;
    },
    generatePDF() {
      this.$refs.html2Pdf.generatePdf();
    },
    moment: function (date) {
      const mom = new Date(date);
      //moment.locale(local(this.$i18n.locale))
      return moment(mom);
    },
    pageUpdateFunction(newPageNumber) {
      axios
        .get(
          `/api/admins/transactions?page=${newPageNumber - 1}&perPage=${
            this.itemsPerPage
          }`
        )
        .then((res) => {
          this.page = newPageNumber;
          this.pageCount = res.data.pageCount;
          this.transactions = res.data.transactions;
        });
    },
    itemsPerPageUpdateFunction(length) {
      this.page = 1;
      this.pageCount = 1;
      axios
        .get(
          `/api/admins/transactions?page=${
            this.pageCount - 1
          }&perPage=${length}`
        )
        .then((res) => {
          this.requestCount = res.data.requestCount;
          this.pageCount = res.data.pageCount;
          this.transactions = res.data.transactions;
        });
    },

    formatDate(date) {
      if (!date) return "xx-xx-xx";
      var d = new Date(date),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear(),
        hour = d.getHours(),
        mins = d.getMinutes();

      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;
      if (mins < 10) mins = "0" + mins;
      if (hour < 10) hour = "0" + hour;

      return [year, month, day].join("-") + ` ${hour}:${mins}`;
    },
    
    searchUpdateFunction(){
            const newQuery = this.search
            if (newQuery) {
                axios.get(`/api/admins/transactions?query=${newQuery}`)
                .then(res => {
                  this.requestCount = res.data.requestCount;
                  this.pageCount = res.data.pageCount;
                  this.transactions = res.data.transactions;
                })
            }
        },
  },
};
</script>
