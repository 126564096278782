<template>
  <v-container>

    <!-- view table -->
    <v-data-table
      :headers="tableHeaders"
      :items="requests"
      :page.sync="page"
      :server-items-length="requestCount"
      class="elevation-2 grey lighten-5 ma-3"
      @update:page="pageUpdateFunction"
      @update:items-per-page="itemsPerPageUpdateFunction"
      :items-per-page.sync="itemsPerPage"
    >
      <template v-slot:top>
        <v-row class="d-flex align-center">
            <v-col :cols="10">
                <v-text-field v-model="search" placeholder="Search" class="mx-4"></v-text-field>
            </v-col>
            <v-col :cols="2">
                <v-btn class="mx-4" @click="searchUpdateFunction" color="primary">Search</v-btn>
            </v-col>
        </v-row>
      </template>

      <template v-slot:item.username="{ item }">
        <router-link 
            style="text-decoration:none;font-size:16px;color:#2E86C1"
            :to="'/en/admin/user/' + item.user.id"
        >
            <v-icon color="red" class="mx-3" size="15" v-if="(item.user) ? item.user.suspicious : false">fas fa-exclamation-triangle</v-icon> {{(item.user) ? item.user.fullName : ''}}
        </router-link>
      </template>

      <template v-slot:item.userHash="{ item }">
        <div :class="isExpired(item.createdAt)">{{item.user.addressHash}}</div>
      </template>

      <template v-slot:item.trackNumber="{ item }">
        <div :class="isExpired(item.createdAt)">{{item.trackNumber}}</div>
      </template>

      <template v-slot:item.date="{ item }">
        <div :class="isExpired(item.createdAt)">{{ formatDate(item.createdAt)}}</div>
      </template>

      <template v-slot:item.actions="{ item }">
        <div>
          <v-btn class="ma-2" @click="showPackage(item)" small tile outlined color="primary">
            <v-icon size="15" left>fas fa-eye</v-icon>show
          </v-btn>

          <v-btn
            v-if="user.groupId <= adminId"
            class="ma-2"
            @click="selectPackage(item)"
            small
            tile
            outlined
            color="success"
          >
            <v-icon size="15" left>fas fa-check-circle</v-icon>Approve
          </v-btn>

          <v-btn
            v-if="user.groupId <= adminId"
            class="ma-2"
            @click="selectCancel(item)"
            small
            tile
            outlined
            color="red"
          >
            <v-icon size="15" left>fas fa-times-circle</v-icon>Cancel
          </v-btn>
        </div>
      </template>

      <template v-slot:footer>
          <v-row justify="center">
              <v-col cols="8">
                  <v-container class="max-width">
                      <v-pagination
                      v-model="page"
                      class="my-4"
                      :total-visible="7"
                      :length="pageCount"
                      @input="pageUpdateFunction"
                      ></v-pagination>
                  </v-container>
              </v-col>
          </v-row>
      </template>

    </v-data-table>

    <!-- ship modal -->

    <v-dialog v-model="cancelDialog" max-width="600">

      <v-card>
        <v-card-title class="headline">Please confirm your action</v-card-title>
        <v-spacer></v-spacer>
        <v-card-text>
          <v-alert
             border="top"
            colored-border
            type="warning"
            elevation="2"
          >
            Are you sure that you want to delete request with <b>Track Number: {{selectedRequest.trackNumber}}</b>
          </v-alert>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn depressed @click="handleCancel(selectedRequest)" color="error">Delete</v-btn>
          <v-btn @click="cancelDialog=!cancelDialog" class="ma-2" color="grey white--text">cancel</v-btn>
        </v-card-actions>
      </v-card>

    </v-dialog>


<!-- ship modal -->

    <v-dialog v-model="shipDialog" max-width="600">

        <v-card>
            <v-card-title class="d-flex align-center mb-4">
                Shipping Methods
                <v-btn class="ml-auto" color="success" @click.stop="addMethod">Add</v-btn>
            </v-card-title>

            <v-card-text>

            
            <v-row v-for="(item,i) in methods" :key="i">
                <v-col cols="9">
                    <v-select
                        label="Shipping Company"
                        :items="shippingCompanies"
                        v-model="item.company"
                    />
                </v-col>

                <v-col cols="3">
                    <v-text-field
                        label="Price"
                        v-model="item.price"
                        type="number"
                        min="0"
                    ></v-text-field>
                </v-col>
            </v-row>
            

            <div v-if="loading" class="d-flex justify-center">
                <v-progress-circular
                :indeterminate="true"
                size="40"
                color="indigo"
            ></v-progress-circular>
                </div>

            <v-btn v-if="!loading && methods.length > 0" color="indigo" @click="handleApprove" class="white--text"> <v-icon color="white" left size="20">fas fa-check-circle</v-icon> Approve</v-btn>
                </v-card-text>
        </v-card>
    </v-dialog>


    
    <!-- package info modal -->

    <v-dialog v-model="dialog" max-width="600">
      <v-card class="pa-3">
        <v-card-title>Information</v-card-title>
        <v-card-text>
          <div class="ma-1" style="font-size:16px">
            <b>Package Id:</b> <span>{{selectedRequest.packageId}}</span>
          </div>
          <div class="ma-1" style="font-size:16px">
            <b>Track Number:</b> <span>{{selectedRequest.trackNumber}}</span>
          </div>
          <div class="ma-1" style="font-size:16px">
            <b>Weight:</b> <span>{{selectedRequest.weight}} kg</span>
          </div>
          <div class="ma-1" style="font-size:16px">
            <b>dimensions:</b> <span>{{selectedRequest.length}} * {{selectedRequest.width}} * {{selectedRequest.height}} cm</span>
          </div>
          <div class="ma-1" style="font-size:16px">
              <b>Fees:</b> <span>{{selectedRequest.fees ? `${selectedRequest.fees} €` : 'not mentioned'}}</span>
          </div>


          <template v-for="detail in selectedRequest.details" >
            <v-card>
                <v-card-text>
                    <div class="ma-1" style="font-size:16px">
                        <b>Description:</b> <span>{{detail.description}}</span>
                    </div>

                    <div class="ma-1" style="font-size:16px">
                        <b>Made In:</b> <span>{{detail.madeIn}}</span>
                    </div>

                    <div class="ma-1" style="font-size:16px">
                        <b>Type:</b> <span>{{detail.type}}</span>
                    </div>

                    <div class="ma-1" style="font-size:16px">
                        <b>Quantity:</b> <span>x{{detail.quantity}}</span>
                    </div>

                    <div class="ma-1" style="font-size:16px">
                        <b>price:</b> <span>{{detail.price}}</span>
                    </div>
                </v-card-text>
            </v-card>
          </template>


          <div class="ma-2" style="font-size:20px;font-weight:bold">Images</div>

          <v-row>
            <v-col v-for="img in selectedRequest.images" v-viewer :key="img" sm="4">
              <img height="150" class="ma-2 elevation-3" :src="backendUrl + '/' + img"></img>
            </v-col>
          </v-row>

          <div class="ma-2" style="font-size:20px;font-weight:bold">Shipping Address</div>

          <div v-if="selectedRequest.shippingAddress">

            <div class="ma-1" style="font-size:16px">
              <b>Name:</b> <span>{{selectedRequest.shippingAddress.name}}</span>
            </div>
            <div class="ma-1" style="font-size:16px">
              <b>National ID:</b> <span>{{selectedRequest.shippingAddress.nationalID}}</span>
            </div>
            <div class="ma-1" style="font-size:16px">
              <b>Country:</b> <span>{{selectedRequest.shippingAddress.country}}</span>
            </div>
            <div class="ma-1" style="font-size:16px">
              <b>City:</b> <span>{{selectedRequest.shippingAddress.city}}</span>
            </div>
            <div class="ma-1" style="font-size:16px">
              <b>Address Line One:</b> <span>{{selectedRequest.shippingAddress.addressLineOne}}</span>
            </div>
            <div class="ma-1" style="font-size:16px">
              <b>Address Line Two:</b> <span>{{selectedRequest.shippingAddress.addressLineTwo}}</span>
            </div>

            <div class="ma-1" style="font-size:16px">
              <b>Phone:</b> <span>{{selectedRequest.shippingAddress.phone}}</span>
            </div>

            <div class="ma-1" style="font-size:16px">
              <b>Postal:</b> <span>{{selectedRequest.shippingAddress.zip}}</span>
            </div>
    
          </div>

          <br>
          
          <div class="ma-2" style="font-size:20px;font-weight:bold">Shipping Options</div>

          <div v-if="selectedRequest.shippingOptions">

            <div class="ma-1" style="font-size:16px">
              <b>Does it contain lithium batteries?:</b> <span :style="selectedRequest.shippingOptions.hasLithium ? '' : 'color:red'">{{selectedRequest.shippingOptions.hasLithium ? 'Yes' : 'No'}}</span>
            </div>
            <div class="ma-1" style="font-size:16px">
              <b>Does it contain dangerous substances (such as perfumes)?:</b> <span :style="selectedRequest.shippingOptions.hasDangerous ? '' : 'color:red'">{{selectedRequest.shippingOptions.hasDangerous ? 'Yes' : 'No'}}</span>
            </div>
            <div class="ma-1" style="font-size:16px">
              <b>Remove bills:</b> <span :style="selectedRequest.shippingOptions.removeBills ? '' : 'color:red'">{{selectedRequest.shippingOptions.removeBills ? 'Yes' : 'No'}}</span>
            </div>
            <div class="ma-1" style="font-size:16px">
              <b>Remove shoe boxes:</b> <span :style="selectedRequest.shippingOptions.removeShoeBoxes ? '' : 'color:red'">{{selectedRequest.shippingOptions.removeShoeBoxes ? 'Yes' : 'No'}}</span>
            </div>
            <div class="ma-1" style="font-size:16px">
              <b>Additional notes:</b> <br> 
              <span>{{selectedRequest.shippingOptions.additionalNote}}</span>
            </div>

          </div>
        </v-card-text>
          <v-btn v-if="user.groupId <= adminId" @click="shipDialog=!shipDialog" class="ma-2" color="success white--text">Approve</v-btn>
          
          <v-btn @click="dialog=!dialog" class="ma-2" color="grey white--text">close</v-btn>
        </v-card-actions>

      </v-card>
    </v-dialog>

    <v-snackbar left :color="color" v-model="snackbar">{{ message }}</v-snackbar>
  </v-container>
</template>

<script>
import axios from "axios";
import moment from 'moment'
import auth from "../helpers/auth";
import { backendUrl } from "../config/config";

export default {
  name: "ShippingRequest",
  mounted() {
    axios.get("/api/packages/request/approval/get").then(res => {
      this.requestCount = res.data.requestCount
      this.pageCount = res.data.pageCount
      this.requests = res.data.packages;
    });

     axios.get('/api/users/shipping_companies').then(res=>{
        this.shippingCompanies = res.data.companies.map(item=>{
            return {
                text:item.name,
                value:item._id
            }
        })
    })
  },

  watch: {
        search: function (newSearch) {
            if (newSearch === '') {
                axios.get('/api/packages/request/approval/get').then(res => {
                    this.page = 1
                    this.requestCount = res.data.requestCount
                    this.pageCount = res.data.pageCount
                    this.requests = res.data.packages;
                })
            }
        }
    },

  data() {
    return {
      user: auth.admin(),
      adminId: 1,
      supportId: 2,
      page: 1,
      pageCount: 5,
      requestCount: 1,
      itemsPerPage: 10,
      tableHeaders: [
        {
          text: "Username",
          value: "username",
          align: "center"
        },
        {
          text: "User Hash",
          value: "userHash",
          align: "center"
        },
        {
          text: "Request Date",
          value: "date",
          align: "center"
        },
        {
          text: "Track Number",
          value: "trackNumber",
          align: "center"
        },
        {
          text: "Actions",
          value: "actions",
          align: "center"
        }
      ],
      requests: [],
      dialog: false,
      cancelDialog: false,
      snackbar: false,
      message: "",
      color: "",
      loading: false,
      search: "",
      selectedRequest: {},
      backendUrl,
      shipDialog:false,
      imgDialog: false,
      displayImg: '',
      shippingCompanies:[],
      companyTooltip:false,
      methods:[]
    };
  },
  methods: {
    isExpired(item){
        const itemDate = moment(item)
        if(itemDate.isBefore(moment().subtract(6, 'months')) === true){
            return "expired"
        } else{
            return ''
        }
    },
    pageUpdateFunction(newPageNumber) {
        axios.get(`/api/packages/request/approval/get?page=${newPageNumber-1}&perPage=${this.itemsPerPage}`).then(res=>{
            this.page = newPageNumber
            this.pageCount = res.data.pageCount
            this.requests = res.data.packages

        })

    },
    itemsPerPageUpdateFunction(length) {
        this.page= 1
        this.pageCount = 1
        axios.get(`/api/packages/request/approval/get?page=${this.pageCount -1}&perPage=${length}`).then(res=>{
            this.requestCount = res.data.requestCount
            this.pageCount = res.data.pageCount
            this.requests = res.data.packages

        })

    },

    searchUpdateFunction(){
        const newQuery = this.search
        if (newQuery) {
            axios.get(`/api/packages/request/approval/get?query=${newQuery}`).then(res => {
                this.requestCount = res.data.requestCount
                this.pageCount = res.data.pageCount
                this.requests = res.data.packages
            })
        }
    },

    // customFilter(value, queryText, item) {
    //   const addressHash = item.user.addressHash.toLowerCase();
    //   const username = item.user.fullName.toLowerCase();
    //   const trackNumber = item.trackNumber.toLowerCase();
    //   const searchText = queryText.toLowerCase();

    //   return (
    //     addressHash.indexOf(searchText) > -1 ||
    //     username.indexOf(searchText) > -1 ||
    //     trackNumber.indexOf(searchText) > -1
    //   );
    // },

    formatDate(date) {
        var d = new Date(date) ,
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear(),
            hour = d.getHours(),
            mins = d.getMinutes();

        if (month.length < 2) 
            month = '0' + month;
        if (day.length < 2) 
            day = '0' + day;
        if(mins < 10)
           mins = '0' + mins;
        if(hour < 10)
           hour = '0' + hour;

        return [year, month, day].join('-') + ` ${hour}:${mins}`;
 },
    showPackage(pack) {
      this.dialog = true;
      this.selectedRequest = pack;
    },

    selectPackage(item){
      this.selectedRequest = item;
      this.shipDialog = !this.shipDialog;
    },

    selectCancel(item){
      this.selectedRequest = item;
      this.cancelDialog = !this.cancelDialog;
    },

    handleCancel(item) {
      this.loading = true;
      axios.post('/api/packages/approval/cancel',{
        packageId: item._id,
        }).then(res=>{
          if(!res.data.error){
            this.message = res.data.message;
            this.color = 'success';
            this.snackbar = true;

            this.requests = this.requests.filter((pack)=>pack._id != item._id);

          }else{
            this.message ='An error happened please try again';
            this.color = 'error';
            this.snackbar = true;

          }
          this.cancelDialog= false

          this.loading = false;
        }).catch(err=>{
          console.log(err);

          this.message ='An error happened please try again';
            this.color = 'error';
            this.snackbar = true;
            this.loading = false;
        })
    },

    handleApprove(){

      if(this.methods.length ==0){
          this.snackbar = true;
          this.color = 'error';
          this.message = 'Please Add Methods'
          return;
      }
      
      this.loading = true;
      axios.post('/api/packages/approval/accept',{
        packageId:this.selectedRequest._id,
        methods:this.methods
        }).then(res=>{
          if(!res.data.error){
            this.message = res.data.message;
            this.color = 'success';
            this.snackbar = true;

            this.requests = this.requests.filter((item)=>item._id != this.selectedRequest._id);

            this.methods = [];
            this.shipDialog = false;
            this.dialog = false;

          }else{
            this.message ='An error happened please try again';
            this.color = 'error';
            this.snackbar = true;

          }

          this.loading = false;
        }).catch(err=>{
          console.log(err);

          this.message ='An error happened please try again';
            this.color = 'error';
            this.snackbar = true;
            this.loading = false;
        })
    },
    addMethod(){
        this.methods.push({
            price:0,
            company:''
        })
    }
  }
};
</script>

<style scoped>
    .expired{
        color: red;
        font-weight: bold;
    }
</style>