<template>
    <div>

        <v-container>
            <v-row :style="searched ? '' : 'justify-content: center'">
                <v-col :style="searched ? '' : 'margin: 20px'" cols="12" sm="6" md="5" lg="6">
                    <v-card style="padding: 10px">
                        <v-card-body>
                            <v-form class="ma-3" @submit.prevent="handleSubmit" ref="form">

                        <v-text-field
                        v-model="height"
                        name="weight"
                        label="Hauteur"
                        id="weight"
                        type="number"
                        min="0"
                        color="indigo"
                        required
                        ></v-text-field>

                        <v-text-field
                        v-model="width"
                        name="width"
                        label="Largeur"
                        id="weight"
                        type="number"
                        min="0"
                        color="indigo"
                        required
                        ></v-text-field>

                        <v-text-field
                        v-model="length"
                        name="length"
                        label="Longueur"
                        id="weight"
                        type="number"
                        min="0"
                        color="indigo"
                        required
                        ></v-text-field>

                        <v-text-field
                        v-model="weight"
                        name="weight"
                        label="Poids (kg)"
                        id="weight"
                        type="number"
                        min="0"
                        color="indigo"
                        required
                        ></v-text-field>

                        <v-menu

                        offset-y
                        transition="scale-transition"
                        nudge-top="18"
                        max-height="240"
                        :nudge-width="2"
                        :close-on-content-click="true"

                        v-model="countryMenu"
                        >
                        <template v-slot:activator="{ attrs, on }">
                            <v-text-field
                                label="Pays"
                                v-on="on"
                                v-model="country"
                                autocomplete="off"
                                @keyup="filterCountries"
                            ></v-text-field>
                        </template>

                            <v-list>
                                <v-list-item @click.stop="setCountry(item)" v-for="item in countryList" :key="item.id" class="white pa-2">
                            
                            <v-list-item-content style="direction:ltr">{{item}}</v-list-item-content>

                            </v-list-item>
                            </v-list>


                        </v-menu>


                        <v-btn v-if="!loading" type="button" @click="calc" class="white--text" color="indigo">
                            Calculer mon tarif
                        </v-btn>

                        <div class="d-flex justify-center" v-else>
                            <v-progress-circular
                                :indeterminate="true"
                                size="40"
                                color="indigo"
                            ></v-progress-circular>
                        </div>


                    </v-form>
                        </v-card-body>
                    </v-card>
                    
                </v-col>
                <v-col cols="12" sm="6" md="7" lg="6">
                    <v-alert v-model="showCost" type="success" dismissible>
                    Your shipping cost is : {{cost}} Euro
                </v-alert>
                

            <v-row>
                <v-col class="ma-0 pa-0" cols="12">
                    <v-expansion-panels flat>
                        <v-expansion-panel v-for="price in prices" :key="price.name" style="background: #FFFFFF;border-radius: 10px; margin-bottom: 10px">
                            <v-expansion-panel-header style="padding: 10px 20px">
                                <v-row>
                                    <v-col cols="12" md="7">
                                        <div style="display: flex; align-items: center; height: 1em; line-height: 1em; margin-right: 0.5em;">
                                            <div style="width: 5em;">
                                                <v-img height="20" width='70' :src="backendUrl + '/' + price.logo" />
                                            </div>
                                            
                                            <b :style="$i18n.locale === 'en' ? 'margin-left: 1em': 'margin-right: 1em'">{{ $i18n.locale === 'ar' && price.nameAR ? price.nameAR : price.name }}</b>
                                        </div>
                                    </v-col>
                                    <v-col cols="12" md="5">
                                        <div style="height: 100%; width: 100%; display: flex; justify-content: space-between; flex-direction: row;">
                                            <span class="text-success">{{price.minShipTime && price.maxShipTime ? '⚡ ' + price.minShipTime + ' - ' + price.maxShipTime + ' ' + 'jours' : '' }}</span>
                                            <b>{{price.price}} &euro;</b>
                                        </div>
                                    </v-col>
                                </v-row>
                            </v-expansion-panel-header>
                            <!-- <v-expansion-panel-content style="color:#616161">
                            {{price.price}} &euro;
                            </v-expansion-panel-content> -->
                        </v-expansion-panel>
                    </v-expansion-panels>
                </v-col>

            </v-row>
            </v-col>
        </v-row>


            <v-snackbar left :color="color" v-model="snackbar">
                {{ message }}
            </v-snackbar>
        </v-container>
    </div>
</template>

<script>

import axios from 'axios';
import auth from '../helpers/auth';

import {countryList,backendUrl} from '../config/config';

const newAxios = axios.create()
newAxios.defaults.headers.common = {};


export default {
    name:'ShippingCalculator',
    data(){
        return {
            companies:[],
            searched: false,
            weight:0,
            company:'',
            cost:0,
            showCost:false,
            loading:false,
            color:'',
            message:'',
            snackbar:false,
            countryList:countryList,
            country:'',
            countryMenu:false,
            height:'',
            width:'',
            length:'',
            prices:[],
            backendUrl
        }
    },
    mounted(){
    if(this.$i18n.locale === 'ar'){
        this.$vuetify.rtl = true;
    }

    },
    methods:{
        calc(){

            if(!this.weight || !this.country || !this.height || !this.width || !this.length){
                this.snackbar = true;
                this.message = this.$i18n.t('checkFields');
                this.color= 'error';
                this.loading = false
                return
            }
            this.loading = true;
            this.searched = true
            newAxios.post('/api/users/side_calc_shipping',{
                weight:this.weight,
                country:this.country,
                height:this.height,
                width:this.width,
                length:this.length
            },
            {
            headers:{
                Authorization:`bearer ${auth.token()}`
        }}
            ).then(res=>{
                this.loading= false;
                this.prices = [];
                if(res.data.error){
                    this.snackbar = true;
                    this.message = this.$i18n.t('outRange');
                    this.color = 'error';
                }else{
                    this.prices = res.data.prices;
                }
            }).catch(err=>{
                console.log(err)

                this.snackbar = true;
                this.color = 'error';
                this.message = this.$i18n.t('errorMessage');
                this.loading = false;
            })
        },

        filterCountries(){
            let arr= [];

             if(!this.country){
                this.countryMenu = false;
                return
            }

            this.countryMenu = true;

            countryList.forEach(item=>{
                if(item.toLowerCase().indexOf(this.country.toLowerCase()) >= 0){
                    arr.push(item)
                }
            })

            this.countryList = arr;
        },
        setCountry(item){
            this.country = item;
            this.countryMenu = false;
        }
    }
}
</script>

